<template>
    <div v-if="deleted == false" class="row" style="font-size: 14px;">                
                   
        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
            {{ data.make }}
        </div>
        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
            {{ data.model }}
        </div>
        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
            <span v-if="data.ez_from != 0">Ab {{ data.ez_from }}</span>
            <span v-else>Alle</span>
        </div>
        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
            <span v-if="data.km_to != 0">Bis {{ data.km_to }} km</span>
            <span v-else>Alle</span>
        </div>
        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
            <span v-if="data.price_to != 0">Bis {{ data.price_to }} €</span>
            <span v-else>Alle</span>
        </div>
        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2" style="text-align: center; padding-right:20px;">
            <img @click="deleteFilter()" src="../assets/icons/trash-can-red.svg" width="20" alt="">
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <hr>
        </div>

    </div>        
</template>

<script>

import models from "../models";
import { useToast } from 'vue-toast-notification'

export default {
    name: 'BuyStationFilterItemView',
    props: {
        data: {
            type: Object,
            default: () => {}
        },   
    },
    data() {
        return {
            deleted: false,
        }
    }, 
    methods: {        
        async deleteFilter()
        {
            await models.deleteAnkaufFilter(this.$store.getters.StateToken, this.data.idfilter).then(async res => {
                // console.log(res.data);
                if(res.data['message'] == "success"){                        
                    this.deleted = true;
                    const toast = useToast();                      
                    toast.open({
                        message: 'Marken Filter gelöscht!',
                        type: 'error',
                        position: 'top-right'
                    });
                }                
            }).catch(err => {      
                console.log(err);
            });
        }
    },
    mounted() {

    }
}
</script>

<style scoped>

</style>