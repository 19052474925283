<template>
    <div id="invoices">
        <Loading v-bind:loading="performingRequest"/>
        <div class="sidebar"><Sidebar/></div>
        <div class="body-text">

            <div class="container-fluid pagepadding">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1 mx-auto" style="padding-left: 10px;">
                    <h1><span class="iconfloat-title"><img src="../assets/icons/invoice.svg" style="height:24px; width:24px;"></span>  {{ title }}</h1>                     
                    <hr>          
                    </div>        
                </div> 
            </div> 
            
            <div v-if="invoicecount == 0 && performingRequest != true" class="text-center">
                <br><br><br>
                <h3>Noch keine Rechnung!</h3>
                <p>Jetzt Fahrzeug suchen und buchen!</p>
                <br><br><br>
                <img src="@/assets/icons/invoice-no.svg"  style="width:180px;">
            </div>

            <div v-for="invoice in this.invoices" :key="invoice.idres" class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1 mx-auto">                 
                <div v-if="invoice.type == 'sys'">
                    <InvoiceItem v-bind:data="invoice" v-bind:car="JSON.parse(invoice.cardata)"/>                             
                </div>
                <div v-else-if="invoice.type == 'man'">
                    <InvoiceItem v-bind:data="invoice" v-bind:title="invoice.cardata"/>         
                </div>
            </div>

        <Footer />
        </div>        
    </div>
</template>

<script>
import models from "../models";
import Sidebar from '@/components/Sidebar'
import Loading from '@/components/Loading'    
import Footer from '@/components/Footer'
import InvoiceItem from '@/components/InvoiceItem'

export default {
    name: 'InvoicesView',
    components: {
        Sidebar,
        Loading,
        Footer,
        InvoiceItem
    },
    data() {
        return {
            performingRequest: true,
            invoicecount: 0,
            invoices: {},
            cardata: {},
        }
    }, 
    computed: {    
        title: () => {
            return "Rechnungen"
        }
    },
    async mounted() {
        await models.getInvoices(this.$store.getters.StateToken).then(res => {
            this.invoices = res.data;
            this.invoicecount = res.data.length;                     
            this.performingRequest = false;
        }).catch(err => {      
            this.performingRequest = false;
            console.log(err);
        });
    }
}    
</script>

<style scoped src="../assets/css/app.css"></style>
<style>

</style>