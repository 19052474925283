
<template>
    <div class="container-fluid footer">                     
        <!-- © 2023 - SehrGuterPreis.de             -->
    </div>    
</template>

<script setup>

</script>
<script>
export default {
  name: 'FooterView'
}
</script>

<style>
.footer {  
  height: 60px;
  padding-top: 20px;
  text-align: center;
  font-size: 14px;
}

.footer a{
  color: #fff;
}

.has-curve {
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
}
</style>