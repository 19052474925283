<template>
    <div v-if="show">
        <transition name="modal" id="modal-template">            
            <div>                 
                <div v-if="showclose" @click="closeModal()" class="close">
                    <img src="@/assets/icons/times.svg" alt="">
                </div>                        
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-container-lg">      
                            
                            <div v-if="paying == true">
                                <div class="modal-body-lg text-center">
                                    <br><br><br><br><br>
                                    <h4>BUCHUNG AUSSTEHEND</h4>
                                    <img src="@/assets/payment-animation.gif" style="width: 300px;">
                                    <br>
                                    <p>Ihre Buchung wird bearbeitet.<br>Bitte warten Sie einen Moment.</p>
                                </div>

                            </div>
                            <div v-else>

                            <div v-if="img !=''" :style="'width:100%;height: 200px; overflow:hidden;background-image:url('+img+'), url('+imgdefault+');background-size:cover;background-position:center center;border-top-left-radius: 15px;border-top-right-radius: 15px;'"></div>
                            <div class="modal-body-lg text-left">
                                
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left mt-3 mb-3">
                                    <h5>
                                        {{ data.title }}                                        
                                    </h5>                                    
                                    <hr>
                                </div>

                                <div class="row mt2 mb-2">
                                    <h6><b>Fahrzeug Informationen</b></h6>
                                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 text-left">
                                        <div style="font-size: 16px;">
                                            EZ: {{ data.ez }}<br>
                                            HU: {{ data.hu }}<br>
                                            Kilometer: {{ this.$filters.toCurrency(data.km) }} km<br>                   
                                            Kraftstoff: {{ data.full }}<br>
                                            Getriebe: {{ data.getriebe }} <br>
                                            Kategorie: {{ data.category }} <br>
                                            FIN: {{ message.fin }} <br>       
                                        </div>                                                                        
                                    </div>
                                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 text-left">
                                        <div style="font-size: 16px;">     
                                            Unfall: {{ message.unfall }}<br>        
                                            Vorbesitzer: {{ message.vorbesitzer }}<br>        
                                            Schlüssel: {{ message.schluessel }}<br>        
                                            Abmeldung: {{ message.abmeldung == '0' ? 'Verkäufer' : 'Händler' }}<br>                                                                                                                                    
                                        </div>                                        
                                        <h4 class="mr-1" style="margin-top: 10px;">Preis: {{ this.$filters.toCurrency(message.lastprice) }} € <small style="font-size: 14px;">{{JSON.parse(this.message.cardata).mwst == '0' ? 'Ohne MwSt.' : 'Inkl. MwSt.' }}</small></h4>
                                        <span class="price-badge" style="margin-right:20px;">- {{ this.$filters.toCurrency(getDiff(JSON.parse(this.message.cardata).price, message.lastprice)) }} €</span> 
                                        <span style="font-size: 14px;"><s>{{ this.$filters.toCurrency(JSON.parse(this.message.cardata).price) }} €</s></span>                                       
                                    </div>
                                </div>

                                <div class="row mt2 mb-2">
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left">
                                        <hr>
                                    </div>
                                </div>

                                <div class="row mt2 mb-2">
                                    <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5 text-left">
                                        <div style="font-size: 16px;">
                                        <h6><b>Lieferadresse</b></h6>
                                            {{ user.firma }}<br>
                                            {{ user.street }}<br>
                                            {{ user.zipcode }} {{ user.city }}<br>
                                            {{ user.land }}<br>
                                        </div>
                                    </div>                                    
                                    
                                </div>

                                <div class="row mt2 mb-2">
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left">
                                        <hr>
                                    </div>
                                </div>
                                
                                <div class="row mt2 mb-2">
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left">                                        
                                        <h6 style="margin-bottom: 20px;"><b>Lieferung</b></h6>                                          
                                        <div class="row">
                                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 text-center">
                                            <div @click="setDelivery(1)" :class="deliveryBtn">Mit Lieferung<br>+ {{ this.$filters.toCurrency(deliverycosttotal) }} €</div>
                                        </div>
                                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 text-center">
                                            <div @click="setDelivery(0)" :class="nodeliveryBtn">Ohne Lieferung<br>+ 0.00 €</div>
                                        </div>
                                        </div>                                            
                                    </div>                                                                        
                                </div>

                                <div class="row mt2 mb-2">
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left">
                                        <hr>
                                    </div>
                                </div>
                                
                                <div class="row mt2 mb-2">
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left">                                        
                                        <h6><b>Kaufvertrag</b></h6>
                                        
                                        <div class="row">
                                            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 text-center">
                                                <div @click="setContract()" style="margin:auto; margin-top: 25px;width: 60px;height: 60px;padding:10px;border-radius: 15px; border: 2px solid #000; cursor: pointer;">
                                                    <div v-if="autocontract == 1">
                                                        <img src="@/assets/icons/checkmark-green.svg" style="width: 36px;">
                                                    </div>
                                                    <div v-else-if="autocontract == 0">
                                                        <img src="@/assets/icons/times-red.svg" style="width: 36px;">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 text-left" style="font-style: 16px;">
                                                <b>Automatischer Kaufvertrag</b><br>
                                                Wir generieren automatisch einen Kaufvertrag im<br>
                                                Namen Ihres Autohauses und senden ihn an Sie<br>
                                                und den Verkäufer.
                                                
                                                <!-- <div v-if="autocontract == 1">
                                                    <small style="font-size: 12px;">Ihre Unterschrift:</small>
                                                    <VueSignaturePad 
                                                        width="450px"
                                                        height="150px"
                                                        id="signature"                                                        
                                                        ref="signaturePad"                                                        
                                                    />                                                
                                                    <div>
                                                        <a @click="deleteSignatur" class="text-danger">Löschen</a>
                                                    </div>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>                                                                        
                                </div>
                                
                                <div class="row mt2 mb-2">
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left">          
                                        <hr/>                              
                                        <h6><b>Gutachten buchen</b></h6>                                        
                                        <div class="row">
                                            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 text-center">
                                                <div @click="setGutachten()" style="margin:auto; margin-top: 25px;width: 60px;height: 60px;padding:10px;border-radius: 15px; border: 2px solid #000; cursor: pointer;">
                                                    <div v-if="gutachten == 1">
                                                        <img src="@/assets/icons/checkmark-green.svg" style="width: 36px;">
                                                    </div>
                                                    <div v-else-if="gutachten == 0">
                                                        <img src="@/assets/icons/times-red.svg" style="width: 36px;">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 text-left" style="font-style: 16px;">
                                                <b>Schadensgutachten anfordern</b><br>
                                                Wir lassen für Sie ein Schadengutachten anfertigen.<br>
                                                Sobald dieses vorliegt können Sie Ihre Enscheidung treffen.<br>
                                                <b> + 150,00 Euro</b>
                                            </div>
                                        </div>
                                    </div>                                                                        
                                </div>
                                
                                <!-- <div class="row mt2 mb-2">
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left">          
                                        <hr/>
                                        <h6><b>Treuhand Service buchen</b></h6>                                        
                                        <div class="row">
                                            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 text-center">
                                                <div @click="setTreuhand()" style="margin:auto; margin-top: 25px;width: 60px;height: 60px;padding:10px;border-radius: 15px; border: 2px solid #000; cursor: pointer;">
                                                    <div v-if="truehand == 1">
                                                        <img src="@/assets/icons/checkmark-green.svg" style="width: 36px;">
                                                    </div>
                                                    <div v-else-if="truehand == 0">
                                                        <img src="@/assets/icons/times-red.svg" style="width: 36px;">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 text-left" style="font-style: 16px;">
                                                <b>Treuhand Service</b><br>
                                                Wir bieten Ihnen mehr Sicherheit mit unserem<br>
                                                Treuhand Service. Wir verwalten das Geld bis das Geschäft <br>
                                                abgeschlossen ist.<br>
                                                <b> + 8,50 Euro</b>
                                                                                            
                                            </div>
                                        </div>
                                    </div>                                                                        
                                </div>

                                <div class="row mt2 mb-2">
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left">
                                        <hr>
                                    </div>
                                </div> -->

                                <div class="row mt2 mb-4" style="font-size: 16px;">
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left" style="padding-top:6px;padding-bottom:6px;border-bottom: 1px solid #dedede;">
                                        Anzahlung: <span style="float:right">{{message.anzahlung}}.00 €</span>
                                    </div>                                    
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left" style="margin-bottom: 15px;padding-top:6px;padding-bottom:6px;border-bottom: 1px solid #dedede;">
                                        <b>Fahrzeugpreis</b> <small style="font-size: 13px;color: #6e6e6e;">{{JSON.parse(this.message.cardata).mwst == '0' ? 'Ohne MwSt.' : 'Inkl. MwSt.' }}</small>: <span style="float:right">{{ this.$filters.toCurrency(message.lastprice) }}.00 €</span>
                                    </div>                                    
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left" style="padding-top:6px;padding-bottom:6px;border-bottom: 1px solid #dedede;">
                                        SGP-Service <small style="font-size: 13px;color: #6e6e6e;">(Netto)</small>: <span style="float:right">{{this.$filters.toCurrency(sgpservicetotal)}} €</span>
                                    </div>                                    
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left" style="padding-top:6px;padding-bottom:8px;border-bottom: 1px solid #dedede;">
                                        Lieferung <small style="font-size: 13px;color: #6e6e6e;">(Netto)</small>: <span style="float:right">{{ this.$filters.toCurrency(deliverycost) }} €</span>
                                    </div>                    
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left" style="padding-top:6px;padding-bottom:8px;border-bottom: 1px solid #dedede;">
                                        Gutachten <small style="font-size: 13px;color: #6e6e6e;">(Netto)</small>: <span style="float:right">{{ this.$filters.toCurrency(gutachtencost) }} €</span>
                                    </div>                    
                                    <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left" style="padding-top:6px;padding-bottom:8px;border-bottom: 1px solid #dedede;">
                                        Treuhand Service <small style="font-size: 13px;color: #6e6e6e;">(Netto)</small>: <span style="float:right">{{ this.$filters.toCurrency(treuhandcost) }} €</span>
                                    </div> -->
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left" style="padding-top:6px;padding-bottom:6px;border-bottom: 1px solid #dedede;">
                                        <b>Gesamtpreis: <span style="float:right">{{ this.$filters.toCurrency(totalcost) }} €</span></b>
                                    </div>                                    
                                </div>

                                <div class="row mt2 mb-4">
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center" style="margin-top:20px;padding-top:6px;padding-bottom:6px;">
                                        <button @click="buy()" class="btn btn-success btn-lg btn-block" style="padding-top:15px;padding-bottom:15px;margin:auto;width: 60%;border-radius:20px;">Jetzt Buchen</button>
                                    </div> 
                                </div>
                                
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
    <div>   
        <a @click="openModal()" class="buyBtn mt-2">
            Fahrzeug buchen
        </a>                
    </div>
</template>

<script>
import models from "../models";
import imgdefault from '@/assets/nopic-details.png'
import { useToast } from 'vue-toast-notification'
// import { VueSignaturePad } from 'vue-signature-pad';

export default {
    name: 'PurchaseView',    
    props: {
        data: {
            type: Object,
            required: true
        },
        message: {
            type: Object,
            required: true
        },
    },
    components: {
        // VueSignaturePad
    },
    data() {
        return {
            msgsend: this.data.angefragt,
            show: false,
            showclose: true,
            paying: false,
            img: "",
            imgdefault: imgdefault,            
            distance: 0,
            delivery: 1,
            deliverycost: 0,
            gutachtencost: 0,
            treuhandcost: 0,
            deliverycosttotal: 0,
            deliveryBtn: 'deliverybox delivery',
            nodeliveryBtn: 'deliverybox nodelivery',
            sgpservice: 0,        
            sgpservicetotal: 0,        
            totalcost: 0,
            user: {},
            autocontract: 1,
            gutachten: 0,
            truehand: 0,
            status: [
                "Auslieferung vorbereiten",
                "Termin für Auslieferung",
                "Ausgeliefert",
                "Storniert"
            ]
        }
    },
    mounted() {     
        models.getDistance(this.data.lat, this.data.lon, this.$store.getters.StateUser['lat'], this.$store.getters.StateUser['lon']).then((response) => {
            this.distance = response;
            this.deliverycost = models.getDeliveryCosts(response)['net']
            this.deliverycosttotal = models.getDeliveryCosts(response)['net']
            this.sgpservice = models.getSGPServiceCosts(JSON.parse(this.message.cardata).price)['net']           
            this.sgpservicetotal = Number(this.sgpservice) + ( Number(this.getDiff(JSON.parse(this.message.cardata).price, this.message.lastprice)) * 0.08 )
            this.totalcost = (Number(this.deliverycost) + Number(this.sgpservicetotal))
        })
        this.img = JSON.parse(this.message.cardata).image
        this.user = this.$store.getters.StateUser
    },
    methods: {
        getDiff: function (value1, value2) {
            var val = (Number(value1) - Number(value2));
            return val;
        },
        openModal(){
            this.show = true;
            document.body.classList.add('noscroll');
        },
        closeModal(){
            this.show = false;
            document.body.classList.remove('noscroll')
        },   
        deleteSignatur(){
            this.$refs.signaturePad.clearSignature();
        },     
        setDelivery(i){
            this.delivery = i
            if(i == 1){            
                this.deliveryBtn = "deliverybox delivery"
                this.nodeliveryBtn = "deliverybox nodelivery"
                this.deliverycost = this.deliverycosttotal
                this.totalcost = (Number(this.deliverycost) + Number(this.sgpservicetotal) + Number(this.gutachtencost))
            }else{
                this.deliveryBtn = "deliverybox nodelivery"
                this.nodeliveryBtn = "deliverybox delivery"
                this.deliverycost = 0
                this.totalcost = (Number(this.deliverycost) + Number(this.sgpservicetotal) + Number(this.gutachtencost))
            }
        },
        setContract(){
            if(this.autocontract == 1){
                this.autocontract = 0
            }else{
                this.autocontract = 1
            }
        },
        setGutachten(){
            if(this.gutachten == 1){
                this.gutachten = 0
                this.gutachtencost = 0.00
                this.totalcost = this.totalcost - 150.00
            }else{
                this.gutachten = 1
                this.gutachtencost = 150.00
                this.totalcost = this.totalcost + 150.00                
            }
        },
        setTreuhand(){
            if(this.truehand == 1){
                this.truehand = 0
                this.treuhandcost = 0.00
                this.totalcost = this.totalcost - 8.50
            }else{
                this.truehand = 1
                this.treuhandcost = 8.50
                this.totalcost = this.totalcost + 8.50
            }
        },
        async buy(){
            this.showclose = false
            this.paying = true
            await models.buyCar(this.$store.getters.StateToken, this.message.mtoken, this.delivery, this.distance, this.autocontract, this.gutachten, this.truehand).then(res => {     
                const toast = useToast(); 
                // console.log(res.data); 
                if(res.data['message'] == "success"){                    
                    toast.open({
                        message: 'Fahrzeug gebucht!',
                        type: 'success',
                        position: 'top-right'
                    });
                    document.body.classList.remove('noscroll')
                    this.$router.push("/sales");
                }else{
                    toast.open({
                        message: 'Es ist ein Fehler passiert!',
                        type: 'error',
                        position: 'top-right'
                    });  
                    this.showclose = true
                    this.paying = false                  
                }
            }).catch(err => {
                this.carpaid = false
                this.setpaidloading = false
                console.log(err);
            });            
        }
    }
}
</script>

<style>

.close{
    position: fixed;
    display: block;
    width: 150px;
    height: 150px;
    top: 10px;
    right: 20px;
    cursor: pointer;
    z-index: 8889;
}

.badge{
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;
}

.deliverybox{
    width: 100%;
    border-radius: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    cursor: pointer;
    font-size: 18px;
}

.delivery{
    background-color: #187c0b;   
    color: #fff; 
}
.nodelivery{
    background-color: #dddddd;
    color: #6e6e6e;
}

.buyBtn{
    display: block;
    width: 100%;
    cursor: pointer; 
    margin-top: 10px; 
    color:#187c0b; 
    text-decoration: none; 
    font-size: 16px;
    font-weight: 600;
    padding: 6px 20px 6px 20px;
    border: 1px solid #187c0b;
    border-radius: 15px;
}
.buyBtn:hover {
    color: #fff;
    background-color: #187c0b;
}

.btn-warning{
    background-color: #f2ab46;
    padding-left: 40px;
    padding-right: 40px;
    color: #fff;
}

#signature {
  width: 450px;
  height: 200px;
  border: double 2px transparent;
  border-radius: 15px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #000, #000);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.text-danger{
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
}
</style>
<style scoped src="../assets/css/car.css"></style>