<template>
  <div class="container-fluid">
      <router-link to="/">
        <img src="../assets/logo.png" alt="" class="img-responsive">        
      </router-link>
    </div>
</template>

<script>
export default {
  name: 'LoginHeader',
}
</script>

<style scoped>
.container-fluid{
  /* background-color: #48B213;   */
  padding-top: 14%;
  padding-bottom: 0px;
}
.img-responsive{
  max-height: 50px;
}
</style>
