<template>
    <div v-if="show">
        <transition name="modal" id="modal-template">            
            <div>
                <div @click="closeModal()" class="close">
                    <img src="@/assets/icons/times.svg" alt="">
                </div>                        
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-container-lg">                                                                    
                            <div class="modal-body-lg text-left">
                                

                                <div v-for="car in data" :key="car.mid" class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1 mx-auto">                 
                                    <StockDetailsItem v-bind:data="car"/>         
                                </div>  
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
    <div style="margin-top:10px;">
        <a @click="openModal()" class="detailsBtn mt-2">
            Bestand Details ansehen
        </a>
    </div>
</template>

<script>
import StockDetailsItem from '@/components/StockDetailsItem.vue'

export default {
    name: 'StockDetailView', 
    components: {
        StockDetailsItem
    },
    props: {
        data: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            show: false,            
        }
    },
    mounted() {     
        console.log(this.data);
    },
    methods: {        
        openModal(){
            this.show = true;
            document.body.classList.add('noscroll');
        },
        closeModal(){
            this.show = false;
            document.body.classList.remove('noscroll')
        },        
    }
}
</script>

<style>

.close{
    position: fixed;
    display: block;
    width: 150px;
    height: 150px;
    top: 10px;
    right: 20px;
    cursor: pointer;
    z-index: 99999999;
}

.feature-tag{
    background-color: #f2f2f2;
    padding: 5px 10px;
    border-radius: 10px;       
    color: #000;
    border:1px solid #dedede;
    display:block;
    float:left; 
    margin-right: 10px; 
    margin-bottom: 10px; 
    font-size: 13px;
}

.detailsBtn{
    display: block;
    width: 100%;
    cursor: pointer; 
    margin-top: 10px; 
    color:#454545; 
    text-decoration: none; 
    font-size: 16px;
    font-weight: 600;
    padding: 6px 20px 6px 20px;
    border: 1px solid #dedede;
    border-radius: 15px;
}
.detailsBtn:hover {
    color: #000;
    background-color: #dedede;
}
</style>
<style scoped src="../assets/css/car.css"></style>