<template>
    <div id="temp">
        <Loading v-bind:loading="performingRequest"/>
        <div class="sidebar"><Sidebar/></div>
        <div class="body-text">
            <ImageCarousel v-bind:show="show" v-bind:images="rimgs" @showgallery="(i) => this.show = i" />
                
            <div class="container-fluid pagepadding">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1 mx-auto" style="padding-left: 0px;">
                        <h1>{{ data.title }}</h1>                      
                        <hr>          
                        <!-- {{ $route.params.id }}   -->
                        <!-- <a @click="clicktest()">asdasd</a> -->
                    
                        <div class="row">
                            <!-- <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 text-left"> -->

                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" style="padding:0px;position: relative;">    
                                <div class="photocount">
                                    <span class="iconfloat-title"><img src="../assets/icons/camera.svg" style="height:15px; width:15px;"></span> {{photocount}}         
                                </div>                        
                                <div v-if="this.parked" class="parkcount">
                                    Geparked
                                </div> 
                                <div v-if="data.lastprice != '0'" class="nowoffer">
                                    SOFORT
                                </div>  

                                <div @click="openGallery()" v-if="img !=''" :style="'cursor:pointer; width:100%;height: 420px; overflow:hidden;background-image:url('+img+'), url('+imgdefault+');background-size:cover;background-position:center center;'"></div>
                            </div>


                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 text-left">


                                <div style="position:relative;width: 100%;">
                                    <div style="position:absolute; top: 10px; right:10px;">
                                        <div v-if="this.parked">
                                            <img @click="parkCar(data.mid)" src="@/assets/icons/car-parked.svg" title="Fahrzeug Parken" style="width: 28px;cursor: pointer;" alt="">
                                        </div>
                                        <div v-else>
                                            <img @click="parkCar(data.mid)" src="@/assets/icons/car-notparked.svg" title="Fahrzeug Parken" style="width: 28px;cursor: pointer;" alt="">
                                        </div>
                                    </div> 
                                </div>

                            
                                <h6><b>Fahrzeug Informationen</b></h6>
                            
                                <div style="font-size: 16px;">
                                    EZ: {{ data.ez }}<br>
                                    HU: {{ data.hu }}<br>
                                    Kilometer: {{ this.$filters.toCurrency(data.km) }} km<br>                   
                                    Kraftstoff: {{ data.full }}<br>
                                    Getriebe: {{ data.getriebe }} <br>
                                    Kategorie: {{ data.category }} 
                                </div>                                                                        
                            
                            
                                <div style="font-size: 16px;margin-top: 10px;">                
                                    Entferung: {{this.distance}} km <br>
                                    Logistikkosten: {{this.delivery}} € (Netto)<br>
                                    SGP-Service: {{this.sgpservice}} € (Netto)
                                </div> 
                                <div v-if="data.lastprice != '0'" class="mb-2">
                                    <h4 class="mr-1" style="margin-top: 10px;">{{ this.$filters.toCurrency(data.lastprice) }} €</h4>
                                    <span class="price-badge" style="margin-right:20px;">- {{ this.$filters.toCurrency(getDiff(data.price, data.lastprice)) }} €</span>
                                    <span style="font-size: 14px;"><s>{{ this.$filters.toCurrency(data.price) }} €</s></span>
                                </div>
                                <div v-else>
                                    <h4 class="mr-1" style="margin-top: 10px;">{{ this.$filters.toCurrency(data.price) }} €</h4>
                                    <p class="text-success mb-4" style="font-size:14px;">Optional mit Lieferung</p>                    
                                </div>

                                <div class="mt-4" v-if="performingRequest == false">
                                    <SendMessageBtn v-bind:data="this.data" />
                                </div>
                            
                            </div>

                        </div>

                        <div class="row mt-5">
                            <div v-if="this.data.rating != 'NO_RATING'" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-2" style="font-size:15px;width: 100%;">                                
                                <h6><b>Preisbewertung</b></h6>
                                <div style="float: left;width: 20%; text-align:center;">
                                    <div style="width: 100%;height:6px;background: #187c0b;margin-bottom: 10px;"></div>
                                    <span style="color:#187c0b;font-weight: 600;">Sehr guter Preis</span><br>
                                    {{ ratingItems[0] }} -
                                    {{ ratingItems[1] }}
                                </div>
                               <div style="float: left;width: 20%; text-align:center;">
                                    <div style="width: 100%;height:6px;background: #34b523;margin-bottom: 10px;"></div>
                                    <span style="color:#34b523;font-weight: 600;">Guter Preis</span><br>
                                    {{ ratingItems[1] }} - 
                                    {{ ratingItems[2] }}
                                </div>
                                <div style="float: left;width: 20%; text-align:center;">
                                    <div style="width: 100%;height:6px;background: #36d121;margin-bottom: 10px;"></div>	
                                    <span style="color:#36d121;font-weight: 600;">Fairer Preis</span><br>
                                    {{ ratingItems[2] }} - 
                                    {{ ratingItems[3] }}
                                </div>
                                <div style="float: left;width: 20%; text-align:center;">
                                    <div style="width: 100%;height:6px;background: #f3ad2c;margin-bottom: 10px;"></div>
                                    <span style="color:#f3ad2c;font-weight: 600;">Erhöhter Preis</span><br>
                                    {{ ratingItems[3] }} - 
                                    {{ ratingItems[4] }}
                                </div>
                                <div style="float: left;width: 20%; text-align:center;">
                                    <div style="width: 100%;height:6px;background: #e54d4d;margin-bottom: 10px;"></div>                                        
                                    <span style="color:#e54d4d;font-weight: 600;">Hoher Preis</span><br>
                                    {{ ratingItems[4] }} - 
                                    {{ ratingItems[5] }}
                                </div>
                                <br style="clear: both;">
                                <br>									
                            </div>			
                        </div>

                        <div class="row mt-4">
                            <div v-if="data.features !=''" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left mb-3">
                                <h6><b>Ausstattung</b></h6>
                                <hr>
                                <span v-for="feature in featuresItems" :key="feature"  class="feature-tag">
                                    {{ feature }}
                                </span>                                    
                            </div>
                        </div>
                    
                    
                    </div>   
                                
                </div> 
            </div> 

        <Footer/>
        </div>        
    </div>
</template>

<script>
    import Sidebar from '@/components/Sidebar'
    import Loading from '@/components/Loading'    
    import Footer from '@/components/Footer'
    import SendMessageBtn from '@/components/SendMessageBtn'
    import ImageCarousel from '@/components/ImageCarousel'
    import models from "../models";
    import { useToast } from 'vue-toast-notification'
    import imgdefault from '@/assets/nopic2.png'

    export default {
        name: 'CarDetailsView',
        components: {
            Sidebar,
            Loading,
            SendMessageBtn,
            ImageCarousel,
            Footer
        },  
        computed: {    
            title: () => {
                return "Fahrzeug Details"
            },
            featuresItems: function() {
                return (this.data.features || "").split(",");
            },
            ratingItems: function() {
                return (this.data.ratingprices || "").split(",");
            }
        },       
        data() {
            return {
                performingRequest: true,
                data: {},
                msgsend: 0,
                img: "",
                show: false,
                rimgs: [],
                photocount: 0,
                imgdefault: imgdefault,
                ratings: null,
                distance: 0,
                delivery: 0,
                sgpservice: 0,
                parked: false
            }
        },
        async mounted() {  

            await models.getCarById(this.$route.params.id, this.$store.getters.StateToken).then(res => {
                this.data = res.data            
                this.performingRequest = false

                models.getDistance(this.data.lat, this.data.lon, this.$store.getters.StateUser['lat'], this.$store.getters.StateUser['lon']).then((response) => {
                    this.distance = response;
                    this.delivery = models.getDeliveryCosts(response)['net']
                })
                this.sgpservice = models.getSGPServiceCosts(this.data.price)['net']   
                if(this.data.image !=""){
                    this.img = this.data.image.replace('_8.JPG', '_20.JPG')
                }else{
                    this.img = ""
                }
                if(this.data.ratingprices != ""){
                    this.ratings = this.data.ratingprices.split(',')
                }
                const imgs = this.data.images.split(",")
                for (let index = 0; index < imgs.length; index++) {
                    const element = imgs[index]
                    this.rimgs.push(element.replace('_8.JPG', '_20.JPG'))
                    this.photocount = this.rimgs.length
                }
                if(this.data.parked == 0){
                    this.parked = false
                }else{
                    this.parked = true
                }

            }).catch(err => {      
                this.performingRequest = false;
                console.log(err);
            });
        },
        methods: {
            getDiff: function (value1, value2) {
                var val = (Number(value1) - Number(value2));
                return val;
            },
            // clicktest(){
            //     useToast().open({
            //         message: '<b>Neues Angebot!</b><br><div id="msgOffer">Fiat 500 La Prima by Bocelli +WR +2J.Anschl.Garantie für 28.999€ - 2.793km - Elektro - Automatik</div>',
            //         type: 'success',
            //         position: 'top-right',
            //         duration: 7000,
            //         onClick: () => {
            //             // this.$router.push("/car/701878");
            //             window.location.href = "/car/701878";
            //         }
            //     });
            // },  
            openGallery(){
                document.body.classList.add('noscroll')
                this.show = true;
            },  
            async parkCar(mid) {
                const toast = useToast();  
                if(this.parked == false){
                    await models.parkCar(this.$store.getters.StateToken, mid).then((response) => {
                        if(response.data['message'] == 'success'){
                            this.parked = true;                                            
                            toast.open({
                                message: 'Fahrzeug wurde geparkt!',
                                type: 'success',
                                position: 'top-right'
                            });
                        }
                    }).catch((error) => {
                        console.log(error);
                    });
                }else{
                    await models.parkCarOut(this.$store.getters.StateToken, mid).then((response) => {
                        if(response.data['message'] == 'success'){
                            this.parked = false;   
                            toast.open({
                                message: 'Fahrzeug ausgeparkt!',
                                type: 'error',
                                position: 'top-right'
                            });
                        }
                    }).catch((error) => {
                        console.log(error);
                    });
                }
            }
        }
    }    
</script>

<style scoped src="../assets/css/app.css"></style>
<style scoped src="../assets/css/car.css"></style>
<style>
#msgOffer{
    max-width: 250px;
}
</style>