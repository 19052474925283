
<template>    
    <div class="row bg-white border mt-4" style="padding:0px;">   
        <Loading v-bind:loading="performingRequest"/>
        <BuyStationAcceptDialog 
            v-bind:showdialog="this.showdialog" 
            v-bind:title="'Termin annehmen?'" 
            v-bind:text="'Bitte bestätigen Sie den Termin!'" 
            v-bind:btnname="'Annehmen'" 
            v-bind:btnclass="'success'" 
            @closedialog="(i) => closeTerminConfirm(i)"
            @answer="(i) => terminaccept(i, 1)"
        />               
        <BuyStationCancelDialog 
            v-bind:showdialog="this.showdialog2"
            v-bind:title="'Termin ablehnen?'" 
            v-bind:text="'Bitte bestätigen Sie die Ablehnung!'" 
            v-bind:btnname="'Ablehnen'" 
            v-bind:btnclass="'danger'" 
            @closedialog="(i) => closeTerminReject(i)"
            @answer="(i) => terminaccept(i, 2)"
        />  
        <BuyStationAcceptDialog 
            v-bind:showdialog="this.showdialog3" 
            v-bind:title="'Fahrzeug gekauft?'" 
            v-bind:text="'Haben Sie das Fahrzeug gekauft!'" 
            v-bind:btnname="'Gekauft'" 
            v-bind:btnclass="'success'" 
            @closedialog="(i) => closePurchasedCar(i)"
            @answer="(i) => purchasedcar(i, 1)"
        />              
        <BuyStationCancelDialog 
            v-bind:showdialog="this.showdialog4"
            v-bind:title="'Fahrzeug nicht gekauft?'" 
            v-bind:text="'Haben Sie das Fahrzeug nicht gekauft!'" 
            v-bind:btnname="'Nicht gekauft'" 
            v-bind:btnclass="'danger'" 
            @closedialog="(i) => closeNotPurchasedCar(i)"
            @answer="(i) => purchasedcar(i, 2)"
        />               
        <BuyStationPostponeDialog 
            v-bind:showdialog="this.showdialog5"
            v-bind:title="'Termin verschieben'" 
            v-bind:text="'Sie wollen den Termin auf einen anderen Zeitpunkt verschieben?'" 
            v-bind:btnname="'Verschieben'" 
            v-bind:btnclass="'success'" 
            @closedialog="(i) => closeTerminPostpone(i)"
            @answer="(i) => postponeAppointment(i, 3)"
        />               
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2" style="padding:15px;position: relative; text-align:center;">            
            <div style="margin-top:6px;font-size: 20px; font-weight: 400;">
                <img src="../assets/icons/calendar-green.svg" style="height:24px; width:24px;margin-bottom:8px;"><br>
                {{ formatDateWithTime(data.termindate).replace(", ", "\n") }} Uhr
            </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-3">                        
            <div class="mb-3" style="font-size: 15px;">                
                <h5 style="font-size: 18px;margin-bottom: 0px;font-weight: 400;" class="text-truncate">{{ car.title }}</h5>
                {{ car.ez }} - {{ this.$filters.toCurrency(car.km) }} km - {{ car.full }} - {{ car.getriebe }}  <br>                                
                <h4 style="font-size: 18px;margin-bottom: 0px;margin-top: 6px;font-weight: 400;">Preis VB: {{ this.$filters.toCurrency(data.lastprice) }} EUR</h4>                
                <!-- <h5 style="font-size: 18px;margin-bottom: 10px;font-weight: 400;" class="text-truncate">Datum: <b>{{ formatDateWithTime(data.termindate) }} Uhr</b></h5> -->
                   
                <div style="display: block; height: 10px;"></div>
                
                <div v-if="this.data.termintype == 0">                      
                    <div><span style="cursor: pointer; width: 126px; font-size: 14px; padding: 4px 10px; background-color: green; border-radius: 10px; color: rgb(255, 255, 255);">Autohaus Termin</span><span><small style="font-size: 12px; margin-left: 10px;">Kunde kommt zu Ihnen</small></span></div>
                </div>
                <div v-if="this.data.termintype == 1">  
                    <div><span style="cursor: pointer; width: 101px; font-size: 14px; padding: 4px 10px; background-color: green; border-radius: 10px; color: rgb(255, 255, 255);">Außentermin</span><span><small style="font-size: 12px; margin-left: 10px;">Sie fahren zum Kunden</small></span></div>
                    <div style="margin-top: 5px;" v-if="terminstatus != 0">
                        Verkäufer: {{ this.data.sellername }}<br>
                        Adresse: {{ this.data.sellerstreet }} - {{ this.data.sellerplz }} {{ this.data.sellercity }}<br>
                        Telefon: {{ this.data.sellerphone }}
                    </div>
                </div>

                <div v-if="this.data.notice != null" style="margin-top: 15px; border: 1px solid green; padding: 10px;">
                    <b style="color: green;">SGP Notiz:</b><br>
                    {{this.data.notice}}
                </div>

                <div style="margin-top: 15px;">
                    <span style="float:left;margin-top: 0px; margin-right: 20px" class="chatcarbtndetails">
                        <a @click="openGallery()"><b>Fahrzeug Bilder</b></a>
                    </span>
                    <ImageCarousel v-bind:show="show" v-bind:images="rimgs" @showgallery="(i) => this.show = i" />

                    <CarDetailView v-bind:data="car" v-bind:type="'chat'" v-bind:termin="true" v-bind:price="data.lastprice" v-bind:ankaufstation="true" />   
                </div>

             </div>
        </div>
        <div class="align-items-center align-content-center col-xs-12 col-sm-12 col-md-4 col-lg-4 border-left mt-1">       
            
            <div style="position:relative;width: 100%;">
                <div style="position:absolute; top: 5px; right:10px;" title="Nachricht schreiben">                    
                    <ChatOpener v-bind:data="car" />
                </div>                
                <div style="position:absolute; top: 5px; right:75px;">                    
                    <div v-if="data.vin != '' && data.vin != null"><span style="color: green; font-size: 14px;">FIN: {{ data.vin.toUpperCase() }}</span></div>
                </div>                
            </div>

            <div class="d-flex flex-column text-center">  
                <div v-if="terminstatus == 0">
                    <div style="margin-top: 50px;">
                        <a @click="openTerminConfirm()" style="cursor: pointer;font-size:14px;padding: 4px 10px 4px 10px;background-color: green;border-radius: 10px;color:#fff;">Annehmen</a>
                        <a @click="openTerminRecject()" style="cursor: pointer;font-size:14px;padding: 4px 10px 4px 10px;;background-color: rgb(255, 86, 86);border-radius: 10px;color:#fff;margin-left:10px;">Ablehnen</a>
                        <a @click="openTerminPostpone()" style="cursor: pointer;font-size:14px;padding: 4px 10px 4px 10px;;background-color: rgb(47, 183, 250);border-radius: 10px;color:#fff;margin-left:10px;">Verschieben</a>
                    </div>
                </div>
                <div v-if="terminstatus == 1">
                    <div style="margin-top: 50px;">
                        <a :href="'https://www.sehrguterpreis.de/api/ics/ics.php?atoken='+data.atoken" title="Termin Datei runterladen" target="_blank" style="text-decoration: none;">
                            <span style="cursor: pointer;font-size:16px;padding: 6px 16px 6px 16px;background-color: rgb(75, 203, 246);border-radius: 10px;color:#fff;">Termin speichern</span>
                        </a> 
                    </div>
                </div>
                <div v-if="terminstatus == 2">
                    <div v-if="purchaseloading == false" style="margin-top: 50px;">
                        <div v-if="purchasedstate == 0">
                            <a @click="openPurchasedCar()" style="cursor: pointer;font-size:16px;padding: 6px 16px 6px 16px;background-color: green;border-radius: 10px;color:#fff;">Gekauft</a>
                            <a @click="openNotPurchasedCar()" style="cursor: pointer;font-size:16px;padding: 6px 16px 6px 16px;background-color: rgb(255, 86, 86);border-radius: 10px;color:#fff;margin-left:20px;">Nicht gekauft</a>
                        </div>
                        <div  v-else-if="purchasedstate == 1">
                            <span style="font-size:16px;padding: 6px 16px 6px 16px;background-color: #008000;border-radius: 10px;color:#fff;">Gekauft</span>
                            <span v-if="data.sellerreview == 1" style="margin-left:20px;" title="Nachricht schreiben">                    
                                <img src="../assets/icons/star.svg" height="40" alt="Bewertung erhalten" title="Bewertung erhalten">
                            </span>
                        </div>
                        <div v-else-if="purchasedstate == 2">
                            <span style="font-size:16px;padding: 6px 16px 6px 16px;background-color: #ff5656;border-radius: 10px;color:#fff;">Nicht gekauft</span>
                        </div>
                    </div>
                    <div v-if="purchaseloading == true" style="margin-top: 50px;">
                        <img src="@/assets/bx_loader.gif" width="35" height="35" alt="">
                    </div>
                </div>
            </div>

        </div>              
    </div>
</template>

<script>
import models from "../models";
import { useToast } from 'vue-toast-notification'
import imgdefault from '@/assets/nopic2.png'
import Loading from '@/components/Loading'
import CarDetailView from '@/components/CarDetailView'
import ChatOpener from '@/components/ChatOpener'    
import BuyStationAcceptDialog from '@/components/BuyStationAcceptDialog'    
import BuyStationCancelDialog from '@/components/BuyStationCancelDialog'    
import BuyStationPostponeDialog from '@/components/BuyStationPostponeDialog'    
import ImageCarousel from '@/components/ImageCarousel'    

export default {
    name: 'BuyStationItemView',
    components: {
        Loading,
        CarDetailView,
        ChatOpener,
        BuyStationAcceptDialog,
        BuyStationCancelDialog,
        BuyStationPostponeDialog,
        ImageCarousel
    },
    props: {
        data: {
            type: Object,
            default: () => {}
        },        
        car: {
            type: Object,
            default: () => {}
        }, 
        terminstatus: {
            type: String,
            default: () => '0'
        }, 
        close: {
            type: String,
            default: () => ''
        },       
    },
    data() {
        return {   
            show: false,                 
            showdialog: false,
            showdialog2: false,
            showdialog3: false,
            showdialog4: false,
            showdialog5: false,
            performingRequest: false,
            purchaseloading: false,
            purchasedstate: 0,
            rimgs: []
        }
    },     
    mounted () {  
        // console.log(this.data);
        this.purchasedstate = this.data.purchased;
        const imgs = this.car.images.split(",")
        for (let index = 0; index < imgs.length; index++) {
            const element = imgs[index]
            this.rimgs.push(element.replace('_8.JPG', '_20.JPG')); 
        }
    },
    methods: {    
        openGallery(){
            document.body.classList.add('noscroll')
            this.show = true;
        },
        setAltImg(event) { 
            event.target.src = imgdefault;
        },          
        openTerminConfirm(){
            this.showdialog = true;
        },
        closeTerminConfirm(){
            this.showdialog = false;
        },
        openTerminRecject(){
            this.showdialog2 = true;
        },
        closeTerminReject(){
            this.showdialog2 = false;
        },
        openTerminPostpone(){
            this.showdialog5 = true;
        },
        closeTerminPostpone(){
            this.showdialog5 = false;
        },
        openPurchasedCar(){
            this.showdialog3 = true;
        },
        closePurchasedCar(){
            this.showdialog3 = false;
        },
        openNotPurchasedCar(){
            this.showdialog4 = true;
        },
        closeNotPurchasedCar(){
            this.showdialog4 = false;
        },
        async terminaccept(i, s){            
            if(i[0] == true){
                this.performingRequest = true;
                await models.setTerminConfirmation(this.data.atoken, s, i[1]).then(res => {
                    const toast = useToast();  
                    if(res.data['message'] == "success"){
                        if(s == 1){
                            toast.open({
                                message: 'Termin wurde angenommen!',
                                type: 'success',
                                position: 'top-right'
                            });
                        }else{
                            toast.open({
                                message: 'Termin wurde abgelehnt!',
                                type: 'error',
                                position: 'top-right'
                            });
                        }
                    }else{
                        toast.open({
                            message: 'Es ist ein Fehler passiert!',
                            type: 'error',
                            position: 'top-right'
                        });
                    }
                    this.performingRequest = false;
                    this.$emit('reload', true);
                }).catch(err => {      
                    this.performingRequest = false;
                    console.log(err);
                });
            }
        },
        async purchasedcar(i, s)
        {
            if(i[0] == true){
                this.purchaseloading = true;
                await models.setTerminPurchased(this.data.atoken, s, i[1]).then(res => {
                    const toast = useToast();  
                    if(res.data['message'] == "success"){
                        if(s == 1){
                            toast.open({
                                message: 'Fahrzeug gekauft!',
                                type: 'success',
                                position: 'top-right'
                            });
                            this.purchasedstate = 1;
                        }else{
                            toast.open({
                                message: 'Fahrzeug nicht gekauft!',
                                type: 'error',
                                position: 'top-right'
                            });
                            this.purchasedstate = 2;
                        }
                    }else{
                        toast.open({
                            message: 'Es ist ein Fehler passiert!',
                            type: 'error',
                            position: 'top-right'
                        });
                    }
                    this.purchaseloading = false;
                    // this.$emit('reload', true);
                }).catch(err => {      
                    this.purchaseloading = false;
                    console.log(err);
                });
            }
        },
        async postponeAppointment(i, s) {
            // console.log(s);
            // console.log(i[1]+' '+i[2]);
            if(i[0] == true)
            {
                this.performingRequest = true;
                await models.setPostponeAppointment(this.data.atoken, s, i[1]+' '+i[2]).then(res => {
                    const toast = useToast();  
                    // console.log(res.data);
                    if(res.data['message'] == "success"){
                        if(s == 3){
                            toast.open({
                                message: 'Termin wird verschoben!',
                                type: 'success',
                                position: 'top-right'
                            });
                        }else{
                            toast.open({
                                message: 'Termin wird nicht verschoben!',
                                type: 'error',
                                position: 'top-right'
                            });
                        }
                    }else{
                        toast.open({
                            message: 'Es ist ein Fehler passiert!',
                            type: 'error',
                            position: 'top-right'
                        });
                    }
                    this.performingRequest = false;
                    this.$emit('reload', true);
                }).catch(err => {      
                    this.performingRequest = false;
                    console.log(err);
                });
            }
        },
        reload(){
            console.log("reload");
            this.$emit('close', '');
        },
        formatDateWithTime(str) {
            return new Date(str * 1000).toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' });
        },
    }
}

</script>


<style scoped src="../assets/css/car.css"></style>
<!-- <style scoped>

.detailsBtn{
    border: none !important;
    font-size: 14px !important;
    padding: 0px !important;
    margin-bottom: 10px !important;
    margin-top: 0px !important;
}

.detailsBtn:hover{    
    background-color: transparent !important;
    color: green !important;
}

</style> -->