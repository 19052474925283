<template>
    <div v-if="deleted == false" class="row bg-white border mt-4" style="padding:0px;">           
        <ImageCarousel v-bind:show="show" v-bind:images="rimgs" @showgallery="(i) => this.show = i" />
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3" style="padding:0px;position: relative;">    
            <div class="photocount">
                <span class="iconfloat-title"><img src="../assets/icons/camera.svg" style="height:15px; width:15px;"></span> {{photocount}}         
            </div>                  
            <div v-if="data.image != ''">
                <img @click="openGallery()" :src="data.image" @error="setAltImg" class="img-responsive" style="width: 300px; height: 250px; object-fit: cover;">
            </div>
            <div v-else>
                <img src="../assets/nopic2.png" class="img-responsive" style="width: 300px; height: 250px; object-fit: cover;">
            </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 mt-3">
            <h5 class="text-truncate">{{ data.title }}</h5>
            <div class="mt-2" style="font-size: 17px;">
                EZ: {{ data.ez }}<br>
                Kilometer: {{ this.$filters.toCurrency(data.km) }} km<br>                   
                Kraftstoff: {{ data.full }}<br>
                Getriebe: {{ data.getriebe }} 
                <div v-if="message.fin != '' && message.fin != null"><span style="color: green">FIN: {{ message.fin }}</span></div>
            </div>                                    
            <div class="mt-2" style="font-size: 14px;">                
                Entferung: {{this.distance}} km <br>
                Logistikkosten: {{this.delivery}} € (Netto)<br>
                SGP-Service: {{this.sgpservice}} € (Netto)
            </div>                                    
        </div>
        <div class="align-items-center align-content-center col-xs-12 col-sm-12 col-md-12 col-lg-3 border-left mt-1"> 
            
            <div style="position:relative;width: 100%;">
                <div style="position:absolute; top: 8px; right:10px;">                    
                    <img @click="openDeleteConfirm()" src="@/assets/icons/envelope-times.svg" title="Anfrage löschen" style="width: 32px;cursor: pointer;" alt="">                    
                </div> 
                <div style="position: absolute; top: 45px; right: 14px;">
                    <ChatOpener v-bind:data="data" />
                </div>
            </div>
                    
            <div class="d-flex flex-row align-items-center">                
                <div v-if="data.lastprice != '0'" class="mb-4">
                    <h4 class="mr-1" style="margin-top: 10px;">{{ this.$filters.toCurrency(data.lastprice) }} €  <small style="font-size: 11px;">{{data.mwst == '0' ? 'Ohne MwSt.' : 'Inkl. MwSt.' }}</small></h4>
                    <span class="price-badge" style="margin-right:20px;">- {{ this.$filters.toCurrency(getDiff(data.price, data.lastprice)) }} €</span>
                    <span style="font-size: 14px;"><s>{{ this.$filters.toCurrency(data.price) }} €</s></span>
                </div>
                <div v-else class="mb-1">
                    <h4 class="mr-1" style="margin-top: 10px;">{{ this.$filters.toCurrency(data.price) }} €  <small style="font-size: 11px;">{{data.mwst == '0' ? 'Ohne MwSt.' : 'Inkl. MwSt.' }}</small></h4>
                    <p class="text-success mb-4" style="font-size:14px;">Optional mit Lieferung</p>                    
                </div>
            </div>
            
            <div class="d-flex flex-column mt-2 text-center">    
                
                <div v-if="currentstatus == 0">
                    <div style="width:100%; background-color: #f2ab46;color:#fff;border-radius:15px;padding-top:6px;padding-bottom:6px;">{{status[currentstatus]}}</div>
                </div>
                <div v-if="currentstatus == 1">
                    <Purchase v-bind:data="JSON.parse(this.message.cardata)" v-bind:message="this.message" />                    
                </div>
                <div v-if="currentstatus == 2">
                    <div style="width:100%; background-color: #ff0000;color:#fff;border-radius:15px;padding-top:6px;padding-bottom:6px;">{{status[currentstatus]}}</div>
                </div>
                <div v-if="currentstatus == 3">
                    <div style="width:100%; background-color: #ff0000;color:#fff;border-radius:15px;padding-top:6px;padding-bottom:6px;">{{status[currentstatus]}}</div>
                </div>
                <div v-if="currentstatus == 5">
                   <CounterOfferBtn v-bind:data="this.message"  @status="(i) => currentstatus = i"/>                    
                </div>
                <div v-if="currentstatus == 6">
                    <div style="width:100%; background-color: #ff0000;color:#fff;border-radius:15px;padding-top:6px;padding-bottom:6px;">{{status[currentstatus]}}</div>
                </div>

                <CarDetailView v-bind:data="data" />

                <Dialog 
                    v-bind:showdialog="this.showdialog" 
                    v-bind:title="'Anfrage löschen?'" 
                    v-bind:text="'Wollen Sie die Anfrage wirklich löschen?'" 
                    v-bind:btnname="'Löschen'" 
                    v-bind:btnclass="'danger'" 
                    @closedialog="(i) => closeDeleteConfirm(i)"
                    @answer="(i) => deleteMessage(i)"
                />
            </div>
            </div>              
        </div>
    </template>

<script>
import models from "../models";
import { useToast } from 'vue-toast-notification'
import ImageCarousel from '@/components/ImageCarousel'    
import CarDetailView from '@/components/CarDetailView'    
import CounterOfferBtn from '@/components/CounterOfferBtn'    
import Purchase from '@/components/Purchase'    
import Dialog from '@/components/Dialog'    
import ChatOpener from '@/components/ChatOpener'    
import imgdefault from '@/assets/nopic2.png'

export default {
    name: 'MessageItem',
    components: {
        ImageCarousel,
        CarDetailView,
        Purchase,
        CounterOfferBtn,
        Dialog,
        ChatOpener
    },
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        message: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {        
            deleted: false,
            show: false,
            showdialog: false,
            rimgs: [],
            photocount: 0,
            distance: 0,
            delivery: 0,
            sgpservice: 0,
            status: [
                "In bearbeitung",
                "Fahrzeug kaufen",
                "Fahrzeug verkauft",
                "Mängel am Fahrzeug",
                "",
                "Gegenangebot",
                "Angebot abgelehnt"
            ],
            currentstatus: 0,
        }
    },     
    mounted () {  
        models.getDistance(this.data.lat, this.data.lon, this.$store.getters.StateUser['lat'], this.$store.getters.StateUser['lon']).then((response) => {
            this.distance = response;
            this.delivery = models.getDeliveryCosts(response)['net']
        })
        this.sgpservice = models.getSGPServiceCosts(this.data.price)['net']
        const imgs = this.data.images.split(",")
        for (let index = 0; index < imgs.length; index++) {
            const element = imgs[index]
            this.rimgs.push(element.replace('_8.JPG', '_20.JPG'))
            this.photocount = this.rimgs.length
        }
        this.currentstatus = this.message.status
    },
    methods: {
        setAltImg(event) { 
            event.target.src = imgdefault
        }, 
        getDiff: function (value1, value2) {
            var val = (Number(value1) - Number(value2));
            return val;
        },
        openGallery(){
            document.body.classList.add('noscroll')
            this.show = true;
        },                    
        openDeleteConfirm(){
            this.showdialog = true
        },
        closeDeleteConfirm(){
            this.showdialog = false
        },
        async deleteMessage(res){
            if(res == true){
                await models.deleteMessage(this.message.mtoken).then((response) => {
                    if(response.data['message'] == 'success'){
                        this.deleted = true
                        useToast().open({
                            message: 'Anfrage wurde gelöscht!',
                            type: 'error',
                            position: 'top-right'
                        });
                    }
                }).catch((error) => {
                    console.log(error);
                });
            }
        }      
    }
}

</script>

<style scoped src="../assets/css/car.css"></style>