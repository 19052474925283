<template>
    <div id="transport">
        <Loading v-bind:loading="performingRequest"/>
        <div class="sidebar">
            <Sidebar/>
        </div>
        <div class="body-text">

            <div class="container-fluid pagepadding">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1 mx-auto" style="padding-left: 10px;">
                        <h1><span class="iconfloat-title"><img src="../assets/icons/delivery.svg" style="height:30px; width:30px;"></span>  {{ title }}</h1>    
                        <span style="float: right; margin-top: -70px;">
                            <AddLogisticOrder @booked="(i) => reload()" />
                        </span>
                        <hr>            
                    </div>        
                </div> 

                <div v-if="ordercount == 0  && performingRequest != true" class="text-center">
                    <br>
                    <img src="../assets/truck.png" style="width:120px;"><br><br>
                    <h3>Noch keine Auftrage!</h3><br>
                    <p style="width:600px;margin:auto;">Mit unserem einfachen und kostengünstigen Logistikdienst können Sie problemlos Aufträge für Abholungen und Lieferungen erstellen. <br><br>Ganz gleich, ob es darum geht, Fahrzeuge abzuholen oder an einen Kunden zu liefern, wir bieten Ihnen eine effiziente Lösung.<br><br>Sparen Sie Zeit und Geld mit unserem SehrGuterPreis Logistikservice!</p>
                    <br><br>
                    
                </div>

                <div v-for="order in orders" :key="order.id" class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1 mx-auto">                 
                    <LogisticItem v-bind:data="order" />         
                </div>  

            </div> 

        <Footer/>
        </div>        
    </div>
</template>

<script>
    import models from "../models";
    import Sidebar from '@/components/Sidebar'
    import Loading from '@/components/Loading'    
    import Footer from '@/components/Footer'
    import AddLogisticOrder from '@/components/AddLogisticOrder'
    import LogisticItem from '@/components/LogisticItem'

    export default {
        name: 'TransportView',
        components: {
            Sidebar,
            Loading,
            AddLogisticOrder,
            LogisticItem,
            Footer
        },
        data() {
            return {
                performingRequest: true,
                orders: [],
                ordercount: 0
            }            
        }, 
        computed: {    
            title: () => {
                return "Logistikaufträge"
            }
        },
        mounted() {            
            this.getData();
        },
        methods:{
            async getData(){
                await models.getlogistcOrders(this.$store.getters.StateToken).then(res => {
                    this.orders = res.data; 
                    this.ordercount = res.data.length                  
                    this.performingRequest = false;
                }).catch(err => {      
                    this.performingRequest = false;
                    console.log(err);
                });
            },
            async reload(){
                this.orders = [];
                this.getData();
            }
        }
    }    
</script>

<style scoped src="../assets/css/app.css"></style>
<style>

</style>