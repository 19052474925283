<template>
    <div>
        <Loading v-bind:loading="performingRequest"/>
        <BuyStationDialog 
            v-bind:showdialog="this.showdialog" 
            @closedialog="(i) => closeAnkaufstationConfirm(i)"
            @answer="(i) => confirmAnkaufStation(i)"
        />
        <div class="row mt-5">
            <div class="col-xs-7 col-sm-7 col-md-7" style="padding-left: 10px;">
                <h1 style="font-size: 50px;font-weight: 600;margin-bottom: 30px;">Ankauf Termine</h1>                                                   
                <p style="font-size: 27px;">Effiziente Ankauftermine für Autohändler<br>
                Faire Preise und bequeme Abwicklung!</p><br>
                <a @click="openDialog()" class="btn btn-lg btn-success">Jetzt Ankaufstation werden</a>
            </div>        
            <div class="col-xs-5 col-sm-5 col-md-5 text-center" style="padding-top: 30px;">
                <img src="../assets/radar.png" alt="">
            </div>        
            <div class="col-xs-12 col-sm-12 col-md-12 mt-5">
                <hr>
            </div>
        </div> 
        
        <div class="row mt-5">
            <div class="col-xs-12 col-sm-12 col-md-12 text-center" style="padding-left: 10px;">
                <h1 style="font-size: 33px;font-weight: 600;margin-bottom: 60px;">
                    Entdecken Sie unseren<br>
                    exklusiven Service für Autohändler! 
                </h1>                        
            </div>        
            <div class="col-xs-5 col-sm-5 col-md-5 text-center" style="padding-top: 30px;">
                <img src="../assets/SGA-check.png" width="250" style="margin-top: 20px;">
            </div>    
            <div class="col-xs-7 col-sm-7 col-md-7" style="padding-left: 10px;">
                <p>Mit SehrGuterPreis in kooperation mit VEEKEL erhalten Sie 
                effiziente Ankauftermine, bei denen private Autoverkäufer 
                direkt zu Ihnen kommen. Wir wählen nur Fahrzeuge aus, 
                die genau Ihrem Interesse entsprechen.</p>
                <p>Wir verstehen die Bedürfnisse von Autohändlern und bieten Ihnen 
                eine bequeme Abwicklung, transparente Preise und faire Bedingungen. 
                Vertrauen Sie auf unsere Expertise und erleben Sie eine stressfreie 
                Zusammenarbeit, die Ihnen Zeit und Aufwand spart.
                </p>
                <p>Werden Sie eine Veekel Ankaufstation und sichern 
                Sie sich exklusive Vorteile für Ihren Autohandel!</p>
            </div>   
            <div class="col-xs-12 col-sm-12 col-md-12 mt-5">
                <hr>
            </div>                         
        </div> 

        <div class="row mt-5">
            <div class="col-xs-12 col-sm-12 col-md-12 text-center" style="padding-left: 10px;">
                <h1 style="font-size: 33px;font-weight: 600;margin-bottom: 60px;">
                    Unser Ablauf beim Ankauf
                </h1>                        
            </div>      
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 col-md-offset-2 col-lg-offset-2 mx-auto">
                
                <div class="row">
                    <div class="col-xs-3 col-sm-3 col-md-3">
                        <div class="sgp-circle">1</div>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9">
                        <h3 style="font-size: 25px;">Wir senden Ihnen Ankauf Termine</h3>
                        <p style="font-size: 21px;">Wir bieten Ihen nur Fahrzeuge an, die Ihren 
                        Suchkriterien entsprechen.</p>
                    </div>   
                </div>
                <div class="row mt-4">
                    <div class="col-xs-3 col-sm-3 col-md-3">
                        <div class="sgp-circle">2</div>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9">
                        <h3 style="font-size: 25px;">Sie entscheiden ob es für Sie passt</h3>
                        <p style="font-size: 21px;">Nehmen Sie Termine an oder lehnen Sie ab. <br>
                        Keine Mindestabnahme.</p>
                    </div>   
                </div>
                <div class="row mt-4">
                    <div class="col-xs-3 col-sm-3 col-md-3">
                        <div class="sgp-circle">3</div>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9">
                        <h3 style="font-size: 25px;">Der Kunde kommt zu Ihnen</h3>
                        <p style="font-size: 21px;">Wenn Sie den Termin bestätigen kommt der Kunde
                        zu Ihnen ins Autohaus.</p>
                    </div>   
                </div>
                
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 mt-5">
                <hr>
            </div> 
        </div> 

        <div class="row mt-5">
            <div class="col-xs-12 col-sm-12 col-md-12 text-center">
                <h1 style="font-size: 33px;font-weight: 600;margin-bottom: 60px;">
                    Unser Direkt Ankauf im Vergleich
                </h1>                        
            </div>   
            
            <div class="row mt-4">
                <div class="col-xs-3 col-sm-3 col-md-3 text-left"></div>   
                <div class="col-xs-3 col-sm-3 col-md-3 text-center">
                    <img src="../assets/sgp-logo.png" alt="">
                </div>   
                <div class="col-xs-3 col-sm-3 col-md-3 text-center">
                    <img src="../assets/as24-logo.png" alt="">
                </div>   
                <div class="col-xs-3 col-sm-3 col-md-3 text-center">
                    <img src="../assets/mobile-logo.png" alt="">
                </div>   
                <div class="col-xs-12 col-sm-12 col-md-12 mt-2">
                    <hr>
                </div> 
            </div>   
            
            <div class="row mt-4">
                <div class="col-xs-3 col-sm-3 col-md-3 text-left">
                    Monatliche Gebühren
                </div>   
                <div class="col-xs-3 col-sm-3 col-md-3 text-center">
                    <b>0 €</b>
                </div>   
                <div class="col-xs-3 col-sm-3 col-md-3 text-center">
                    <b>199 €</b>
                </div>   
                <div class="col-xs-3 col-sm-3 col-md-3 text-center">
                    <b>400 €</b>
                </div>   
                <div class="col-xs-12 col-sm-12 col-md-12 mt-4">
                    <hr>
                </div> 
            </div>   

            <div class="row mt-4">
                <div class="col-xs-3 col-sm-3 col-md-3 text-left">
                    Vermittlungsprovision
                </div>   
                <div class="col-xs-3 col-sm-3 col-md-3 text-center">
                    <b>2 %</b>
                </div>   
                <div class="col-xs-3 col-sm-3 col-md-3 text-center">
                    <b>4 %</b>
                </div>   
                <div class="col-xs-3 col-sm-3 col-md-3 text-center">
                    <b>4 %</b>
                </div>   
                <div class="col-xs-12 col-sm-12 col-md-12 mt-4">
                    <hr>
                </div> 
            </div>   
            
            <div class="row mt-4">
                <div class="col-xs-3 col-sm-3 col-md-3 text-left">
                    Ankauf Gebühr MIN.
                </div>   
                <div class="col-xs-3 col-sm-3 col-md-3 text-center">
                    <b>100 €</b>
                </div>   
                <div class="col-xs-3 col-sm-3 col-md-3 text-center">
                    <b>125 €</b>
                </div>   
                <div class="col-xs-3 col-sm-3 col-md-3 text-center">
                    <b>150 €</b>
                </div>   
                <div class="col-xs-12 col-sm-12 col-md-12 mt-4">
                    <hr>
                </div> 
            </div>   
            
            <div class="row mt-4">
                <div class="col-xs-3 col-sm-3 col-md-3 text-left">
                    Ankauf Gebühr MAX.
                </div>   
                <div class="col-xs-3 col-sm-3 col-md-3 text-center">
                    <b>500 €</b>
                </div>   
                <div class="col-xs-3 col-sm-3 col-md-3 text-center">
                    <b>700 €</b>
                </div>   
                <div class="col-xs-3 col-sm-3 col-md-3 text-center">
                    <b>700 €</b>
                </div>   
                <div class="col-xs-12 col-sm-12 col-md-12 mt-4">
                    <hr>
                </div> 
            </div>   
            
            <div class="row mt-4">
                <div class="col-xs-3 col-sm-3 col-md-3 text-left">
                    Mindestabnahme
                </div>   
                <div class="col-xs-3 col-sm-3 col-md-3 text-center">
                    <b>Nein</b>
                </div>   
                <div class="col-xs-3 col-sm-3 col-md-3 text-center">
                    <b>Ja</b>
                </div>   
                <div class="col-xs-3 col-sm-3 col-md-3 text-center">
                    <b>Ja</b>
                </div>   
                <div class="col-xs-12 col-sm-12 col-md-12 mt-4">
                    <hr>
                </div> 
            </div>   

        </div>   
        
        <div class="row mt-5">
            <div class="col-xs-12 col-sm-12 col-md-12 text-center" style="padding-left: 10px;">
                <h1 style="font-size: 33px;font-weight: 600;margin-bottom: 60px;">
                    🚗💨 Jetzt VEEKEL Ankaufstation werden !
                </h1>                        
                <p style="font-size: 23px;">
                    Jetzt anmelden und exklusive Termine sichern!<br>
                    Einfach online anmelden mit einem Klick. 
                    <br>
                </p>
                <p style="font-size: 18px;">
                    Danach wird unser Team Ihre Anmeldung prüfen und Sie als Ankaufstation freischalten. <br>
                    Sobald Sie freigeschaltet sind, werden Sie von uns per E-Mail benachrichtigt.<br>
                    Alle zukünfitigen Ankauf Termine finden Sie dann auf dieser Seite hier.<br>
                </p>
                <p style="font-size: 16px;">
                    Keine Vertragsmindestlaufzeit 👍<br>
                    Keine Mindestabnahme 👍<br>                            
                    Keine versteckten Kosten 👍<br>
                    Monatlich kündbar 👍<br>                    
                </p>
                <br>                
                <a @click="openDialog()" class="btn btn-lg btn-success">Jetzt Ankaufstation werden</a>
                <br><br><br>
                <p><b>Sie haben Fragen oder benötigen Beratung zum SehrGuterPreis Direkt-Verkauf?</b></p>
                <p style="font-size: 16px;">Ihr SehrGuterPreis-Kundenservice ist telefonisch von<br>Montag bis Freitag 9 bis 18 Uhr erreichbar. <br>
                info@sehrguterpreis.de<br>
                +49 (0)2171 77 69 559<br>
                </p>
                <br><br>
            </div>   
        </div>   
    </div>
</template>



<script>    
import models from "../models";
import { useToast } from 'vue-toast-notification'
import BuyStationDialog from '@/components/BuyStationDialog'    
import Loading from '@/components/Loading'    

export default {
    name: 'BuyingStationView',
    components: {
        BuyStationDialog,
        Loading
    },
    data() {
        return {
            showdialog: false,
            performingRequest: false
        }
    }, 
    computed: {},
    mounted() {},
    methods: {
        openDialog(){
            this.showdialog = true;
            document.body.classList.add('noscroll');
        },
        closeAnkaufstationConfirm(){
            this.showdialog = false;
            document.body.classList.remove('noscroll')
        },
        async confirmAnkaufStation(i){
            if(i == true)
            {
                this.performingRequest = true;
                await models.addUserAsAnkaufStation(this.$store.getters.StateToken).then(res => {                    
                    if(res.data['message']=="success"){
                        const toast = useToast(); 
                        toast.open({
                            message: 'Wir haben Ihren Anfrage erhalten!',
                            type: 'success',
                            position: 'top-right'
                        });
                        this.$emit('reload', true);
                    }
                    this.performingRequest = false;
                }).catch(err => {
                    console.log(err);
                    this.performingRequest = false;
                    const toast = useToast(); 
                    toast.open({
                        message: 'Es ist ein Fehler aufgetreten!',
                        type: 'error',
                        position: 'top-right'
                    });
                });
            }   
        }   
    }
}   
</script>


<style>
    .sgp-circle{
        display: block; 
        width: 90px; 
        height: 90px; 
        background-color: #33b22b; 
        color: #fff; 
        font-size: 46px; 
        font-weight: bold; 
        border-radius: 45px; 
        text-align: center;
        padding-top: 13px; 
        margin-top: 10px;
    }
</style>