<template>
    <div id="sales">
        <Loading v-bind:loading="performingRequest"/>
        <div class="sidebar"><Sidebar/></div>
        <div class="body-text">

            <div class="container-fluid pagepadding">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1 mx-auto" style="padding-left: 10px;">
                    <h1><span class="iconfloat-title"><img src="../assets/icons/shopping-basket.svg" style="height:24px; width:24px;"></span>  {{ title }}</h1>     
                    <hr>          
                    </div>        
                </div> 
            </div> 

            <div v-if="salescount == 0  && performingRequest != true" class="text-center">
                <br><br><br>
                <h3>Noch keine Buchung!</h3>
                <p>Jetzt Fahrzeug suchen und buchen!</p>
                <br><br><br>
                <img src="../assets/nosale.png" style="width:200px;">
            </div>

            <div v-for="sale in sales" :key="sale.carid" class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1 mx-auto">                 
                <SaleItem v-bind:data="JSON.parse(sale['cardata'])" v-bind:sales="sale" @dealerpaidsales="(i) => dealerpaidfunc(i)" @dealdonesales="(i) => dealdonefunc()" />         
            </div>
        
        <Footer/>
        </div>        
    </div>
</template>

<script>
import models from "../models";
import Sidebar from '@/components/Sidebar'
import Loading from '@/components/Loading'    
import Footer from '@/components/Footer'
import SaleItem from '@/components/SaleItem'

export default {
    name: 'SalesView',
    components: {
        Sidebar,
        Loading,
        Footer,
        SaleItem
    },
    data() {
        return {
            performingRequest: true,
            salescount: 0,
            sales: {}            
        }
    }, 
    computed: {    
        title: () => {
            return "Buchungen"
        }
    },
    async mounted() {
        this.getSales();
    },
    methods: {
        async getSales(){
            this.performingRequest = true;
            await models.getSales(this.$store.getters.StateToken).then( async res => {
                this.sales = res.data
                this.salescount = res.data.length
                this.performingRequest = false            
            }).catch(err => {
                this.performingRequest = false
                console.log(err)
            });
        },
        dealdonefunc(){
            this.getSales();
        },
        dealerpaidfunc(i){
            this.show = i;
            this.getSales();
        }
    }
}    
</script>

<style scoped src="../assets/css/app.css"></style>
<style>

</style>