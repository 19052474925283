<template>
    <div id="temp">
        <Loading v-bind:loading="performingRequest"/>
        <div class="sidebar"><Sidebar/></div>
        <div class="body-text">

            <div class="container-fluid pagepadding">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1 mx-auto" style="padding-left: 10px;">
                    <h1><span class="iconfloat-title"><img src="../assets/icons/comments-alt-2.svg" style="height:24px; width:24px;"></span>  {{ title }}</h1>                     
                    <hr>          
                    </div>        
                </div> 
            </div> 

            <div v-if="chatcount == 0 && performingRequest != true" class="text-center">
                <br><br><br>
                <h3>Noch keine Nachrichten!</h3>
                <p>Jetzt Fahrzeug suchen und Frage stellen!</p>
                <br><br><br>
                <img src="@/assets/icons/comments-alt-2-green.svg"  style="width:180px;">
            </div>

            <div v-for="chat in this.chats" :key="chat.idchat" class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1 mx-auto">                
                <ChatListItem v-bind:data="chat" v-bind:car="JSON.parse(chat['cardata'])" @close="() => getChatData()"/>                                             
            </div>
        
        <Footer/>
        </div>        
    </div>
</template>

<script>
import models from "../models";
import Sidebar from '@/components/Sidebar'
import Loading from '@/components/Loading'    
import Footer from '@/components/Footer'
import ChatListItem from '@/components/ChatListItem'
export default {
    name: 'ChatListsView',
    components: {
        Sidebar,
        Loading,
        ChatListItem,
        Footer
    },
    data() {
        return {
            performingRequest: true,
            chatcount: 0,
            chats: []
        }
    }, 
    computed: {    
        title: () => {
            return "Nachrichten"
        }
    },
    mounted() {
        this.chatcount = 0;
        this.chats = [];
        this.getChatData();
    },
    methods: {
        async getChatData(){                
            await models.getUserChats(this.$store.getters.StateToken).then(res => {
                console.log(res.data);
                this.chats = res.data['chats'];
                this.chatcount = res.data['chats'].length;                    
                this.performingRequest = false;
            }).catch(err => {      
                this.performingRequest = false;
                console.log(err);
            });
        }
    }
}    
</script>

<style scoped src="../assets/css/app.css"></style>
<style>

</style>