<template>
    <div class="row bg-white border mt-4" style="padding:0px;">                   
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3" style="padding:0px;position: relative;">                     
            <div v-if="this.parked" class="parkcount">
                Geparked
            </div>     
            <div v-if="sales.mdata != ''">
                <img :src="'https://www.sehrguterpreis.de/soldcars/'+sales.mdata" @error="setAltImg" class="img-responsive" style="width: 300px; height: 250px; object-fit: cover;">
            </div>
            <div v-else>
                <img src="../assets/nopic2.png" class="img-responsive" style="width: 300px; height: 250px; object-fit: cover;">
            </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 mt-3">
            <h5 class="text-truncate">{{ data.title }}</h5>
            <div class="mt-2" style="font-size: 17px;">
                EZ: {{ data.ez }}<br>
                Kilometer: {{ this.$filters.toCurrency(data.km) }} km<br>                   
                Kraftstoff: {{ data.full }}<br>
                Getriebe: {{ data.getriebe }} 
                <div v-if="sales.fin != '' && sales.fin != null"><span style="color: green">FIN: {{ sales.fin }}</span></div>
            </div>                                    
            <div class="mt-2" style="font-size: 14px;">                
                Entferung: {{this.distance}} km <br>
                Logistikkosten: {{this.delivery}} € (Netto)<br>
                SGP-Service: {{this.sgpservice}} € (Netto)
            </div>                                    
        </div>
        <div class="align-items-center align-content-center col-xs-12 col-sm-12 col-md-12 col-lg-3 border-left mt-1"> 

            <div style="position:relative;width: 100%;">
                <div style="position:absolute; top: 10px; right:10px;">
                    <div v-if="paid == true && sales.status != 3">
                        <span class="badge paid">Bezahlt</span>
                    </div>                    
                    <div v-if="paid == false && sales.status != 3">
                        <span class="badge notpaid">Nicht Bezahlt</span>
                    </div>                    
                </div> 
                <div style="position: absolute; top: 52px; right: 12px;">
                    <ChatOpener v-bind:data="data" />
                </div>
            </div>
                    
            <div class="d-flex flex-row align-items-center">                                
                <h4 class="mr-1" style="margin-top: 10px;">{{ this.$filters.toCurrency(sales.price) }} €</h4>                                    
            </div>
            
            <div class="d-flex flex-column mt-5 text-center">    
                
                <div v-if="dealdone == true">
                    <div v-if="paid == false">
                        <div style="width:100%; font-size: 16px; background-color: #f2ab46;color:#fff;border-radius:15px;padding-top:6px;padding-bottom:6px;">Fahrzeug bezahlen</div>
                    </div>
                    <div v-else>
                        <div v-if="sales.status == 0">
                            <div style="width:100%; font-size: 16px; background-color: #f2ab46;color:#fff;border-radius:15px;padding-top:6px;padding-bottom:6px;">{{status[sales.status]}}</div>
                        </div>
                        <div v-if="sales.status == 1">
                            <div style="width:100%; font-size: 16px; background-color: #5ea3ee;color:#fff;border-radius:15px;padding-top:4px;padding-bottom:6px;">{{status[sales.status]}}</div>
                        </div>
                        <div v-if="sales.status == 2">
                            <div style="width:100%; font-size: 16px; background-color: #48B213;color:#fff;border-radius:15px;padding-top:6px;padding-bottom:6px;">{{status[sales.status]}}</div>
                        </div>
                        <div v-if="sales.status == 3">
                            <div style="width:100%; font-size: 16px; background-color: red;color:#fff;border-radius:15px;padding-top:6px;padding-bottom:6px;">{{status[sales.status]}}</div>
                        </div>
                    </div>
                </div>
                <div v-else-if="dealdone == false">                    
                    <div style="width:100%; font-size: 16px; background-color: #f2ab46;color:#fff;border-radius:15px;padding-top:6px;padding-bottom:6px;">Geschäft abschließen</div>
                </div>

                <div v-if="sales.status != 3">
                    <SaleDetails v-bind:data="data" v-bind:sale="sales" @paid="(i) => dealerpaid_func(i)" @dealdone="(i) => dealdone_func(i)" @storno="(i) => storno_func(i)" />
                </div>
                
            </div>
        </div>              
     </div>
</template>

<script>
import models from "../models";
import imgdefault from '@/assets/nopic2.png'
import SaleDetails from '@/components/SaleDetails.vue'
import ChatOpener from '@/components/ChatOpener.vue'

export default {
    name: 'SaleItemView',
    components: {
        SaleDetails,
        ChatOpener
    },
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        sales: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {        
            parked: false,
            show: false,
            paid: false,
            dealdone:false,
            storno:false,
            rimgs: [],
            photocount: 0,
            distance: 0,
            delivery: 0,
            sgpservice: 0,
            status: [
                "Auslieferung vorbereiten",
                "Termin für Auslieferung",
                "Ausgeliefert",
                "Storniert"
            ]
        }
    },     
    mounted () {  
        models.getDistance(this.data.lat, this.data.lon, this.$store.getters.StateUser['lat'], this.$store.getters.StateUser['lon']).then((response) => {
            this.distance = response;
            this.delivery = models.getDeliveryCosts(response)['net']
        })
        this.sgpservice = models.getSGPServiceCosts(this.data.price)['net']
        // const imgs = this.data.images.split(",")
        // for (let index = 0; index < imgs.length; index++) {
        //     const element = imgs[index]
        //     this.rimgs.push(element.replace('_8.JPG', '_20.JPG'))
        //     this.photocount = this.rimgs.length
        // }  
        if(this.sales.dealerpaid == 1){ this.paid = true }     
        if(this.sales.deal_done == 1){ this.dealdone = true }     
        if(this.sales.status == 3){ this.storno = true }     
    },
    methods: {    
        setAltImg(event) { 
            event.target.src = imgdefault
        },  
        dealdone_func(i) {
            this.dealdone = i;
            this.$emit('dealdonesales', true);
        },
        storno_func(i) {
            this.storno = i;
            this.dealdone = true;
            this.paid = true;
            this.show = false;
            this.$emit('dealdonesales', true);
        },
        dealerpaid_func(i) {
            this.show = i;
            // this.sales.dealerpaid = 1;
            this.paid = true;
            this.$emit('dealerpaidsales', true);
        }      
    }
}

</script>


<style scoped src="../assets/css/car.css"></style>
<style>
.notpaid{
    background: #d8d8d8;
    color: #878787;
    border-radius: 30px;
    border: 1px solid #cecece !important;
}
</style>