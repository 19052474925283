<template>    
    <div class="row bg-white border mt-4" style="padding:0px;">                  
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3" style="padding:0px;position: relative;">                    
            <div v-if="data.image != ''">
                <img :src="data.image" @error="setAltImg" class="img-responsive" style="width: 300px; height: 220px; object-fit: cover;">
            </div>
            <div v-else>
                <img src="../assets/nopic2.png" class="img-responsive" style="width: 300px; height: 220px; object-fit: cover;">
            </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 mt-3">
            <a style="text-decoration: none;" :href="'https://suchen.mobile.de/fahrzeuge/details.html?id='+data.mid+'&adLimitation=ONLY_FSBO_ADS&damageUnrepaired=NO_DAMAGE_UNREPAIRED&export=NO_EXPORT&isSearchRequest=true&makeModelVariant1.makeId=20100&maxFirstRegistrationDate=2021-12-31&minFirstRegistrationDate=2017-01-01&pageNumber=1&scopeId=C&sfmr=false&action=eyeCatcher&fnai=prev&searchId=030d4cfe-661c-4ede-f78c-8c02e2387fd4'" target="_blank" rel="noreferrer">
                <h5 class="text-truncate">{{ data.title }}</h5>
            </a>
            <div class="mt-2" style="font-size: 17px;">
                EZ: {{ data.ez }}<br>
                Kilometer: {{ this.$filters.toCurrency(data.km) }} km<br>                   
                Kraftstoff: {{ data.fuel }}<br>
                Getriebe: {{ data.gear }} <br>
                Preislabel: {{ data['pricelabel'] }} <br>
                Standtage: {{ data['standdays'].toFixed(0) }} Tag(e) <br>
            </div>                                                                                  
        </div>
        <div class="align-items-center align-content-center col-xs-12 col-sm-12 col-md-12 col-lg-3 border-left mt-1">             
            <div class="d-flex flex-column mt-1 text-center">                                                
                <h4 class="mr-1" style="margin-top: 10px;">{{ this.$filters.toCurrency(data.price) }} € </h4>                
            </div>
        </div>              
    </div>
</template>

<script>
import imgdefault from '@/assets/nopic2.png'

export default {
    name: 'StockDetailItem',
    components: {
    },
    props: {
        data: {
            type: Object,
            default: () => {}
        },        
    },
    data() {
        return {                    
            
        }
    },     
    mounted () {  
        // console.log(this.data);
    },
    methods: {    
        setAltImg(event) { 
            event.target.src = imgdefault
        },                 
    }
}

</script>


<style scoped src="../assets/css/car.css"></style>