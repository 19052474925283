<template>
    <div id="messages">
        <Loading v-bind:loading="performingRequest"/>
        <div class="sidebar"><Sidebar/></div>
        <div class="body-text">

            <div class="container-fluid pagepadding">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1 mx-auto" style="padding-left: 10px;">
                    <h1><span class="iconfloat-title"><img src="@/assets/icons/envelope.svg" style="height:24px; width:24px;"></span>  {{ title }}</h1>    
                    <hr>          
                    </div>        
                </div> 
            </div>   
            
            <div v-if="msgcount == 0  && performingRequest != true" class="text-center">
                <br><br><br>
                <h3>Noch keine Anfragen!</h3>
                <p>Jetzt Fahrzeug suchen und anfragen!</p>
                <br><br><br>
                <img src="@/assets/nomsg.png" style="width: 170px;">                
            </div>
            
            <div v-for="message in messages" :key="message.carid" class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1 mx-auto">                 
                <MessageItem v-bind:data="JSON.parse(message['cardata'])" v-bind:message="message" v-bind:parkedcars="this.parkedcars"/>         
            </div>

        <Footer/>
        </div>        
    </div>
</template>

<script>
import models from "../models";
import Sidebar from '@/components/Sidebar'
import Loading from '@/components/Loading'    
import Footer from '@/components/Footer'
import MessageItem from '@/components/MessageItem'

export default {
    name: 'MessagesView',
    components: {
        Sidebar,
        Loading,
        Footer,
        MessageItem
    },
    data() {
        return {
            performingRequest: true,
            msgcount: 0,
            messages: null,
            parkedcars: []
        }
    }, 
    computed: {    
        title: () => {
            return "Anfragen"
        }
    },
    async mounted () {
        this.performingRequest = true
        await models.getMessages(this.$store.getters.StateToken).then( async res => {                            
            // console.log(res.data['messages']);
            this.messages = res.data['messages'];
            this.msgcount = res.data['messages'].length;
            this.performingRequest = false;
            for (let index1 = 0; index1 < res.data['parkedcars'].length; index1++) {
                const element = res.data['parkedcars'][index1];        
                this.parkedcars.push(element['mid']);
            }
        }).catch(err => {
            this.performingRequest = false;
            console.log(err);
        });
    }
}    
</script>

<style scoped src="../assets/css/app.css"></style>
<style>

</style>