<template>  
  <div class="home"> 
    <Loading v-bind:loading="performingRequest"/> 
    <BlockedView v-bind:blockedshow="this.blocked" />
      <div class="sidebar"><Sidebar/></div>
      <div class="body-text">    
        <div class="container-fluid pagepadding">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1 mx-auto" style="padding-left: 10px;">
              <h1><span class="iconfloat-title"><img src="../assets/icons/tag.svg" style="height:24px; width:24px;"></span> {{ title }} <span class="badge">{{carcount}}</span></h1>                
              <span style="float: right; margin-top: -70px;">                
                <span @click="resetFilter()" v-if="markeid != ''" style="float: left;margin-top: 8px; margin-right: 20px;font-size:15px;cursor: pointer;" class="text-danger">Zurücksetzen</span>                
                <select @change="onChangeMake($event)" ref="makeSelect" v-model="markeid" class="form-control" style="width: 180px;" name="makes">                                    
                    <option value="">Alle Marken</option>
                    <option v-for="make in makes.makedata" :key="make.makeid"  :value="make.makeid">{{ make.make }}</option>
                </select>
              </span>
              <hr>          
            </div>
          </div>   
          
          <div v-if="carcount == 0  && performingRequest != true" class="text-center">
            <br><br><br>
            <h3>Kein Fahrzeug gefunden!</h3>
            <br>
            <img src="../assets/nocar.png" style="width:250px;">                            
          </div>

          <div v-if="filtered == false">
            <div v-for="car in cars" :key="car.carid" class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1 mx-auto">                 
              <CarItem v-bind:data="car" v-bind:parkedcars="this.parkedcars"/>         
            </div>  
          </div>
          <div v-else-if="filtered">
            <div v-for="car in filteredlist" :key="car.carid" class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1 mx-auto">                 
              <CarItem v-bind:data="car" v-bind:parkedcars="this.parkedcars"/>         
            </div> 
          </div>

        </div>    
        <Footer/>  
      </div> 
  </div>        
</template>

<script>
import models from "../models";
import makes from '@/config/makes.js'
import Sidebar from '@/components/Sidebar'
import Loading from '@/components/Loading'
import CarItem from '@/components/CarItem'
import Footer from '@/components/Footer'
import BlockedView from '@/components/BlockedView'


export default {
  name: 'HomeView',    
  components: {
    Sidebar,
    Loading,
    CarItem,
    Footer,
    BlockedView
  },
  data() {
    return {
      cars: [],
      parkedcars: [],      
      blocked: false,
      filteredlist: [],
      filtered: false,
      notification: null,
      performingRequest: true,
      carcount: 0,
      makes: makes,
      markeid: '',      
      marke: 'Alle Marken'
    }
  },  
  computed: {    
    title: () => {
      return "Sofort Verfügbar"
    },
  },
  async mounted () 
  {
    this.getData();
  }, 
  methods: {
    async getData(){
      await models.getHome(this.$store.getters.StateToken).then(async res => {
        this.cars = res.data['available'];
        if(res.data['blocked'] == '0' && this.$store.getters.StateUser['zugang'] == '0'){
          this.blocked = true;
          this.$store.getters.StateUser['zugang'] = 1;
        }else if(res.data['blocked'] == '0' && this.$store.getters.StateUser['zugang'] =='1'){
          await this.$store.dispatch("LogOut");
          this.$router.push("/login");
          this.$router.go(0);
        }
        this.carcount = res.data['available'].length;
        for (let index1 = 0; index1 < res.data['parkedcars'].length; index1++) {
          const element = res.data['parkedcars'][index1];        
          this.parkedcars.push(element['mid']);
        }
        this.performingRequest = false;
      }).catch(err => {      
        this.performingRequest = false;
        console.log(err);
      });
    },    
    async onChangeMake(event) {
      this.markeid = event.target.value
      this.marke = event.target.options[event.target.selectedIndex].text        
      if(this.markeid != "")
      { 
        var newlist = []
        this.filteredlist = []
        this.cars.forEach(item => {
          if(item.makeid == this.markeid){
            newlist.push(item)
          }
        });
        this.filteredlist = newlist
        this.carcount = newlist.length
        this.filtered = true        
      }else{
        console.log('nofilter')
        this.carcount = this.cars.length        
        this.filteredlist = {}
        this.filtered = false
      }
    },
    resetFilter(){
      this.markeid = ''
      this.marke = 'Alle Marken'
      this.carcount = this.cars.length        
      this.filteredlist = {}
      this.filtered = false
      var makeSelect = this.$refs.makeSelect.children;
      if (makeSelect.length) {
        this.markeid = makeSelect[0].value;
      }      
    }  
  }
}
</script>


<style scoped src="../assets/css/app.css"></style>
<style scoped></style>
