<template>
    <div v-if="show" class="imgview" >
        <transition name="fade" class="loading">
            <div>
                <div @click="closing()" class="close">
                    <img src="@/assets/icons/times.svg" alt="">
                </div>
                <div>                    
                    <Splide :options="{ rewind: true }" aria-label="My Favorite Images">
                        <SplideSlide v-for="img in images" v-bind:key="img">
                            <img :src="img" class="img" @error="setAltImg">
                        </SplideSlide>                     
                    </Splide>                    
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import imgdefault from '@/assets/nopic2.png'
import '@splidejs/vue-splide/css';

export default {
    name: 'ImageCarousel',
    props: ['show', 'images'],   
    data: () => ({
        settings: {
        itemsToShow: 1,
        snapAlign: 'center',
        wrapAround: false
        },        
        breakpoints: {
        700: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        1024: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        },
    }),
    components: {
        Splide,
        SplideSlide,
    },
    methods: {
        setAltImg(event) { 
            event.target.src = imgdefault
        }, 
        closing() {
            document.body.classList.remove('noscroll')
            this.$emit('showgallery', false)            
        },       
    } 
}
</script>

<style scoped>
.imgview{
    display: flex;
    justify-content: center;
    text-align: center;
}

.img{
    max-width: 80%;
    max-height: 80%; 
    margin: auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);    
}

.close{
    position: fixed;
    display: block;
    width: 150px;
    height: 150px;
    top: 10px;
    right: 20px;
    cursor: pointer;
    z-index: 99999999;
}

.loading {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.7);
	z-index: 9999;
    padding-top: 0px;
}
</style>