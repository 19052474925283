<template>
  <div class="passwordreset">
    <LoginHeader/>
    <div class="container">
      <div class="col-md-6 offset-md-3">
        <br>           
        <p><b>Passwort vergessen?</b></p>  
        <p>Bitte geben Sie Ihre E-Mail-Adresse ein und wir senden<br>Ihnen ein neues Passwort per E-Mail zu.</p>  
        <br>
        <input class="form-control" style="height:60px;" type="text" v-model="email" placeholder="E-Mail">
        <br><br>        
        <div v-if="loading == false">
          <button @click="resetPassword()" class="btn btn-lg btn-success">Passwort zurücksetzten</button>
        </div>
        <div v-else>          
          <button disabled class="btn btn-lg btn-success">Passwort zurücksetzten</button>
        </div>
        
        <br><br>
        <p><router-link to="/login" style="color: #000;text-decoration: none;"><b>Zurück zum Login</b></router-link></p>
      </div>
    </div>
  </div>
</template>

<script>
import LoginHeader from '@/components/LoginHeader'
import { useToast } from 'vue-toast-notification'
import models from "../models";

export default {
  name: 'PasswordReset',
  data() {
    return {
      email: '',
      loading: false
    }
  },
  components: {
    LoginHeader
  },
  methods: {
    async resetPassword(){
      this.loading = true;
      await models.resetPassword(this.email).then(res => { 
          // console.log(res.data)
          if(res.data.message == 'success'){     
              this.loading = false; 
              this.email = '';     
              useToast().open({
                  message: 'Ihr neues Passwort wurde versendet!',
                  type: 'success',
                  position: 'top-right',
              });              
          }else{
              this.loading = false;
              useToast().open({
                  message: 'Es ist ein Fehler aufgetreten!',
                  type: 'error',
                  position: 'top-right',
              });
          }
      }).catch(() => {
          this.show = false;
      });
    }
  }
}
</script>

<style scoped>

.passwordreset{
  text-align: center;
}

a:hover{
  text-decoration: none;  
}
</style>
