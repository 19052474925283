import { createWebHistory, createRouter } from "vue-router";
import store from "../store";
import Login from '@/views/Login'
import Home from '@/views/Home'
import Cars from '@/views/Cars'
import Search from '@/views/Search'
import SearchResults from '@/views/SearchResults'
import Parking from '@/views/Parking'
import Messages from '@/views/Messages'
import Sales from '@/views/Sales'
import Invoices from '@/views/Invoices'
import Settings from '@/views/Settings'
import PasswordReset from '@/views/PasswordReset'
import CarDetails from '@/views/CarDetails'
import Transport from '@/views/Transport'
import Termine from '@/views/Termine'
import Chats from '@/views/Chats'
import Prices from '@/views/Prices'
import Contact from '@/views/Contact'
import Stock from '@/views/Stock'

const routes = [
  {
    path: "/:catchAll(.*)",
    redirect: '/'
  },
  {
    path: '/', 
    redirect: '/login',
    meta: { requiresGuest: true },
  },
  {
    path: '/', 
    redirect: '/home',
    meta: { requiresAuth: true },
  },
  {
    path: '/forgot-password',
    name: 'RePasswordResetst',
    component: PasswordReset,
    meta: { requiresGuest: true },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { requiresGuest: true },
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: '/cars',
    name: 'Cars',
    component: Cars,
    meta: { requiresAuth: true }
  },
  {
    path: '/car/:id',
    name: 'Car',
    component: CarDetails,
    meta: { requiresAuth: true }
  },
  {
    path: '/search',
    name: 'Search',
    component: Search,
    meta: { requiresAuth: true }    
  },
  {
    path: '/searchresults',
    name: 'SearchResults',
    component: SearchResults,
    meta: { requiresAuth: true }    
  },
  {
    path: '/parking',
    name: 'Parking',
    component: Parking,
    meta: { requiresAuth: true }
  },
  {
    path: '/messages',
    name: 'Messages',
    component: Messages,
    meta: { requiresAuth: true }
  },
  {
    path: '/sales',
    name: 'Sales',
    component: Sales,
    meta: { requiresAuth: true }
  },
  {
    path: '/invoices',
    name: 'Invoices',
    component: Invoices,
    meta: { requiresAuth: true }
  },
  {
    path: '/transport',
    name: 'Transport',
    component: Transport,
    meta: { requiresAuth: true }
  },
  {
    path: '/termine',
    name: 'Termine',
    component: Termine,
    meta: { requiresAuth: true }
  },
  {
    path: '/chats',
    name: 'Chats',
    component: Chats,
    meta: { requiresAuth: true }
  },
  {
    path: '/stock',
    name: 'Stock',
    component: Stock,
    meta: { requiresAuth: true }
  },
  {
    path: '/prices',
    name: 'Prices',
    component: Prices,
    meta: { requiresAuth: true }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: { requiresAuth: true }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: { requiresAuth: true }
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});


router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  const requiresGuest = to.matched.some((x) => x.meta.requiresGuest);
  const isLoggedin = store.getters.isAuthenticated;  
  if (requiresAuth && !isLoggedin) {
      next("/login");
  } else if (requiresGuest && isLoggedin) {  
      next("/home");
  }  else {
    next();
  }
});


export default router;
