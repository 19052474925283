<template>
    <div v-if="show">
        <transition name="modal" id="modal-template">            
            <div>
                <div @click="closeModal()" class="close">
                    <img src="@/assets/icons/times.svg" alt="">
                </div>                        
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-container-lg">                                        
                            <div v-if="img !=''" :style="'width:100%;height: 200px; overflow:hidden;background-image:url('+img+'), url('+imgdefault+');background-size:cover;background-position:center center;border-top-left-radius: 15px;border-top-right-radius: 15px;'"></div>
                            <div class="modal-body-lg text-left">
                                
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left mt-3 mb-3">
                                    <h5>{{ data.title }}</h5> 
                                    <span v-if="ankaufstation == true" style="float:right; margin-top: -30px;"><a style="text-decoration: none;color:#187c0b" :href="'https://suchen.mobile.de/fahrzeuge/search.html?cn=DE&dam=0&fr='+data.ezjahr+'%3A'+(parseInt(data.ezjahr)+3)+'&ml=%3A'+(parseInt(data.km)+25000)+'&ft='+data.fuel+'&tr='+data.getriebe+'&isSearchRequest=true&pw='+data.kw+'%3AKW&ms='+data.makeid+';'+data.modelid+'&s=Car&sfmr=false&vc=Car&sortOption.sortBy=searchNetGrossPrice&sortOption.sortOrder=ASCENDING'" target="_blank">Fahrzeug vergleichen</a></span>
                                    <hr>
                                </div>

                                <div class="row mt2 mb-3">
                                    <h6><b>Fahrzeug Informationen</b></h6>
                                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 text-left">
                                        <div v-if="termin == true">
                                            Kilometer: {{ this.$filters.toCurrency(data.km) }} km<br>                   
                                            Kraftstoff: {{ data.full }}<br>
                                            Getriebe: {{ data.getriebe }} <br>
                                            Kategorie: {{ data.category }} 
                                        </div>
                                        <div v-else>   
                                            <div style="font-size: 16px;">
                                                EZ: {{ data.ez }}<br>
                                                HU: {{ data.hu }}<br>
                                                Kilometer: {{ this.$filters.toCurrency(data.km) }} km<br>                   
                                                Kraftstoff: {{ data.full }}<br>
                                                Getriebe: {{ data.getriebe }} <br>
                                                Kategorie: {{ data.category }} 
                                            </div>                                                                        
                                        </div>                                                                        
                                    </div>
                                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 text-left ">                                                                       
                                        <div v-if="termin == true">
                                            EZ: {{ data.ez }}<br>
                                            HU: {{ data.hu }}<br>
                                            <h4 class="mr-1" style="margin-top: 10px;">{{ this.$filters.toCurrency(price) }} € <small style="font-size: 14px;">{{data.mwst == '0' ? 'Ohne MwSt.' : 'Inkl. MwSt.' }}</small></h4>
                                        </div>
                                        <div v-else>                                        
                                            <div style="font-size: 16px;">                
                                                Entferung: {{this.distance}} km <br>
                                                Logistikkosten: {{this.delivery}} € (Netto)<br>
                                                SGP-Service: {{this.sgpservice}} € (Netto)
                                            </div> 
                                            <div v-if="data.lastprice != '0'" class="mb-2">
                                                <h4 class="mr-1" style="margin-top: 10px;">{{ this.$filters.toCurrency(data.lastprice) }} € <small style="font-size: 14px;">{{data.mwst == '0' ? 'Ohne MwSt.' : 'Inkl. MwSt.' }}</small> </h4>
                                                <span class="price-badge" style="margin-right:20px;">- {{ this.$filters.toCurrency(getDiff(data.price, data.lastprice)) }} €</span>
                                                <span style="font-size: 14px;"><s>{{ this.$filters.toCurrency(data.price) }} €</s></span>
                                            </div>
                                            <div v-else>
                                                <h4 class="mr-1" style="margin-top: 10px;">{{ this.$filters.toCurrency(data.price) }} € <small style="font-size: 14px;">{{data.mwst == '0' ? 'Ohne MwSt.' : 'Inkl. MwSt.' }}</small></h4>
                                                <p class="text-success mb-4" style="font-size:14px;">Optional mit Lieferung</p>                    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                
                                
                                <div v-if="data.rating != 'NO_RATING'" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-2" style="font-size:15px;width: 100%;">
									<h6><b>Preisbewertung</b></h6>
									<div style="float: left;width: 20%; text-align:center;">
										<div style="width: 100%;height:6px;background: #187c0b;margin-bottom: 10px;"></div>
                                        <span style="color:#187c0b;font-weight: 600;">Sehr guter Preis</span><br>
										{{ ratings[0].replace('u00a0u20ac', '') }} - 
										{{ ratings[1].replace('u00a0u20ac', ' €') }}
									</div>
									<div style="float: left;width: 20%; text-align:center;">
										<div style="width: 100%;height:6px;background: #34b523;margin-bottom: 10px;"></div>
                                        <span style="color:#34b523;font-weight: 600;">Guter Preis</span><br>
										{{ ratings[1].replace('u00a0u20ac', '') }} - 
										{{ ratings[2].replace('u00a0u20ac', ' €') }}
									</div>
									<div style="float: left;width: 20%; text-align:center;">
										<div style="width: 100%;height:6px;background: #36d121;margin-bottom: 10px;"></div>	
                                        <span style="color:#36d121;font-weight: 600;">Fairer Preis</span><br>
										{{ ratings[2].replace('u00a0u20ac', '') }} - 
										{{ ratings[3].replace('u00a0u20ac', ' €') }}
									</div>
									<div style="float: left;width: 20%; text-align:center;">
										<div style="width: 100%;height:6px;background: #f3ad2c;margin-bottom: 10px;"></div>
                                        <span style="color:#f3ad2c;font-weight: 600;">Erhöhter Preis</span><br>
										{{ ratings[3].replace('u00a0u20ac', '') }} - 
										{{ ratings[4].replace('u00a0u20ac', ' €') }}
									</div>
									<div style="float: left;width: 20%; text-align:center;">
										<div style="width: 100%;height:6px;background: #e54d4d;margin-bottom: 10px;"></div>                                        
                                        <span style="color:#e54d4d;font-weight: 600;">Hoher Preis</span><br>
										{{ ratings[4].replace('u00a0u20ac', '') }} - 
										{{ ratings[5].replace('u00a0u20ac', ' €') }}
									</div>
									<br style="clear: both;">	
                                    <br>									
								</div>			


                                <div v-if="data.features !=''" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left mb-3">
                                    <h6><b>Ausstattung</b></h6>
                                    <hr>
                                    <span v-for="feature in data.features.split(',')" :key="feature"  class="feature-tag">
                                        {{ feature }}
                                    </span>                                    
                                </div>
                                
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
    <div v-if="type == 'chat'" style="margin-top:5px;">
        <a @click="openModal()" class="chatcarbtndetails mt-2">
            <b>Fahrzeug Details</b>
        </a>
    </div>
    <div v-else style="margin-top:10px;">       
        <a @click="openModal()" class="detailsBtn mt-2">
            Fahrzeug Details
        </a>
    </div>

</template>

<script>
import models from "../models";
import imgdefault from '@/assets/nopic-details.png'

export default {
    name: 'CarDetailView',    
    props: {
        data: {
            type: Object,
            required: true
        },
        type: {
            type: String,
            required: false
        },
        termin: {
            type: Boolean,
            required: false
        },
        price: {
            type: String,
            required: false
        },
        ankaufstation: {
            type: Boolean,
            required: false
        },
    },
    data() {
        return {
            msgsend: this.data.angefragt,
            show: false,
            img: "",
            imgdefault: imgdefault,
            ratings: null,
            distance: 0,
            delivery: 0,
            sgpservice: 0,
        }
    },
    mounted() {     
        models.getDistance(this.data.lat, this.data.lon, this.$store.getters.StateUser['lat'], this.$store.getters.StateUser['lon']).then((response) => {
            this.distance = response;
            this.delivery = models.getDeliveryCosts(response)['net']
        })
        this.sgpservice = models.getSGPServiceCosts(this.data.price)['net']   
        if(this.data.image !=""){
            this.img = this.data.image.replace('_8.JPG', '_20.JPG')
        }else{
            this.img = ""
        }
        if(this.data.ratingprices != ""){
            this.ratings = this.data.ratingprices.split(',')
        }
    },
    methods: {
        getDiff: function (value1, value2) {
            var val = (Number(value1) - Number(value2));
            return val;
        },
        openModal(){
            this.show = true;
            document.body.classList.add('noscroll');
        },
        closeModal(){
            this.show = false;
            document.body.classList.remove('noscroll')
        },
        sendMessage(){

        }
    }
}
</script>

<style>

.close{
    position: fixed;
    display: block;
    width: 150px;
    height: 150px;
    top: 10px;
    right: 20px;
    cursor: pointer;
    z-index: 99999999;
}

.feature-tag{
    background-color: #f2f2f2;
    padding: 5px 10px;
    border-radius: 10px;       
    color: #000;
    border:1px solid #dedede;
    display:block;
    float:left; 
    margin-right: 10px; 
    margin-bottom: 10px; 
    font-size: 13px;
}

.detailsBtn{
    display: block;
    width: 100%;
    cursor: pointer; 
    margin-top: 10px; 
    color:#454545; 
    text-decoration: none; 
    font-size: 16px;
    font-weight: 600;
    padding: 6px 20px 6px 20px;
    border: 1px solid #dedede;
    border-radius: 15px;
}
.detailsBtn:hover {
    color: #000;
    background-color: #dedede;
}

.chatcarbtndetails{
    text-decoration: none;
    color: #000;
    cursor: pointer;
}
.chatcarbtndetails:hover{
    color: green;
}
</style>
<style scoped src="../assets/css/car.css"></style>