<template>
    <div v-if="show">
        <transition name="modal" id="modal-template">            
            <div>
                <div @click="closeModal()" class="close">
                    <img src="@/assets/icons/times.svg" alt="">
                </div>                        
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        
                        <div class="msg-wrapper" align="center">
                        <section class="msger">
                            <header class="msger-header">
                                <div class="msger-header-title">
                                <i class="fas fa-comment-alt"></i> {{ data.title }}
                                </div>
                                <div class="msger-header-options">
                                <span></span>
                                </div>
                            </header>

                            <div class="msger-chat" id="msgerchat">
                                <div v-for="message in messages" :key="message.idmsg">
                                    <div v-if="message.sender == 'carpirate'" class="msg left-msg">                            
                                        <div class="msg-bubble">
                                            <div class="msg-text">
                                                {{ message.message }}
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else class="msg right-msg">
                                        <div class="msg-bubble">
                                            <div class="msg-text">
                                                {{ message.message }}
                                            </div>                                        
                                        </div>                                   
                                    </div>                                
                                </div>   
                                <div ref="bottomEl"></div>                             
                            </div>

                            <form v-on:submit.prevent="noop" class="msger-inputarea">
                                <input v-model="textmsg" @keyup.enter="onEnter" type="text" class="msger-input" placeholder="Frage zum Fahrzeug stellen...">
                                <a @click="sendMessage" class="msger-send-btn">
                                    <img src="../assets/icons/paper-plane.svg" style="color:#fff" height="35">                                    
                                </a>
                            </form>
                        </section>
                    </div>
                        
                    </div>
                </div>
            </div>
        </transition>
    </div>  
    <div v-if="this.bycar != true" style="margin-top:5px;">
        <a @click="openModal()" class="chatcarbtndetails mt-2">
            <img src="../assets/icons/angle-right.svg" height="30" style="cursor: pointer;">
        </a>
    </div>  
</template>

<script>
import models from "../models";
// import imgdefault from '@/assets/nopic-details.png'

export default {
    name: 'ChatMessageView',    
    props: {
        data: {
            type: Object,
            required: true
        },
        ctoken: {
            type: String,
            required: true
        },
        open: {
            type: Boolean,
            required: false        
        },
        bycar: {
            type: Boolean,
            required: false
        }
    },
    data() {
        return {
            show: false,    
            messages:[],
            textmsg: "",
            textmsg_save: "",
            chat_changed: 0
        }
    },
    mounted() {             
        this.loadMessages();    
        if(this.open == true){
            this.openModal();
        }
    },
    methods: {      
        onEnter: function() {
          this.sendMessage();
        },         
        openModal(){
            this.show = true;
            document.body.classList.add('noscroll');
        },        
        closeModal(){
            this.show = false;
            document.body.classList.remove('noscroll')   
            if(this.chat_changed == 1 && this.bycar != true){          
                this.$emit('close', '') 
            }
            if(this.bycar == true){
                this.$emit('close', '') 
            }
        },
        async loadMessages(){            
            await models.getUserChatMessages(this.$store.getters.StateToken, this.ctoken).then(res => {
                this.messages = res.data['msg']
                this.performingRequest = false;
            }).catch(err => {      
                this.performingRequest = false;
                console.log(err);
            });
        },
        async sendMessage(){  
            if(this.textmsg!=""){  
                this.textmsg_save = this.textmsg;
                this.textmsg = "";
                this.messages.unshift({admin_read: "0", ctoken: this.ctoken, idmsg: "", message: this.textmsg_save, message_type: "text", sender: this.$store.getters.StateToken, sendtime: "1688545431", user_read: "1"});
                await models.addChatMessage(this.$store.getters.StateToken, this.ctoken, this.textmsg_save, "text").then(() => {                    
                    this.chat_changed = 1;
                    this.textmsg_save = "";
                    this.performingRequest = false;
                }).catch(err => {      
                    this.performingRequest = false;
                    console.log(err);
                });
            }
        },
    },   
}
</script>

<style scoped scss>

.close{
    position: fixed;
    display: block;
    width: 150px;
    height: 150px;
    top: 10px;
    right: 20px;
    cursor: pointer;
    z-index: 99999999;
}

:root {
  --body-bg: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  --msger-bg: #fff;
  --border: 2px solid #ddd;
  --left-msg-bg: #ececec;
  --right-msg-bg: #579ffb;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

textarea:focus, input:focus{
    outline: none;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: var(--body-bg);
  font-family: Helvetica, sans-serif;
}

.msg-wrapper{
    height: calc(100% - 50px);
}

.msger {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 867px;
  margin: 25px 10px;
  height: 85vh;
  border: var(--border);
  border-radius: 5px;
  background: var(--msger-bg);
  box-shadow: 0 15px 15px -5px rgba(0, 0, 0, 0.2);
}

.msger-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: var(--border);
  background: #eee;
  color: #666;
}

.msger-chat {
  display: flex;
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 10px;
  flex-direction: column-reverse;
}
.msger-chat::-webkit-scrollbar {
  width: 6px;
}
.msger-chat::-webkit-scrollbar-track {
  background: #ddd;
}
.msger-chat::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}
.msg {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}
.msg:last-of-type {
  margin: 0;
}
.msg-img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  background: #ddd;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
}
.msg-bubble {
  max-width: 450px;
  padding: 15px;
  border-radius: 15px;
  background: #ddd;
  text-align: left;
  margin-bottom: 10px;
}
.msg-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.msg-info-name {
  margin-right: 10px;
  font-weight: bold;
}
.msg-info-time {
  font-size: 0.85em;
}

.left-msg .msg-bubble {
  border-bottom-left-radius: 0;
}

.right-msg {
  flex-direction: row-reverse;
}
.right-msg .msg-bubble {
  background: green;
  color: #fff;
  border-bottom-right-radius: 0;
}
.right-msg .msg-img {
  margin: 0 0 0 10px;
}

.msger-inputarea {
  display: flex;
  padding: 10px;
  border-top: var(--border);
  background: #eee;
}
.msger-inputarea * {
  padding: 10px;
  border: none;
  border-radius: 3px;
  font-size: 1em;
}
.msger-input {
  flex: 1;
  background: #ddd;
}
.msger-send-btn {
  margin-left: 10px;
  background: rgb(0, 196, 65);
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.23s;
}
.msger-send-btn:hover {
  background: rgb(0, 180, 50);
}

.msger-chat {
  background-color: #fcfcfe;
  background-image: url("../assets/chatbg.png");
}

</style>