<template>
    <div v-if="show">
        <transition name="modal" id="modal-template">            
            <div>
                <div @click="closeModal()" class="close">
                    <img src="@/assets/icons/times.svg" alt="">
                </div>                        
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-container-lg">                                                                    
                            <div class="modal-body-lg text-left">
                                
                                <div class="row mt-3">
                                    <div class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1 mx-auto">
                                        
                                        <div class="row">
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mx-auto">
                                                <h3>Ankauf-Station Öffnungszeiten
                                                    <span style="float: right;">
                                                        <span v-if="updateloading == true">
                                                            <img src="@/assets/bx_loader.gif" style="margin-right:40px;" width="25" height="25" alt="">
                                                        </span>
                                                        <span v-else>
                                                            <a @click="updateOpenTimes()" class="btn btn-md btn-success">Speichern</a>
                                                        </span>
                                                    </span>
                                                </h3>
                                                <hr>
                                            </div>
                                        </div>                                         

                                        <div class="row mt-5">
                                            <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 mx-auto">
                                               <input @click="clickCheckBox('mo')" type="checkbox" v-model="mo" name="mo">
                                            </div>
                                            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mx-auto">
                                               Montag
                                            </div>

                                            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mx-auto">
                                                <span style="float:left;margin-right: 6px;margin-left: 20px;">von: </span> <span><input type="time" class="form-control" style="width:100px;" :disabled="modisabled" v-model="movon" ></span>
                                            </div>
                                            
                                            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mx-auto">
                                               <span style="float:left;margin-right: 6px;">bis: </span> <span><input type="time" class="form-control" style="width:100px;" :disabled="modisabled" v-model="mobis"></span>
                                            </div>
                                            
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mx-auto">                                                
                                                <hr>
                                            </div>
                                        </div>  

                                        <div class="row mt-2">
                                            <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 mx-auto">
                                               <input @click="clickCheckBox('di')" type="checkbox" v-model="di" name="di">
                                            </div>
                                            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mx-auto">
                                                Dienstag
                                            </div>

                                            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mx-auto">
                                                <span style="float:left;margin-right: 6px;margin-left: 20px;">von: </span> <span><input class="form-control" style="width:100px;" type="time" :disabled="didisabled" v-model="divon" name="divon"></span>
                                            </div>
                                            
                                            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mx-auto">
                                               <span style="float:left;margin-right: 6px;">bis: </span> <span><input type="time" class="form-control" style="width:100px;" :disabled="didisabled" v-model="dibis" name="dibis"></span>
                                            </div>
                                            
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mx-auto">                                                
                                                <hr>
                                            </div>
                                        </div>  

                                        <div class="row mt-2">
                                            <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 mx-auto">
                                               <input @click="clickCheckBox('mi')" type="checkbox" v-model="mi" name="mi">
                                            </div>
                                            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mx-auto">
                                                Mittwoch
                                            </div>

                                            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mx-auto">
                                                <span style="float:left;margin-right: 6px;margin-left: 20px;">von: </span> <span><input class="form-control" style="width:100px;" type="time" :disabled="midisabled" v-model="mivon" name="mivon"></span>
                                            </div>
                                            
                                            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mx-auto">
                                               <span style="float:left;margin-right: 6px;">bis: </span> <span><input type="time" class="form-control" style="width:100px;" :disabled="midisabled" v-model="mibis" name="mibis"></span>
                                            </div>
                                            
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mx-auto">                                                
                                                <hr>
                                            </div>
                                        </div>  

                                        <div class="row mt-2">
                                            <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 mx-auto">
                                               <input @click="clickCheckBox('do')" type="checkbox" v-model="don" name="don">
                                            </div>
                                            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mx-auto">
                                                Donnerstag
                                            </div>

                                            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mx-auto">
                                                <span style="float:left;margin-right: 6px;margin-left: 20px;">von: </span> <span><input class="form-control" style="width:100px;" type="time" :disabled="dodisabled" v-model="dovon" name="dovon"></span>
                                            </div>
                                            
                                            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mx-auto">
                                               <span style="float:left;margin-right: 6px;">bis: </span> <span><input type="time" class="form-control" style="width:100px;" :disabled="dodisabled" v-model="dobis" name="dobis"></span>
                                            </div>
                                            
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mx-auto">                                                
                                                <hr>
                                            </div>
                                        </div>  

                                        <div class="row mt-2">
                                            <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 mx-auto">
                                               <input @click="clickCheckBox('fr')" type="checkbox" v-model="fr" name="fr">
                                            </div>
                                            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mx-auto">
                                               Freitag
                                            </div>

                                            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mx-auto">
                                                <span style="float:left;margin-right: 6px;margin-left: 20px;">von: </span> <span><input class="form-control" style="width:100px;" type="time" :disabled="frdisabled" v-model="frvon" name="frvon"></span>
                                            </div>
                                            
                                            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mx-auto">
                                               <span style="float:left;margin-right: 6px;">bis: </span> <span><input type="time" class="form-control" style="width:100px;" :disabled="frdisabled" v-model="frbis" name="frbis"></span>
                                            </div>
                                            
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mx-auto">                                                
                                                <hr>
                                            </div>
                                        </div>  

                                        <div class="row mt-2">
                                            <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 mx-auto">
                                               <input @click="clickCheckBox('sa')" type="checkbox" v-model="sa" name="sa">
                                            </div>
                                            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mx-auto">
                                               Samstag
                                            </div>

                                            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mx-auto">
                                                <span style="float:left;margin-right: 6px;margin-left: 20px;">von: </span> <span><input class="form-control" style="width:100px;" type="time" :disabled="sadisabled" v-model="savon" name="savon"></span>
                                            </div>
                                            
                                            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mx-auto">
                                               <span style="float:left;margin-right: 6px;">bis: </span> <span><input type="time" class="form-control" style="width:100px;" :disabled="sadisabled" v-model="sabis" name="sabis"></span>
                                            </div>
                                            
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mx-auto">                                                
                                                <hr>
                                            </div>
                                        </div>  

                                        <div class="row mt-2">
                                            <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 mx-auto">
                                               <input @click="clickCheckBox('so')" type="checkbox" v-model="so" name="so">
                                            </div>
                                            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mx-auto">
                                                Sonntag
                                            </div>

                                            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mx-auto">
                                                <span style="float:left;margin-right: 6px;margin-left: 20px;">von: </span> <span><input class="form-control" style="width:100px;" type="time" :disabled="sodisabled" v-model="sovon" name="sovon"></span>
                                            </div>
                                            
                                            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mx-auto">
                                               <span style="float:left;margin-right: 6px;">bis: </span> <span><input type="time" class="form-control" style="width:100px;" :disabled="sodisabled" v-model="sobis"  name="sobis"></span>
                                            </div>
                                            
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mx-auto">                                                
                                                <hr>
                                            </div>
                                        </div>  

                                      
                                    </div>
                                </div>
                                

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
    <div style="margin-top:10px;">
        <a @click="openModal()" class="detailsBtn mt-2">
            Öffnungszeiten
        </a>
    </div>
</template>

<script>

import models from "../models";
// import { useToast } from 'vue-toast-notification'

export default {
    name: 'BuyStationOpenTimesView', 
    components: {
    },
    props: {
    },
    data() {
        return {
            loading: true,
            updateloading: false,
            show: false,           
            opentimes: [],
            mo: false,
            movon: '08:00',
            mobis: '18:00',
            modisabled: true,
            di: false,
            divon: '08:00',
            dibis: '18:00',
            didisabled: true,
            mi: false,
            mivon: '08:00',
            mibis: '18:00',
            midisabled: true,
            don: false,
            dovon: '08:00',
            dobis: '18:00',
            dodisabled: true,
            fr: false,
            frvon: '08:00',
            frbis: '18:00',
            frdisabled: true,
            sa: false,
            savon: '10:00',
            sabis: '14:00',
            sadisabled: true,
            so: false,
            sovon: '10:00',
            sobis: '14:00',
            sodisabled: true
        }
    },
    mounted() {     
    },
    methods: {        
        openModal(){
            this.show = true;
            document.body.classList.add('noscroll');
            this.loadOpenTimes();
        },
        closeModal(){
            this.show = false;
            document.body.classList.remove('noscroll')
            // this.loadOpenTimes();
        }, 
        clickCheckBox(day){
            if(day == "mo"){ if(this.modisabled == true){ this.modisabled = false; this.mo = false; }else{ this.modisabled = true; this.mo = true; } }
            if(day == "di"){ if(this.didisabled == true){ this.didisabled = false; this.di = false; }else{ this.didisabled = true; this.di = true; } }
            if(day == "mi"){ if(this.midisabled == true){ this.midisabled = false; this.mi = false; }else{ this.midisabled = true; this.mi = true; } }
            if(day == "do"){ if(this.dodisabled == true){ this.dodisabled = false; this.don = false; }else{ this.dodisabled = true; this.don = true; } }
            if(day == "fr"){ if(this.frdisabled == true){ this.frdisabled = false; this.fr = false; }else{ this.frdisabled = true; this.fr = true; } }
            if(day == "sa"){ if(this.sadisabled == true){ this.sadisabled = false; this.sa = false; }else{ this.sadisabled = true; this.sa = true; } }
            if(day == "so"){ if(this.sodisabled == true){ this.sodisabled = false; this.so = false; }else{ this.sodisabled = true; this.so = true; } }
        },
        async loadOpenTimes()
        {
            this.loading = true;
            await models.getAnkaufOpenTimes(this.$store.getters.StateToken).then(async res => 
            {
                if(res.data['message'] == "success"){
                    if(res.data['opentimes'] != null){
                        this.opentimes = res.data['opentimes'];              
                        if(this.opentimes.mo == "1"){ this.mo = true; this.modisabled = false; }else{ this.modisabled = true; }
                        if(this.opentimes.di == "1"){ this.di = true; this.didisabled = false; }else{ this.didisabled = true; }
                        if(this.opentimes.mi == "1"){ this.mi = true; this.midisabled = false; }else{ this.midisabled = true; }
                        if(this.opentimes.do == "1"){ this.don = true; this.dodisabled = false; }else{ this.dodisabled = true; }
                        if(this.opentimes.fr == "1"){ this.fr = true; this.frdisabled = false; }else{ this.frdisabled = true; }
                        if(this.opentimes.sa == "1"){ this.sa = true; this.sadisabled = false; }else{ this.sadisabled = true; }
                        if(this.opentimes.so == "1"){ this.so = true; this.sodisabled = false; }else{ this.sodisabled = true; }
                        this.movon = this.opentimes.mo_von;
                        this.mobis = this.opentimes.mo_bis;
                        this.divon = this.opentimes.di_von;
                        this.dibis = this.opentimes.di_bis;
                        this.mivon = this.opentimes.mi_von;
                        this.mibis = this.opentimes.mi_bis;
                        this.dovon = this.opentimes.do_von;
                        this.dobis = this.opentimes.do_bis;
                        this.frvon = this.opentimes.fr_von;
                        this.frbis = this.opentimes.fr_bis;
                        this.savon = this.opentimes.sa_von;
                        this.sabis = this.opentimes.sa_bis;
                        this.sovon = this.opentimes.so_von;
                        this.sobis = this.opentimes.so_bis;
                    }
                }                
                this.loading = false;
            }).catch(err => {      
                this.loading = false;
                console.log(err);
            });
        },
        async updateOpenTimes()
        { 
            var montag = "1";
            var dienstag = "1";
            var mitwoch = "1";
            var donnerstag = "1";
            var freitag = "1";
            var samstag = "1";
            var sontag = "1";            
            if(this.mo == true){ montag = "1"; }else{ montag = "0"; }
            if(this.di == true){ dienstag = "1"; }else{ dienstag = "0"; }
            if(this.mi == true){ mitwoch = "1"; }else{ mitwoch = "0"; }
            if(this.don == true){ donnerstag = "1"; }else{ donnerstag = "0"; }
            if(this.fr == true){ freitag = "1"; }else{ freitag = "0"; }
            if(this.sa == true){ samstag = "1"; }else{ samstag = "0"; }
            if(this.so == true){ sontag = "1"; }else{ sontag = "0"; }
            var opentimesdata = { 
                mo: montag, 
                movon: this.movon,
                mobis: this.mobis,
                di: dienstag, 
                divon: this.divon,
                dibis: this.dibis,
                mi: mitwoch, 
                mivon: this.mivon,
                mibis: this.mibis,
                do: donnerstag, 
                dovon: this.dovon,
                dobis: this.dobis,
                fr: freitag, 
                frvon: this.frvon,
                frbis: this.frbis,
                sa: samstag, 
                savon: this.savon,
                sabis: this.sabis,
                so: sontag, 
                sovon: this.sovon,
                sobis: this.sobis 
            };
            this.updateloading = true;
            await models.updateAnkaufOpenTimes(this.$store.getters.StateToken, opentimesdata).then(async res => {
                console.log(res.data);
                if(res.data['message'] == "success"){                    
                    console.log(res.data);
                }                
                this.updateloading = false;
            }).catch(err => {      
                this.updateloading = false;
                console.log(err);
            });
           
        }
    }
}
</script>

<style>

.close{
    position: fixed;
    display: block;
    width: 150px;
    height: 150px;
    top: 10px;
    right: 20px;
    cursor: pointer;
    z-index: 99999999;
}

.feature-tag{
    background-color: #f2f2f2;
    padding: 5px 10px;
    border-radius: 10px;       
    color: #000;
    border:1px solid #dedede;
    display:block;
    float:left; 
    margin-right: 10px; 
    margin-bottom: 10px; 
    font-size: 13px;
}

.detailsBtn{
    display: block;
    width: 100%;
    cursor: pointer; 
    margin-top: 10px; 
    color:#454545; 
    text-decoration: none; 
    font-size: 16px;
    font-weight: 600;
    padding: 6px 20px 6px 20px;
    border: 1px solid #dedede;
    border-radius: 15px;
}
.detailsBtn:hover {
    color: #000;
    background-color: #dedede;
}
</style>
<style scoped src="../assets/css/car.css"></style>