<template>
  <div>
  <!-- <div class="container-fluid"> -->
      <router-link to="/">
        <img src="@/assets/logo.png" alt="" class="img-responsive logo-sidebar">        
      </router-link>      
      <hr>
      <div id="nav">
        <div class="item">
          <router-link to="/home"><span class="iconfloat"><img src="@/assets/icons/car-alt.svg" style="height:18px; width:18px;"></span> <span>Neusten Fahrzeuge</span></router-link>
        </div>
        <div class="item">
          <router-link to="/cars"><span class="iconfloat"><img src="@/assets/icons/tag.svg" style="height:18px; width:18px;"></span> <span>Sofort Verfügbar</span></router-link>
        </div>
        <div class="item">
          <router-link to="/search"><span class="iconfloat"><img src="@/assets/icons/search-alt.svg" style="height:18px; width:18px;"></span> <span>Fahrzeug Suche</span></router-link>
        </div>
        <div class="item">
          <router-link to="/parking"><span class="iconfloat"><img src="@/assets/icons/producthunt.svg" style="height:18px; width:18px;"></span> <span>Parkplatz</span></router-link>
        </div>
        <div class="item">
          <router-link to="/messages"><span class="iconfloat"><img src="@/assets/icons/envelope.svg" style="height:18px; width:18px;"></span> <span>Anfragen</span></router-link>
        </div>
        <div class="item">
          <router-link to="/sales"><span class="iconfloat"><img src="@/assets/icons/shopping-basket.svg" style="height:18px; width:18px;"></span> <span>Buchungen</span></router-link>
        </div>
        <div class="item">
          <router-link to="/termine"><span class="iconfloat"><img src="@/assets/icons/calendar.svg" style="height:18px; width:18px;"></span> <span>Ankauf Termine</span></router-link>
        </div> 
        <div class="item">
          <router-link to="/transport"><span class="iconfloat"><img src="@/assets/icons/delivery.svg" style="height:18px; width:18px;"></span> <span>Logistikaufträge</span></router-link>
        </div>                       
        <div class="item">
          <router-link to="/invoices"><span class="iconfloat"><img src="@/assets/icons/invoice.svg" style="height:18px; width:18px;"></span> <span>Rechnungen</span></router-link>
        </div>       
        <div class="item">
          <router-link to="/chats"><span class="iconfloat"><img src="@/assets/icons/comments-alt-2.svg" style="height:18px; width:18px;"></span> <span>Nachrichten</span></router-link>
        </div>       
        <div class="item">
          <router-link to="/stock"><span class="iconfloat"><img src="@/assets/icons/pie-chart.svg" style="height:18px; width:18px;"></span> <span>Bestandsanalyse</span></router-link>
        </div>       
        <div class="item">
          <router-link to="/prices"><span class="iconfloat"><img src="@/assets/icons/calculator-alt.svg" style="height:18px; width:18px;"></span> <span>SGP Preise</span></router-link>
        </div>       
        <div class="item">
          <router-link to="/contact"><span class="iconfloat"><img src="@/assets/icons/consulting.svg" style="height:18px; width:18px;"></span> <span>Kontakt</span></router-link>
        </div>       
        <!-- <br>
        <hr>
        <br> -->
        <div class="logoutitem">
          <a @click="logout" class="logout"><span class="iconfloat2"><img :src="iconUrl" style="height:18px; width:18px;"></span> <span>Ausloggen</span></a>
        </div>
    </div>
    </div>
</template>star-empty

<script>
// import someImage from '@/assets/icons/power-switch.svg'

export default {
  name: 'SideBar',
  props: {
    msg: String
  },
  computed: {
    isLoggedIn: function() {
      return this.$store.getters.isAuthenticated;
    },  
    useremail: function() {
      return this.$store.getters.StateUserEmail;
    },
    activeRoute: function() {
      return this.$route.path.split('/')[1];
    },
    iconUrl () {
      return require('@/assets/icons/power-switch.svg')
    }
  },
  methods: {
    async logout() {
      // console.log(this.$store.getters.StateUserEmail);
      await this.$store.dispatch("LogOut");
      this.$router.push("/login");
    },
  },
}
</script>


<style scoped>
.img-responsive{
  max-width: 220px;
}

.logo-sidebar{
  margin-bottom:40px;
}

hr{
  margin: 0px;
  padding: 0px;  
  margin-right: 25px;
}

#nav {
  padding-top:40px;
  padding-bottom:30px;    
}

#nav a {
  /* font-weight: bold; */
  color: rgb(121, 121, 121);
  text-decoration: none !important;
  font-size: 18px;  
}

#nav a:hover {
  /* font-weight: bold; */
  color: #000;
  text-decoration: none !important;
  font-size: 18px;  
}

#nav a.router-link-exact-active {
  color: rgb(39, 189, 31);
  text-decoration: none;
}
#nav a.router-link-active {
  color: rgb(39, 189, 31);
  text-decoration: none;
}

.iconfloat{
  float: left;
  margin-top: 4px;
  margin-right: 8px;
  opacity: 0.4;
}
.iconfloat2{
  float: left;
  margin-top: 4px;
  margin-right: 8px;
}

.logoutitem{
  padding-left: 10px;
  padding-right: 20px;
  margin-bottom: 10px;
  margin-right: 20px;
  border-radius: 10px;
}

.item{
  padding-left: 10px;
  padding-right: 20px;
  margin-bottom: 10px;
  margin-right: 20px;
  border-radius: 10px;
}
.item:hover{
  background-color: #efefef;
}

.logout {
  cursor: pointer;
  color: #e53333 !important;
}

</style>
