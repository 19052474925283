<template>
    <div id="temp">
        <Loading v-bind:loading="performingRequest"/>
        <div class="sidebar"><Sidebar/></div>
        <div class="body-text">

            <div class="container-fluid pagepadding">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1 mx-auto" style="padding-left: 10px;">
                    <h1><span class="iconfloat-title"><img src="../assets/icons/calendar.svg" style="height:24px; width:24px;"></span>  {{ title }}</h1>
                    <span  v-if="ankaufstation == 1" style="float: right; margin-top: -73px;margin-right: 342px;">
                        <!-- Temporär schließen -->
                        <a @click="openPauseDialog" class="detailsBtn mt-2"> Ankaufstation pausieren </a>
                        <BuyStationPauseDialog 
                            :showdialog=showpausedialog
                            title="Ankaufstation pausieren"
                            text="Wenn Sie keine Termine temporär mehr erhalten möchten. Können Sie Ihre Ankaufstation auch vorrübergehend pausieren."
                            btnname="Jetzt pausieren"
                            btnclass="success"
                            @closedialog="(i) => closePauseDialog()"
                            @answer="(i) => activedAnkaufStation(i, 5)"
                        />
                    </span>
                    <span  v-if="ankaufstation == 1" style="float: right; margin-top: -75px;margin-right: 170px;">
                        <BuyStationOpenTimes />
                    </span>
                    <span  v-if="ankaufstation == 1" style="float: right; margin-top: -75px;">
                        <BuyStationFilter />
                    </span>
                    <hr>          
                    </div>        
                </div> 
            </div> 

            <div v-if="ankaufstation == 0 && performingRequest != true" class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1 mx-auto">                
                <BuyStation @reload="(i) => getAppointments()"/>
            </div>                        

            <div v-else-if="ankaufstation == 1 && performingRequest != true">
                
                <div  class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1 mx-auto">
                    <div style="margin-bottom: 40px;margin-top: 20px;">
                        <span @click="changeTab(0)" :class="tabactive == 0 ? 'tabButton_active' : 'tabButton'">
                            <span class="content">Neue Termine</span>
                            <span class="badge_tabs_btn">{{ appointments_new.length }}</span>
                        </span>
                        <span @click="changeTab(1)" :class="tabactive == 1 ? 'tabButton_active' : 'tabButton'" style="margin-left: 25px;">                            
                            <span class="content">Angenommen Termine</span>
                            <span class="badge_tabs_btn">{{ appointments_accepted.length }}</span>
                        </span>
                        <span @click="changeTab(2)" :class="tabactive == 2 ? 'tabButton_active' : 'tabButton'" style="margin-left: 25px;">
                            <span class="content">Abgeschloßene Termine</span>
                            <span class="badge_tabs_btn">{{ appointments_done.length }}</span>                            
                        </span>
                    </div>
                </div>
                
                <div v-if="tabactive == 0">    
                    <div v-if="this.appointments_new.length != 0">
                        <div v-for="termin in this.appointments_new" :key="termin.idres" class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1 mx-auto">
                            <BuyStationItem v-bind:data="termin" v-bind:car="JSON.parse(termin['cardata'])" v-bind:terminstatus="'0'" @reload="(i) => getAppointments()"/>                                             
                        </div>
                    </div>  
                    <div v-else>
                        <div class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1 mx-auto text-center">
                            <br><br>
                            <h3>Noch keine Termine!</h3>
                            <p>Wir haben schon bald einen Termin für Sie in Ihrer Nähe!</p>
                            <br><br>
                            <img src="../assets/icons/calendar-green.svg" style="width:200px;">
                        </div>                        
                    </div>              
                </div>

                <div v-if="tabactive == 1">
                    <div v-if="this.appointments_accepted.length != 0">
                        <div v-for="termin in this.appointments_accepted" :key="termin.idres" class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1 mx-auto">
                            <BuyStationItem v-bind:data="termin" v-bind:car="JSON.parse(termin['cardata'])" v-bind:terminstatus="'1'" @reload="(i) => getAppointments()"/>                                             
                        </div>
                    </div>  
                    <div v-else>
                        <div class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1 mx-auto text-center">
                            <br><br>
                            <h3>Noch keine Termine!</h3>
                            <p>Wir haben schon bald einen Termin für Sie in Ihrer Nähe!</p>
                            <br><br>
                            <img src="../assets/icons/calendar-green.svg" style="width:200px;">
                        </div>                        
                    </div>              
                </div>

                <div v-if="tabactive == 2">
                    <div v-if="this.appointments_done.length != 0">
                        <div v-for="termin in this.appointments_done" :key="termin.idres" class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1 mx-auto">
                            <BuyStationItem v-bind:data="termin" v-bind:car="JSON.parse(termin['cardata'])" v-bind:terminstatus="'2'" @reload="(i) => getAppointments()"/>                                             
                        </div>
                    </div>  
                    <div v-else>
                        <div class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1 mx-auto text-center">
                            <br><br>
                            <h3>Noch keine Termine!</h3>
                            <p>Wir haben schon bald einen Termin für Sie in Ihrer Nähe!</p>
                            <br><br>
                            <img src="../assets/icons/calendar-green.svg" style="width:200px;">
                        </div>                        
                    </div> 
                </div>

            </div>
            
            <div v-else-if="ankaufstation == 2 && performingRequest != true" class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1 mx-auto text-center">
                
                <br><br>
                <h3>Fast geschafft!</h3><br>
                <p>Vielen Dank, wir haben<br>Ihre Anmeldung bekommen.</p>
                <p>Wir prüfen nun Ihre Daten und melden<br>uns innerhalb von 24 Stunden bei Ihnen.</p>                
                <img src="../assets/payment-animation.gif" width="300" alt="">
            </div>

            <div v-else-if="ankaufstation == 3 && performingRequest != true" class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1 mx-auto text-center">
                <br>
                <h3>Sie wurden als Ankauf-Station abgelehnt 🥲</h3>
                <br>
                <img src="../assets/icons/building-red.svg" width="200" alt="">
                <br>
                <br>
                <p>Wir bedauern Ihnen mitteilen zu müssen, dass wir Ihre Daten geprüft haben und Sie leider nicht als Ankaufstation freischalten können. Wir danken Ihnen dennoch für Ihr Interesse und stehen Ihnen für weitere Informationen gerne zur Verfügung. Bei Fragen oder Anliegen können Sie sich jederzeit an uns wenden. Vielen Dank für Ihr Verständnis.</p>
                
                <p><b>Sie haben Fragen oder benötigen Beratung zum SehrGuterPreis Direkt-Verkauf?</b></p>
                <p style="font-size: 16px;">Ihr SehrGuterPreis-Kundenservice ist telefonisch von<br>Montag bis Freitag 9 bis 18 Uhr erreichbar. <br>
                info@sehrguterpreis.de<br>
                +49 (0)2171 77 69 559<br>
                </p>

            </div>
            
            <div v-else-if="ankaufstation == 4 && performingRequest != true" class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1 mx-auto">
                
                <h3>Ihr Account wurde gesperrt!</h3>
                <br>
                <img src="../assets/icons/building-red.svg" width="200" alt="">
                <br>
                <br>
                <p><b>Sie haben Fragen oder benötigen Beratung zum SehrGuterPreis Direkt-Verkauf?</b></p>
                <p style="font-size: 16px;">Ihr SehrGuterPreis-Kundenservice ist telefonisch von<br>Montag bis Freitag 9 bis 18 Uhr erreichbar. <br>
                info@sehrguterpreis.de<br>
                +49 (0)2171 77 69 559<br>
                </p>

            </div>
            
            <div v-else-if="ankaufstation == 5 && performingRequest != true" class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1 mx-auto text-center">
                <br>
                <h3>Ihr Account pausiert!</h3>                
                <br>
                <img src="../assets/icons/pause-circle.svg" width="200" alt="">
                <br>
                <br>
                <p>Ihre Ankaufstation pausiert momentan. Sie erhalten jetzt keine neuen Ankauftermine mehr.<br>
                    Um Ihre Ankaufstation wieder zu aktivieren. Klicken Sie auf den den unten liegenden<br>
                    Button "Pausierung aufheben". Danach erhalten Sie wieder Ankauftermine.
                </p>
                <br>
                <p>
                    <a @click="activedAnkaufStation(true, 1)" class="btn btn-success btn-lg">Pausierung aufheben</a>
                </p>

            </div>
        
        <Footer/>
        </div>        
    </div>
</template>

<script>
import models from "../models";
import Sidebar from '@/components/Sidebar'
import Loading from '@/components/Loading'    
import Footer from '@/components/Footer'
import BuyStation from '@/components/BuyStation'
import BuyStationItem from '@/components/BuyStationItem'
import BuyStationPauseDialog from '@/components/BuyStationPauseDialog'
import BuyStationFilter from '@/components/BuyStationFilter'
import BuyStationOpenTimes from '@/components/BuyStationOpenTimes'
import { useToast } from 'vue-toast-notification'

export default {
    name: 'TerminView',
    components: {
        Sidebar,
        Loading,
        Footer,
        BuyStation,
        BuyStationItem,
        BuyStationFilter,
        BuyStationPauseDialog,
        BuyStationOpenTimes
    },
    data() {
        return {
            performingRequest: true,
            showpausedialog: false,
            tabactive: 0,
            appointments_new: [],
            appointments_accepted: [],
            appointments_done: [],
            ankaufstation: 0
        }
    }, 
    computed: {    
        title: () => {
            return "Ankauf Termine"
        }
    },
    mounted() {
        this.getAppointments();            
    },
    methods: {
        openPauseDialog(){
            this.showpausedialog = true;
        },
        closePauseDialog(){
            this.showpausedialog = false;
        },
        async getAppointments(){
            await models.getUserAppointments(this.$store.getters.StateToken).then(async res => {
                // console.log(res.data);
                if(res.data['message'] == "success"){
                    this.appointments_new = res.data['new_appointments'];
                    this.appointments_accepted = res.data['accepted_appointments'];
                    this.appointments_done = res.data['done_appointments'];                    
                    this.ankaufstation = 1;
                }else if(res.data['message'] == "applied"){
                    this.ankaufstation = 2;
                }else if(res.data['message'] == "not_accepted"){
                    this.ankaufstation = 3;
                }else if(res.data['message'] == "blocked"){
                    this.ankaufstation = 4;
                }else if(res.data['message'] == "not_applied"){
                    this.ankaufstation = 0;
                }else if(res.data['message'] == "paused"){
                    this.ankaufstation = 5;
                }else{
                    this.ankaufstation = 0;
                }                
                this.performingRequest = false;
            }).catch(err => {      
                this.performingRequest = false;
                console.log(err);
            });
        },
        async activedAnkaufStation(i, status){
            if(i == true)
            {
                this.performingRequest = true;
                await models.activedAnkaufstation(this.$store.getters.StateToken, status).then(res => {                    
                    if(res.data['message']=="success"){
                        const toast = useToast(); 
                        if(status == 1){
                            toast.open({
                                message: 'Ihre Ankaufstation wurde Aktiviert!',
                                type: 'success',
                                position: 'top-right'
                            });
                        }else if(status == 5){
                            toast.open({
                                message: 'Ihre Ankaufstation wurde Deaktiviert!',
                                type: 'error',
                                position: 'top-right'
                            });
                        }
                        // this.$emit('reload', true);
                        this.getAppointments();  
                    }
                    this.performingRequest = false;                    
                }).catch(err => {
                    console.log(err);
                    this.performingRequest = false;
                    const toast = useToast(); 
                    toast.open({
                        message: 'Es ist ein Fehler aufgetreten!',
                        type: 'error',
                        position: 'top-right'
                    });
                });
            }   
        },
        changeTab(num){
            this.tabactive = num;
        }
    }
}    
</script>

<style scoped src="../assets/css/app.css"></style>
<style>

.tabButton_active{
    cursor: pointer;
    padding: 10px 25px 10px 25px;
    background-color: green; 
    color: white; 
    border-radius: 15px;
    position: relative;
}
.tabButton{
    cursor: pointer;
    padding: 10px 25px 10px 25px;
    background-color: #e1e1e1; 
    color: #686868; 
    border-radius: 15px;
    position: relative;
}

.badge_tabs_btn {
	border-radius: 15px;
	width: 30px;
	height: 30px;
	display: block;
	position: absolute;
	background: #27c747;
	border: 2px solid white;
	display: flex;
	align-items: center;
	justify-content: center;
	top: -12px;
	right: -15px;
	transition: all .3s;
    font-size: 14px;
    color: #fff;
}

</style>