<template>
    <div class="load">
    <transition name="fade">
        <div v-if="loading" class="loading" style="padding-top: 50px;padding-left: 50px;">
            <p class="spinner-grow text-light" style="width: 2.5rem; height: 2.5rem; margin-right: 10px;" role="status">
            </p>
            <p class="spinner-grow text-light" style="width: 2.5rem; height: 2.5rem; margin-right: 10px;" role="status">
            </p>
            <p class="spinner-grow text-light" style="width: 2.5rem; height: 2.5rem; margin-right: 10px;" role="status">          
            </p>            
        </div>
    </transition>   
    </div>
</template>

<script>
export default {
    name: 'LoadingView',
    props: ['loading']   
}
</script>

<style scoped>
.load{
    text-align: center;
}
.loading {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(0,0,0, 0.6);
	z-index: 9999;
}
.loading p {
		color: #ffffff;
		text-align: center;
    margin: 40vh auto 0;
    
}
</style>