<template>
    <div id="temp">
        <a v-if="chatloading == false" @click="openChatView()" style="cursor: pointer;">
            <img src="../assets/icons/comments-alt-2-grey.svg" height="25" title="Nachrichten öffnen">
        </a>
        <div v-else-if="chatloading == true">
            <div style="width:100%;text-align:center;">
                <img src="@/assets/bx_loader.gif" width="25" height="25" alt="">
            </div>
        </div>
        <div v-if="openchat == true">
            <ChatMessageView v-bind:data="this.data" v-bind:ctoken="this.ctoken" v-bind:open="this.openchat"  v-bind:bycar="true" @close="() => closeChatView()"  />
        </div>
    </div>
</template>

<script>
import models from "../models"; 
import ChatMessageView from '@/components/ChatMessageView'   

export default {
    name: 'ChatOpenerView',
    components: {
        ChatMessageView,       
    },
    props: {
        data: {
            type: Object,
            default: () => {}
        },
    },
    data() {
        return {
            ctoken: "",
            openchat: false,
            chatloading: false,
        }
    }, 
    mounted() {
    },
    methods: {
        async openChatView(){
            // request ctoken
            this.chatloading = true;
            await models.getUserChatsById(this.data.carid, this.$store.getters.StateToken).then((response) => {
                // console.log(response.data);
                if(response.data['message'] == 'success'){
                    var chat = response.data['chat'];
                    this.ctoken = chat.ctoken;
                    this.openchat = true;                       
                    // console.log(chat.ctoken);
                }
                this.chatloading = false; 
            }).catch((error) => {
                console.log(error);
                this.chatloading = false; 
            });
        },
        closeChatView(){
            this.ctoken = "";
            this.openchat = false;
            this.chatloading = false;    
        },
    }
}    
</script>

<style scoped src="../assets/css/app.css"></style>
<style>

</style>