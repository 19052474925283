<template>
    <div v-if="show">
        <transition name="modal" id="modal-template">            
            <div>
                <div @click="closeModal()" class="close">
                    <img src="@/assets/icons/times.svg" alt="">
                </div>                        
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-container-lg">                                        
                            <!-- <div v-if="img !=''" :style="'width:100%;height: 200px; overflow:hidden;background-image:url('+img+'), url('+imgdefault+');background-size:cover;background-position:center center;border-top-left-radius: 15px;border-top-right-radius: 15px;'"></div> -->
                            <div class="modal-body-lg">
                                
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left mt-3 mb-3">
                                    <h5>Logistigauftrag erstellen</h5>
                                    <hr>
                                </div>

                                
                                <div class="row">
                                    <div class="col-md-12">
                                        <ul class="stepper stepper-horizontal">
                                            <li :class="step_1 ? 'active' : 'completed'">
                                                <a @click="step1" style="cursor: pointer;">
                                                <span class="circle">1</span>
                                                <span class="label">Preis erhalten</span>
                                                </a>
                                            </li>
                                            <li :class="step_2 ? 'active' : 'completed'">
                                                <a>
                                                <span class="circle">2</span>
                                                <span class="label">Auftrag buchen</span>
                                                </a>
                                            </li>
                                            <li :class="step_3 ? 'active' : 'completed'">
                                                <a>
                                                <span class="circle">3</span>
                                                <span class="label">Auftrag erstellt</span>
                                                </a>
                                            </li>
                                        </ul>    
                                    </div>
                                </div>

                                <div v-if="step_1 == true" id="step1" class="col-xs-12 col-sm-6 col-md-6 offset-md-3 mt-3 mb-3">
                                    <form @submit.prevent="getPrice">         
                                        <div style="margin-top: 18%">
                                            PLZ Von:<br>
                                            <input type="text" @keypress="isNumber($event)" v-model="form.fromzip" maxlength="5" required class="form-control" style="height:50px; font-size: 19px;" name="fromzip" id="fromzip" />
                                        </div>

                                        <div class="mt-4">
                                            PLZ Nach:<br>
                                            <input type="text" @keypress="isNumber($event)" v-model="form.tozip" maxlength="5" required class="form-control" style="height:50px; font-size: 19px;" name="tozip" id="tozip" />
                                        </div>
                                        
                                        <div class="mt-4">
                                            <span>Premium Transport:</span>
                                           
                                            <label for="sporttransport" style="margin-left: 25px;"><input type="radio" v-model="form.sporttransport" value="1" name="sporttransport" id="sporttransport"> Ja</label>
                                            <label for="sporttransport2" style="margin-left: 25px;"><input type="radio" v-model="form.sporttransport" checked value="0" name="sporttransport2" id="sporttransport2"> Nein</label>

                                            <br><p style="margin-top:7px;font-size:12px;font-weight:400;line-height:18px !important;color: rgb(119, 119, 119)">Unser Premium Transport-Service bietet Ihnen die optimale Lösung für den sicheren und schonenden Transport hochwertiger Sportwagen. Mit unserem geschlossenen Transporter, ausgestattet mit einer langen Rampe, gewährleisten wir höchste Sicherheitsstandards und eine schonende Beförderung Ihrer kostbaren Fahrzeuge.</p>
                                        </div>
                                        
                                        <br>
                                        <div v-if="this.loading == false">
                                            <button type="submit" class="btn btn-lg btn-success btn-block" style="width:100%">Preis anfordern</button>
                                        </div>
                                        <div v-if="this.loading == true">
                                            <div style="width:100%;text-align:center;">
                                                <img src="@/assets/bx_loader.gif" width="40" height="40" alt="">
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <div v-if="step_2 == true" id="step2" class="col-xs-12 col-sm-6 col-md-6 offset-md-3 mt-3 mb-3">       

                                    <form @submit.prevent="bookOrder">
                                        <div class="mt-4">
                                            <!-- <h2>Preis: <span id="logisticprice"></span> €</h2>
                                            <h5>Entfernung: <span id="distance">{{ distance }}</span> km</h5> -->
                                            <div class="row">
                                                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mx-auto">
                                                    <b>Ihr Preis: <span style="margin-top:7px;font-size:12px;font-weight:400;line-height:18px !important;color: rgb(119, 119, 119)">(zzgl. MwSt.)</span></b>
                                                </div>      
                                                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mx-auto" style="text-align: right;">
                                                    <h2 style="color:#48B213">{{ delivery }} €</h2>
                                                </div>   
                                            </div>
                                            <div class="row">
                                            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mx-auto">
                                                <b>Entfernung:</b>
                                            </div>      
                                            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mx-auto" style="text-align: right;">
                                                <h5>{{ distance }} km</h5>
                                            </div>   
                                            </div>
                                            <div class="row mt-3">
                                            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mx-auto">
                                                <b>Premium Transport:</b>
                                            </div>      
                                            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mx-auto" style="text-align: right;">
                                                <h5>{{ form.sporttransport == 0 ? "Nein" : "Ja" }} </h5>
                                            </div>   
                                            </div>

                                        </div>
                                        <hr>
                                        <div class="row mt-4">
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mx-auto">
                                                <b>Fahrzeug:</b>
                                            </div>                                                                            
                                        </div>
                                        <div class="mt-4">
                                            Marke:<br>
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mx-auto">
                                                <select v-model="form.make" @change="onChangeMake($event)" required style="height:40px; font-size: 19px;" class="form-control" name="makes">                                    
                                                    <option disabled value="">Alle Marken</option>
                                                    <option v-for="make in this.data.makedata" :key="make.makeid"  :value="make.makeid">{{ make.make }}</option>
                                                </select>
                                            </div>
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2 mx-auto">
                                                Modelle:<br>
                                                <select v-model="form.model" class="form-control" required style="height:40px; font-size: 19px;" disabled="true" @change=" onChangeModel($event)" name="models">
                                                    <option disabled value="">Alle Modelle</option>
                                                    <option v-for="model in this.modelData.models" :key="model.n"  :value="model.i">{{ model.n }}</option>
                                                </select> 
                                            </div>

                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2 mx-auto">
                                                FIN Nummer:<br>
                                                <input type="text" v-model="form.fin" maxlength="20" required class="form-control" style="height:40px; font-size: 19px;" name="fin" id="fin" />
                                            </div>

                                            <div class="row">
                                            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mt-2 mx-auto">
                                                HSN:<br>
                                                <input type="text" v-model="form.hsn" @keypress="isNumber($event)" maxlength="4" required class="form-control" style="height:40px; font-size: 19px;" name="hsn" id="hsn" />
                                            </div>
                                            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mt-2 mx-auto">
                                                TSN:<br>
                                                <input type="text" v-model="form.tsn" maxlength="3" required class="form-control" style="height:40px; font-size: 19px;" name="tsn" id="tsn" />
                                            </div> 
                                            </div> 


                                        </div>
                                        
                                    

                                        <div class="row mt-4">                                            
                                            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mx-auto">
                                                <b>Abholadresse:</b>
                                            </div>      
                                            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mx-auto" style="text-align: right;">
                                                <a @click="myPickupAddress()" style="cursor: pointer;">
                                                    <img src="@/assets/icons/arrow-down-circle-green.svg" width="25" height="25" alt=""> <span style="font-size: 15px;color: #48B213;">Meine Adresse</span>
                                                </a>
                                            </div>                                      
                                        </div>
                                        <div class="row mt-4">
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mx-auto">
                                                Name:<br>
                                                <input type="text" v-model="form.pickup_name" required class="form-control" style="height:40px; font-size: 19px;" name="pickup_name" id="pickup_name" />
                                            </div>                                                                            
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2 mx-auto">
                                                Straße:<br>
                                                <input type="text" v-model="form.pickup_street" required class="form-control" style="height:40px; font-size: 19px;" name="pickup_street" id="pickup_street" />
                                            </div>                                                                            
                                            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mt-2 mx-auto">
                                                PLZ:<br>
                                                <input type="text" v-model="form.pickup_zipcode" required @keypress="isNumber($event)" maxlength="5" class="form-control" style="height:40px; font-size: 19px;" name="pickup_zipcode" id="pickup_zipcode" />
                                            </div>                                                                            
                                            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mt-2 mx-auto">
                                                Stadt:<br>
                                                <input type="text" v-model="form.pickup_city" required class="form-control" style="height:40px; font-size: 19px;" name="pickup_city" id="pickup_city" />
                                            </div>                                                                            
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2 mx-auto">
                                                Telefon:<br>
                                                <input type="text" v-model="form.pickup_phone" required class="form-control" style="height:40px; font-size: 19px;" name="pickup_phone" id="pickup_phone" />
                                            </div>                                                                            
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2 mx-auto">
                                                E-Mail:<br>
                                                <input type="email" v-model="form.pickup_email" required class="form-control" style="height:40px; font-size: 19px;" name="pickup_email" id="pickup_email" />
                                            </div>                                                                            
                                        </div>

                                        <div class="row mt-4">                                            
                                            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mx-auto">
                                                <b>Lieferadresse:</b>
                                            </div>      
                                            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mx-auto" style="text-align: right;">
                                                <a @click="myDeliveryAddress()" style="cursor: pointer;">
                                                    <img src="@/assets/icons/arrow-down-circle-green.svg" width="25" height="25" alt=""> <span style="font-size: 15px;color: #48B213;">Meine Adresse</span>
                                                </a>
                                            </div>                                                                           
                                        </div>
                                         <div class="row mt-4">
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mx-auto">
                                                Name:<br>
                                                <input type="text" v-model="form.delivery_name" required class="form-control" style="height:40px; font-size: 19px;" name="delivery_name" id="delivery_name" />
                                            </div>                                                                            
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mx-auto">
                                                Straße:<br>
                                                <input type="text" v-model="form.delivery_street" required class="form-control" style="height:40px; font-size: 19px;" name="delivery_street" id="delivery_street" />
                                            </div>                                                                            
                                            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mx-auto">
                                                PLZ:<br>
                                                <input type="text" v-model="form.delivery_zipcode" required @keypress="isNumber($event)" maxlength="5" class="form-control" style="height:40px; font-size: 19px;" name="delivery_zipcode" id="delivery_zipcode" />
                                            </div>                                                                            
                                            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mx-auto">
                                                Stadt:<br>
                                                <input type="text" v-model="form.delivery_city" required class="form-control" style="height:40px; font-size: 19px;" name="delivery_city" id="delivery_city" />
                                            </div>   
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mx-auto">
                                                Telefon:<br>
                                                <input type="text" v-model="form.delivery_phone" required class="form-control" style="height:40px; font-size: 19px;" name="delivery_phone" id="delivery_phone" />
                                            </div>                                                                          
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mx-auto">
                                                E-Mail:<br>
                                                <input type="email" v-model="form.delivery_email" required class="form-control" style="height:40px; font-size: 19px;" name="delivery_email" id="delivery_email" />
                                            </div>                                                                          
                                        </div>

                                        <div class="row mt-4">
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mx-auto">
                                                <b>Liefertermin:</b><br><br>
                                                <div style="width: 100%;height: 40px;padding-top:1px;padding-left:1px;padding-right:1px;">
                                                    <div @click="setTransportType(0)" :class="form.transportype == 0 ? 'termintype_active' : 'termintype_deactive'">
                                                        <span>Schnellst möglich</span>
                                                    </div>
                                                    <div @click="setTransportType(1)" :class="form.transportype == 0 ? 'termintype_deactive' : 'termintype_active'" style="margin-left: 6%;">
                                                        <span>Termintransport</span>
                                                    </div>
                                                </div>
                                                <div>    
                                                    <br>
                                                    <input v-model="form.transport_date" type="date" data-date-format="DD.MM.YYYY" style="height:40px;" :disabled="form.transportype == 0 ? true : false" class="form-control" name="" id="">
                                                    <br>
                                                    <input v-model="form.transport_time" type="time" class="form-control" :disabled="form.transportype  == 0 ? true : false" name="" id="">                                                    
                                                </div>
                                                <br>                                               
                                            </div>   

                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mx-auto">
                                                <hr>
                                            </div>
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2 mx-auto">
                                                <b>Übergabe Checklist für den Fahrer:</b><br><br>
                                                <span style="float:left;"><input v-model="form.power_keys" :checked="true" type="checkbox" id="power_keys" /> Schlüssel:</span> <span @click="keycount('-')" style="float:left;display:block;width:20px;height:20px;margin-left: 10px;margin-right: 10px;margin-top:4px;padding-left:8px;font-size:12px;background-color: rgb(216, 216, 216);border-radius: 9px;cursor: pointer;">-</span> <span style="float:left;">{{this.form.power_keycount}}</span> <span @click="keycount('+')" style="float:left;display:block;width:20px;height:20px;margin-left: 10px;margin-top:4px;padding-left:6px;font-size:12px;background-color: rgb(216, 216, 216);border-radius: 9px;cursor: pointer;">+</span><br>
                                                <label for="power_paper"><input v-model="form.power_paper" :checked="true" type="checkbox" id="power_paper" /> Fahrzeugschein</label><br>
                                                <label for="power_letter"><input v-model="form.power_letter" :checked="false" type="checkbox" id="power_letter" /> Fahrzeugbrief</label><br>
                                                <label for="power_summer"><input v-model="form.power_summer" :checked="false" type="checkbox" id="power_summer" /> Sommerreifen</label><br>
                                                <label for="power_winter"><input v-model="form.power_winter" :checked="false" type="checkbox" id="power_winter" /> Winterreifen</label><br>
                                                <label for="power_tuev"><input v-model="form.power_tuev" :checked="false" type="checkbox" id="power_tuev" /> TÜV Bericht(e)</label><br>
                                                <label for="power_coc"><input v-model="form.power_coc" :checked="false" type="checkbox" id="power_coc" /> COC Papiere</label><br>
                                                <label for="power_abe"><input v-model="form.power_abe" :checked="false" type="checkbox" id="power_abe" /> ABE Bescheinung</label><br>
                                                <label for="power_condition"><input v-model="form.power_condition" :checked="false" type="checkbox" id="power_condition" /> Zustandsbericht</label><br>
                                                <br>
                                            </div>       
                                        </div>

                                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-3 mx-auto">
                                            <hr>
                                        </div>

                                        <small style="font-size: 12px;">Ihre Unterschrift für die Vollmacht:</small>
                                        <VueSignaturePad 
                                            width="450px"
                                            height="150px"
                                            id="signature"                                                        
                                            ref="signaturePad"                                                        
                                        />                                                
                                        <div>
                                            <a @click="deleteSignatur" class="text-danger">Löschen</a>
                                        </div>

                                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-3 mx-auto">
                                            <hr>
                                        </div>

                                        <div class="row mt-4">
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mx-auto">                                                
                                                <label for="agps" style="font-size: 14px;"><input type="checkbox" required id="agps" /> Hiermit bestätige ich die AGBs gelesen zuhaben und akzeptiere diese. </label>
                                            </div>
                                        </div>

                                        <br>
                                        <br>
                                        <div v-if="this.loading == false">
                                            <span style="margin-right: 20px;"><button type="text" @click="step1" class="btn btn-lg btn-default btn-block" style="width:30%">Zurück</button></span>
                                            <span><button type="submit" class="btn btn-lg btn-success btn-block" style="width:65%">Auftrag buchen</button></span>
                                        </div>
                                        <div v-if="this.loading == true">
                                            <div style="width:100%;text-align:center;">
                                                <img src="@/assets/bx_loader.gif" width="40" height="40" alt="">
                                            </div>
                                        </div>

                                    </form>
                                </div>

                                <div v-if="step_3 == true" id="step3" class="col-xs-12 col-sm-6 col-md-6 offset-md-3 mt-3 mb-3 text-center">

                                    <br><br>
                                    <img src="../../assets/icons/checkmark-circle-green.svg" style="color:#48B213; width: 80px; height:80px;" alt="">
                                    <br><br>
                                    <h3>Vielen Dank für Ihren Auftrag</h3>
                                    <p>Ihr Auftrag wurde erstellt, wir verbinden Sie zeitnah mit einem Fahrer.</p>
                                    <br>
                                    <div @click="closeModal()" class="text-danger" style="cursor: pointer;">Schließen</div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
    <div style="margin-top:0px;">
        <a @click="openModal()" class="btn btn-md btn-success">+ Auftrag erstellen</a>
    </div>
</template>

<script>
import models from "../models";
import makes from '@/config/makes.js'
import { useToast } from 'vue-toast-notification'
import { VueSignaturePad } from 'vue-signature-pad';

export default {
    name: 'AddLogisticOrderView',    
    components: {
        VueSignaturePad
    },
    props: {},
    data() {
        return {
            // msgsend: this.data.angefragt,
            form: {},
            loading: false,
            show: false,
            data: makes,
            modelData: [],
            marke: 'Alle',
            markeid: '',
            model: 'Alle',
            modelid: '',
            distance: 0,
            delivery: 0,
            pickup: true,
            step_1: true,
            step_2: false,
            step_3: false
        }
    },
    mounted() {     
         
    },
    methods: {        
        openModal()
        {
            this.step1()
            this.show = true;
            document.body.classList.add('noscroll');
            this.form.sporttransport = "0";
        },
        closeModal()
        {
            this.step1()            
            this.show = false;            
            document.body.classList.remove('noscroll')
        },
        deleteSignatur(){
            this.$refs.signaturePad.clearSignature();
        },
        step1()
        {
            console.log('step 1')     
            this.form = {}       
            this.step_1 = true
            this.step_2 = false
            this.step_3 = false
            this.form.sporttransport = "0";
        },
        step2()
        {
            console.log('step 2')
            this.form.make = ""
            this.form.model = "" 
            this.form.pickup_name = ""
            this.form.pickup_street = ""
            this.form.pickup_zipcode = ""
            this.form.pickup_city = ""
            this.form.pickup_phone = ""
            this.form.pickup_email = ""
            this.form.delivery_name = ""
            this.form.delivery_street = ""
            this.form.delivery_zipcode = ""
            this.form.delivery_city = ""
            this.form.delivery_phone = ""   
            this.form.power_keycount = 2
            this.form.power_keys = true
            this.form.power_paper = true
            this.form.power_letter = false
            this.form.power_summer = false
            this.form.power_winter = false
            this.form.power_tuev = false
            this.form.power_coc = false
            this.form.power_abe = false
            this.form.power_condition = false
            this.form.transportype = 0
            this.step_1 = false
            this.step_2 = true
            this.step_3 = false
        },
        step3()
        {
            console.log('step 3')
            this.step_1 = false
            this.step_2 = false
            this.step_3 = true
            this.form = {}
        },
        setTransportType(int)
        {
            this.form.transportype = int;
        },
        keycount(click){
            if(click == '+'){
                if(this.form.power_keycount != '5'){
                    this.form.power_keycount = this.form.power_keycount +1
                }
            }
            else if(click == '-'){
                if(this.form.power_keycount != '1'){
                    this.form.power_keycount = this.form.power_keycount -1
                }
            }
        },
        getJsonFile (index) 
        {
            this.modelData = require('../assets/carmodels/' + index + '.json')
        },
        async onChangeMake(event) 
        {
            this.markeid = event.target.value
            this.marke = event.target.options[event.target.selectedIndex].text  
            if(event.target.value != ""){                                   
                this.modelid = ''
                this.model = 'Alle'    
                this.modelData = []        
                this.getJsonFile(this.markeid)
                document.getElementsByName("models")[0].disabled = false                
            }else{
                this.modelid = ''
                this.model = 'Alle'
                this.modelData = []
                document.getElementsByName("models")[0].disabled = true
            }
        },
        onChangeModel(event) 
        {            
            if(event.target.value != ""){
                this.modelid = event.target.value
                this.model = event.target.options[event.target.selectedIndex].text            
            }else {
                this.modelid = ''
                this.model = 'Alle'
                this.modelData = []
            }            
        },
        isNumber(evt) 
        {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        async getPrice()
        {
            console.log(this.form.sporttransport);

            this.loading = true
            await models.getlocations(this.form.fromzip, this.form.tozip).then(res => {                    
                this.form.pickup_lat = res.data.from.lat;
                this.form.pickup_lon = res.data.from.lon;
                this.form.delivery_lat = res.data.to.lat;
                this.form.delivery_lon = res.data.to.lon;
                models.getDistance(res.data.from.lat, res.data.from.lon, res.data.to.lat, res.data.to.lon).then((response) => {
                    this.distance = response;                    
                    if(this.form.sporttransport == "0"){
                        this.delivery = models.getDeliveryCosts(response)['net'];
                        this.form.cost = models.getDeliveryCosts(response)['net'];
                    }else{
                        this.delivery = models.getDeliveryCostsPremium(response)['net'];
                        this.form.cost = models.getDeliveryCostsPremium(response)['net'];
                    }
                    this.form.distance = response;                    
                });
                this.step2();
                this.loading = false;
            }).catch(err => {
                console.log(err);
                this.loading = false;
            });
        },
        async bookOrder()
        {
            // console.log(this.form)
            const signaturRef = this.$refs.signaturePad.saveSignature().data;
            this.form.signatur = signaturRef.split('data:image/png;base64,')[1];
            
            
            this.loading = true 
            if(this.form.fromzip == this.form.pickup_zipcode && this.form.tozip == this.form.delivery_zipcode)
            {
                var make = this.form.make;
                var model = this.form.model;
                var makename = "";
                var modelname = "";
                this.data.makedata.map(function(value) {
                    if(value['makeid'] == make){ makename = value['make']; }
                });            
                this.modelData.models.map(function(value) {
                    if(value['i'] == model){ modelname = value['n'];}
                });

                this.form.makename = makename;
                this.form.modelname = modelname;                
                await models.booklogistcOrders(this.$store.getters.StateToken, this.form).then(res => {                                 
                    console.log(res.data);                    
                    if(res.data['message'] == 'success'){                         
                        const toast = useToast(); 
                        toast.open({
                            message: 'Wir haben Ihren Auftrag erhalten!',
                            type: 'success',
                            position: 'top-right'
                        });
                        this.loading = false; 
                        this.step3();
                        // reload logistic orders
                        this.$emit('booked', true)
                    }else{
                        const toast = useToast();
                        toast.open({
                            message: 'Es ist ein Fehler aufgetreten!',
                            type: 'error',
                            position: 'top-right'
                        }); 
                        this.loading = false; 
                    }                               
                }).catch(err => {
                    console.log(err);
                    const toast = useToast(); 
                    toast.open({
                        message: 'Es ist ein Fehler aufgetreten!',
                        type: 'error',
                        position: 'top-right'
                    }); 
                    this.loading = false;
                });
            }else{
                this.loading = false;
                const toast = useToast(); 
                toast.open({
                    message: 'Postleitzahlen passen nicht überein!',
                    type: 'error',
                    position: 'top-right'
                }); 
            }   

        },
        myPickupAddress(){
            this.form.pickup_name = this.$store.getters.StateUser['firma']
            this.form.pickup_street = this.$store.getters.StateUser['street']
            this.form.pickup_zipcode = this.$store.getters.StateUser['zipcode']
            this.form.pickup_city = this.$store.getters.StateUser['city']
            this.form.pickup_phone = this.$store.getters.StateUser['phone']
            this.form.pickup_email = this.$store.getters.StateUser['email']
        },
        myDeliveryAddress()
        {
            this.form.delivery_name = this.$store.getters.StateUser['firma']
            this.form.delivery_street = this.$store.getters.StateUser['street']
            this.form.delivery_zipcode = this.$store.getters.StateUser['zipcode']
            this.form.delivery_city = this.$store.getters.StateUser['city']
            this.form.delivery_phone = this.$store.getters.StateUser['phone']
            this.form.delivery_email = this.$store.getters.StateUser['email']            
        }
    }
}
</script>

<style>

.close{
    position: fixed;
    display: block;
    width: 150px;
    height: 150px;
    top: 10px;
    right: 20px;
    cursor: pointer;
    z-index: 99999999;
}

.feature-tag{
    background-color: #f2f2f2;
    padding: 5px 10px;
    border-radius: 10px;       
    color: #000;
    border:1px solid #dedede;
    display:block;
    float:left; 
    margin-right: 10px; 
    margin-bottom: 10px; 
    font-size: 13px;
}

.detailsBtn{
    display: block;
    width: 100%;
    cursor: pointer; 
    margin-top: 10px; 
    color:#454545; 
    text-decoration: none; 
    font-size: 16px;
    font-weight: 600;
    padding: 6px 20px 6px 20px;
    border: 1px solid #dedede;
    border-radius: 15px;
}
.detailsBtn:hover {
    color: #000;
    background-color: #dedede;
}


.termintype_active{
    float:left; 
    cursor: pointer;
    width:47%;
    height: 38px; 
    text-align: center; 
    background-color: #48B213;
    border-radius: 20px;
    padding-top:3px;
    border: 1px solid #ececec;
}
.termintype_active span{
    font-size: 14px;
    color:#fff;
}
.termintype_deactive{
    float:left; 
    cursor: pointer;
    width:47%;
    height: 38px; 
    text-align: center; 
    background-color: #fff;
    border-radius: 20px;
    padding-top:3px;
    border: 1px solid #ececec;
}
.termintype_deactive span{
    font-size: 14px;
    color:#000;
}


</style>
<style scoped src="../../assets/css/stepper.css"></style>