<template>
    <div v-if="parked == true" class="row bg-white border mt-4" style="padding:0px;">             
        <ImageCarousel v-bind:show="show" v-bind:images="rimgs" @showgallery="(i) => this.show = i" />
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3" style="padding:0px;position: relative;">    
                <div class="photocount">
                    <span class="iconfloat-title"><img src="../assets/icons/camera.svg" style="height:15px; width:15px;"></span> {{photocount}}         
                </div>     
                <div v-if="this.parked" class="parkcount">
                    Geparked
                </div>     
                <div v-if="data.image != ''">
                    <img @click="openGallery()" :src="data.image" @error="setAltImg" class="img-responsive" style="width: 300px; height: 250px; object-fit: cover;">
                </div>
                <div v-else>
                    <img src="../assets/nopic.png" class="img-responsive" style="width: 300px; height: 250px; object-fit: cover;">
                </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 mt-3">
            <h5 class="text-truncate">{{ data.title }}</h5>
            <div class="mt-2" style="font-size: 17px;">
                EZ: {{ data.ez }}<br>
                Kilometer: {{ this.$filters.toCurrency(data.km) }} km<br>                   
                Kraftstoff: {{ data.full }}<br>
                Getriebe: {{ data.getriebe }}                  
            </div>                                    
            <div class="mt-2" style="font-size: 14px;">                
                Entferung: {{this.distance}} km <br>
                Logistikkosten: {{this.delivery}} € (Netto)<br>
                SGP-Service: {{this.sgpservice}} € (Netto)                   
            </div>                                    
            </div>
            <div class="align-items-center align-content-center col-xs-12 col-sm-12 col-md-12 col-lg-3 border-left mt-2">

                <div style="position:relative;width: 100%;">
                    <div style="position:absolute; top: 10px; right:10px;">                    
                        <img @click="parkOut(data.mid)" src="@/assets/icons/car-unpark.svg" title="Fahrzeug ausparken" style="width: 28px;cursor: pointer;" alt="">                        
                    </div> 
                    <div style="position: absolute; top: 45px; right: 12px;">
                        <ChatOpener v-bind:data="data" />
                    </div>
                </div>

                <div class="d-flex flex-row align-items-center">                
                    <div v-if="data.lastprice != '0'" class="mb-3">
                        <h4 class="mr-1" style="margin-top: 10px;">{{ this.$filters.toCurrency(data.lastprice) }} € <small style="font-size: 11px;">{{data.mwst == '0' ? 'Ohne MwSt.' : 'Inkl. MwSt.' }}</small></h4>
                        <span class="price-badge" style="margin-right:20px;">- {{ this.$filters.toCurrency(getDiff(data.price, data.lastprice)) }} € </span>
                        <span style="font-size: 14px;"><s>{{ this.$filters.toCurrency(data.price) }} €</s></span>
                    </div>
                    <div v-else>
                        <h4 class="mr-1" style="margin-top: 10px;">{{ this.$filters.toCurrency(data.price) }} € <small style="font-size: 11px;">{{data.mwst == '0' ? 'Ohne MwSt.' : 'Inkl. MwSt.' }}</small></h4>
                        <p class="text-success mb-4" style="font-size:14px;">Optional mit Lieferung</p>                    
                    </div>
                </div>      
                <div class="d-flex flex-column mt-1 text-center">
                    <SendMessageBtn v-bind:data="data" />
                    <CarDetailView v-bind:data="data" />
                </div>
            </div>              
        </div>
    </template>

<script>
import SendMessageBtn from '@/components/SendMessageBtn'
import ImageCarousel from '@/components/ImageCarousel'
import CarDetailView from '@/components/CarDetailView'
import ChatOpener from '@/components/ChatOpener'
import imgdefault from '@/assets/nopic2.png'
import models from "../models";

export default {
    name: 'ParkItem',
    props: {
        data: {}
    },
    components: {
        SendMessageBtn,
        ImageCarousel,
        CarDetailView,
        ChatOpener
    },
    data() {
        return {   
            show: false,     
            parked: true,
            rimgs: [],
            photocount: 0,
            distance: 0,
            delivery: 0,
            sgpservice: 0
        }
    },         
    mounted () {   
        models.getDistance(this.data.lat, this.data.lon, this.$store.getters.StateUser['lat'], this.$store.getters.StateUser['lon']).then((response) => {
            this.distance = response;
            this.delivery = models.getDeliveryCosts(response)['net']
        });
        this.sgpservice = models.getSGPServiceCosts(this.data.price)['net']    
        const imgs = this.data.images.split(",")
        for (let index = 0; index < imgs.length; index++) {
            const element = imgs[index]
            this.rimgs.push(element.replace('_8.JPG', '_20.JPG'))
            this.photocount = this.rimgs.length
        }
    },
    methods: {    
        setAltImg(event) { 
            event.target.src = imgdefault
        },     
        getDiff: function (value1, value2) {
            var val = (Number(value1) - Number(value2));
            return val;
        },
        openGallery(){
            document.body.classList.add('noscroll')
            this.show = true;
        },
        async parkOut(mid) {
            await models.parkCarOut(this.$store.getters.StateToken, mid).then((response) => {
                if(response.data['message'] == 'success'){
                    this.parked = false;   
                    this.$emit('add', 1)
                }
            }).catch((error) => {
                console.log(error);
            });
        },             
    }
}

</script>


<style scoped src="../assets/css/car.css"></style>