<template>
    <div id="settings">
        <Loading v-bind:loading="performingRequest"/>
        <div class="sidebar"><Sidebar/></div>
        <div class="body-text">

            <div class="container-fluid pagepadding">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1 mx-auto" style="padding-left: 10px;">
                    <h1>{{ title }}</h1>    
                    <br>
                    <hr>          
                    </div>        
                </div> 
            </div> 
        
        <Footer/>
        </div>        
    </div>
</template>

<script>
    import Sidebar from '@/components/Sidebar'
    import Loading from '@/components/Loading'    
    import Footer from '@/components/Footer'
    export default {
        name: 'SettingsView',
        components: {
            Sidebar,
            Loading,
            Footer
        },
        data() {
            return {
                performingRequest: true
            }
        }, 
        computed: {    
            title: () => {
                return "Einstellungen"
            }
        },
        mounted() {
            this.performingRequest = false
        }
    }    
</script>

<style scoped src="../assets/css/app.css"></style>
<style>

</style>