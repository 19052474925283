<template>
    <div id="searchresults">
        <Loading v-bind:loading="performingRequest"/>
        <div class="sidebar"><Sidebar/></div>
        <div class="body-text">
            <div class="container-fluid pagepadding">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1 mx-auto" style="padding-left: 10px;">
                        <a @click="goBack()" style="cursor: pointer;" title="Zurück zur Suche"> 
                            <h1><span class="iconfloat-title"><img src="../assets/icons/angle-back.svg" style="height:40px; width:40px;"></span>  {{ title }}</h1>  
                        </a>
                        <hr>
                    </div>
                </div>

                <div v-if="carscount == 0 && performingRequest != true" class="text-center">
                    <br><br><br>
                    <img src="../assets/icons/search-alt-green.svg" style="width:200px;">                
                    <br><br><br>
                    <h3>Keine Autos gefunden!</h3>
                    <p>Starten Sie eine neue Suche!</p>
                    <a @click="goBack()" class="btn btn-success btn-lg">
                        Neue Suche starten
                    </a>
                    <br>                    
                </div>

                <div v-for="car in cars" :key="car.id" class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1 mx-auto">                 
                    <CarItem v-bind:data="car" v-bind:parkedcars="this.parkedcars"/>         
                </div>  
            </div> 
        <Footer/>
        </div>        
    </div>
</template>

<script>
import models from "../models";
import Sidebar from '@/components/Sidebar'
import Loading from '@/components/Loading'    
import CarItem from '@/components/CarItem'    
import Footer from '@/components/Footer'

export default {
    name: 'SearchResultsView',
    components: {
        Sidebar,
        Loading,
        CarItem,
        Footer
    },
    data() {
        return {
            performingRequest: true,
            cars: [],
            parkedcars: [],
            carscount: 0
        }
    },     
    computed: {    
        title: () => {
            return "Suchergebnisse";
        },
    },
    mounted() {
        this.getSearchResults();        
    },
    methods: {
        goBack(){
            this.$router.go(-1);
        },
        getSearchResults: async function()  {            
            await models.getSearch(
                history.state.body.uid,
                history.state.body.make,
                history.state.body.model,
                history.state.body.priceto,
                history.state.body.kmto,
                history.state.body.fuel,
                history.state.body.gearbox,
                history.state.body.firstreg,                
                history.state.body.sort,
                history.state.body.mwst,
                history.state.body.umkreisactive,
                history.state.body.searchplz,
                history.state.body.distance,
            ).then(res => {
                // console.log(res.data);
                this.cars = res.data['results'];
                this.carscount = res.data['results'].length;
                for (let index1 = 0; index1 < res.data['parkedcars'].length; index1++) {
                    const element = res.data['parkedcars'][index1];        
                    this.parkedcars.push(element['mid']);
                }
                this.performingRequest = false;
            }).catch(err => {      
                console.log(err);
                this.performingRequest = false;
            });  
        },        
    }
}    
</script>

<style scoped src="../assets/css/app.css"></style>
<style>

</style>