<template>
    <div id="contact">
        <Loading v-bind:loading="performingRequest"/>
        <div class="sidebar"><Sidebar/></div>
        <div class="body-text">

            <div class="container-fluid pagepadding">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1 mx-auto" style="padding-left: 10px;">
                    <h1><span class="iconfloat-title"><img src="../assets/icons/consulting.svg" style="height:24px; width:24px;"></span>  {{ title }}</h1>                     
                    <hr>          
                    </div>        
                </div> 

                <div class="row">

                    <div class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1 mx-auto" style="padding-left: 10px;">
                        <br>
                        <p>Kontaktiere uns per E-Mail oder direkt per Telefon. <br>Zusätzlich bietet SehrGuterPreis auch einen Direktkontakt per WhatsApp an.</p>
                        <p style="margin-bottom: 40px;">
                            Tel: +49 02171 77 69 559
                            <br>
                            Email: info@sehrguterpreis.de
                        </p>
                        <hr>
                        <br>    
                        <a href="mailto:info@sehrguterpreis.de">
                            <img src="../assets/email-kontakt.png">
                        </a>

                        <br>
                        <br>

                        <a href="tel:49021717769559">
                            <img src="../assets/telefon-kontakt.png">
                        </a>

                        <br>
                        <br>
                        
                        <a href="https://api.whatsapp.com/send?phone=491723738650" target="_blank">
                            <img src="../assets/whatsapp-kontakt.png">
                        </a>
                    
                    </div>

                </div>

            </div> 

            
        
        <Footer/>
        </div>        
    </div>
</template>

<script>
    import Sidebar from '@/components/Sidebar'
    import Loading from '@/components/Loading'    
    import Footer from '@/components/Footer'
    export default {
        name: 'ContactView',
        components: {
            Sidebar,
            Loading,
            Footer
        },
        data() {
            return {
                performingRequest: true
            }
        }, 
        computed: {    
            title: () => {
                return "Kontakt"
            }
        },
        mounted() {
            this.performingRequest = false
        }
    }    
</script>

<style scoped src="../assets/css/app.css"></style>
<style>

</style>