<template>
    <div class="col-xs-12 col-sm-12 col-md-12">  
        <div style="height: 130px;">
            <h3 style="margin-top: 0px; height: 30px; padding-top:10px;padding-left:0px;font-size:19px;">
                {{ cartitle }}
            </h3>						
            <p style="color: grey;">
                Rechnungsnummer: {{ data.billid }}<br>
                Datum: {{ billdate }}
            </p>

            <div class="row">	
                <div class="col-xs-7 col-sm-7 col-md-7 text-left">
                    <div class="badge" style="background: #187c0b;color:#fff;border:1px solid #187c0b;border-radius:30px;">
                        <h4 style="font-size: 16px;padding-left:3px;padding-right:3px;color:#fff;margin-top:1px;margin-bottom:2px;">
                            Betrag: {{  this.$filters.toCurrency(data.billpricebrutto) }} €
                        </h4>
                    </div>
                </div>

                <div v-if="this.data.type == 'sys'" class="col-xs-2 col-sm-2 col-md-2 text-right">	
                    <div v-if="data.contracttoken != '' ">
                        <a :href="'https://app.sehrguterpreis.de/pdf/c7xV4fhk93y2.php?ctoken='+data.contracttoken" target="_blank">							
                            <div class="badge" style="background: grey;color:#fff;border:1px solid grey;border-radius:30px;">
                                <h4 style="font-size: 16px;padding-left:3px;padding-right:3px;color:#fff;margin-top:1px;margin-bottom:2px;">
                                    Kaufvertrag
                                </h4>
                            </div>
                        </a>
                    </div>                    
                </div>		

                <div :class="this.data.type == 'sys' ? 'col-xs-3 col-sm-3 col-md-3 text-right' : 'col-xs-5 col-sm-5 col-md-5 text-right' " style="text-align:right">		
                    <!-- <a :href="'https://app.sehrguterpreis.de/pdf/r78few39hy.php?ctoken='+data.billtoken" target="_blank"> -->
                    <a :href="'https://app.sehrguterpreis.de/invoices/'+data.billtoken+'.pdf'" target="_blank">
                        <div :class="colorClass" style="color:#fff;border:1px solid #187c0b;border-radius:30px;">
                            <h4 style="font-size: 16px;padding-left:3px;padding-right:3px;color:#fff;margin-top:1px;margin-bottom:2px;">
                                {{ status[data.billstatus] }}
                            </h4>
                        </div>
                    </a>
                </div>											
            </div>

        </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12">  
        <br>
        <hr>
    </div>
</template>

<script>

export default {
    name: 'InvoiceItem',
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        car: {
            type: Object,
            default: () => {}
        },
        title: {
            type: String,
            default: () => {}
        },
    },  
    data() {
        return {
            cartitle: '',
            status: [
                "Rechnung Offen",
                "Rechnung bezahlt",
                "Rechnung Storniert",
            ],
            billdate: new Date(this.data.time * 1000).toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' }),
            colorClass: 'open', 
        }
    },   
    mounted() {         
        if(this.data.type == "man"){
            this.cartitle = this.title
        }else{
            this.cartitle = this.car.title
        }
        if(this.data.billstatus == 0){
            this.colorClass = 'badge open'
        } else if(this.data.billstatus == 1){
            this.colorClass = 'badge paid'
        } else if(this.data.billstatus == 2){
            this.colorClass = 'badge storno'
        }
    },
}

</script>

<style>
.open{
    background: #f2ab46;
    color:#fff;
    border-radius:30px;
    border: 1px solid #f2ab46 !important;
}
.paid{
    background: #187c0b;
    color:#fff;
    border-radius:30px;
    border: 1px solid #187c0b !important;
}
.storno{
    background: red;
    color:#fff;
    border-radius:30px;
    border: 1px solid red !important;
}
</style>