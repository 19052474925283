<template>
    <div v-if="show">
        <transition name="modal" id="modal-template">            
            <div>
                <div @click="closeModal()" class="close">
                    <img src="@/assets/icons/times.svg" alt="">
                </div>                        
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-container-lg">                                        
                            <!-- <div v-if="img !=''" :style="'width:100%;height: 200px; overflow:hidden;background-image:url('+img+'), url('+imgdefault+');background-size:cover;background-position:center center;border-top-left-radius: 15px;border-top-right-radius: 15px;'"></div> -->
                            <div class="modal-body-lg">
                                
                                <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left mt-3 ">
                                    <h5>Auftrag Übersicht <span v-if="data.premium == '1'" style="float:right;color:green;font-size: 18px;">Premium</span></h5>
                                    <hr>
                                </div>
                                </div>

                                
                                <div class="row">
                                    <div class="col-md-12">
                                        <ul class="stepper stepper-horizontal">
                                            <li :class="data.orderstatus == '0' ? 'active' : 'completed'">
                                                <a>
                                                <span class="circle">1</span>
                                                <span class="label">In bearbeitung</span>
                                                </a>
                                            </li>
                                            <li :class="data.orderstatus == '1' ? 'active' : 'completed'">
                                                <a>
                                                <span class="circle">2</span>
                                                <span class="label">Termin gebucht</span>
                                                </a>
                                            </li>
                                            <li :class="data.orderstatus == '2' ? 'active' : 'completed'">
                                                <a>
                                                <span class="circle">3</span>
                                                <span class="label">In Auslieferung</span>
                                                </a>
                                            </li>
                                            <li :class="data.orderstatus == '3' ? 'active' : 'completed'">
                                                <a>
                                                <span class="circle">4</span>
                                                <span class="label">Abgeschlossen</span>
                                                </a>
                                            </li>
                                        </ul>    
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left mt-2">                                    
                                        <hr>
                                    </div>
                                </div>
                    
                                <div v-if="data.orderstatus > 0" class="row" style="padding-top: 10px;">
                                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 text-center">                                    
                                        <small>Abholtermin:</small><br>
                                        {{formatDate(data.pickup_time)}} Uhr
                                    </div>  
                                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 text-center">
                                        <small>Liefertermin:</small><br>
                                        {{formatDate(data.delivery_time)}} Uhr
                                    </div>  
                                </div>
                                
                                <div v-if="data.orderstatus > 0" class="row">
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left mt-3 mb-1">                                    
                                        <hr>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" style="position: relative;">    
                                        <div class="stepper d-flex flex-column mt-3 ml-2 mb-2">                           
                                            <div class="d-flex mt-2 mb-1">
                                                <div class="d-flex flex-column pr-4 align-items-center">
                                                    <div class="rounded-circle py-2 bg-sgp1 text-white mb-1" style="padding-left: 12px;padding-right: 12px;"><img style="width: 20px;height:20px;" src="../../assets/icons/map-marker-white.svg" alt=""></div>
                                                    <div class="line h-100"></div>
                                                </div>
                                                <div>
                                                    <h5 class="text-dark text-truncate">{{data.pickup_city}}, {{data.pickup_country}}-{{data.pickup_plz}}</h5> 
                                                    <p class="lead text-muted pb-3"></p>                   
                                                </div>
                                            </div>

                                            <div class="d-flex mb-1">
                                                <div class="d-flex flex-column pr-4 align-items-center">
                                                    <div class="rounded-circle py-2 bg-sgp2 text-white mb-1" style="padding-left: 12px;padding-right: 12px;"><img style="width: 20px;height:20px;" src="../../assets/icons/map-marker-white.svg" alt=""></div>
                                                    <div class="line h-100"></div>
                                                </div>
                                                <div>
                                                    <h5 class="text-dark text-truncate">{{data.delivery_city}}, {{data.delivery_country}}-{{data.delivery_plz}}</h5>                    
                                                </div>
                                            </div>               
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 pt-2">  
                                        <div v-if="data.orderstatus < 3"><br></div>  
                                        <h2>Preis: {{data.cost}} €</h2>
                                        <h5>Entfernung: {{data.distance}} km</h5>
                                        <a v-if="data.orderstatus == 3" href="#" target="_blank" class="buyBtn" style="width: 150px;margin-top:15px;text-align: center;">Rechnung</a>
                                    </div>  
                                </div>

                                <div class="row">
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left mt-1 mb-3">                                    
                                        <hr>
                                    </div>
                                </div>

                                <div class="row">

                                    <div v-if="data.orderstatus == 0" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-success" style="padding-top: 0px;">
                                        <span v-if="data.transportype == 0">Schnellst möglicher Termin</span><span v-if="data.transportype == 1">Termintransport - {{ formatDateWithTime(data.transport_time) }} Uhr</span>
                                        <br>
                                        <br>
                                    </div>

                                    <div :class="data.orderstatus > 0 ? 'col-xs-12 col-sm-3 col-md-3 col-lg-3' : 'col-xs-12 col-sm-4 col-md-4 col-lg-4'">
                                        <b>Abholung</b>
                                        <p style="font-size: 15px;">
                                            {{data.pickup_name}}<br>
                                            {{data.pickup_street}}<br>
                                            {{ data.pickup_country }}-{{ data.pickup_plz}} {{data.pickup_city}}<br>
                                            Tel: <QrCode v-bind:text="data.pickup_phone" />
                                        </p>
                                    </div>
                                    <div :class="data.orderstatus > 0 ? 'col-xs-12 col-sm-3 col-md-3 col-lg-3' : 'col-xs-12 col-sm-4 col-md-4 col-lg-4'">
                                        <b>Lieferung</b>
                                        <p style="font-size: 15px;">
                                            {{data.delivery_city}}<br>
                                            {{data.delivery_street}}<br>
                                            {{data.delivery_country}}-{{data.delivery_plz}} {{data.delivery_city}}<br>
                                            Tel: <QrCode v-bind:text="data.delivery_phone" /><br>
                                        </p>
                                    </div>
                                    <div v-if="data.orderstatus > 0" :class="data.orderstatus > 0 ? 'col-xs-12 col-sm-3 col-md-3 col-lg-3' : ''">
                                        <b>Fahrer</b>
                                        <p style="font-size: 15px;">                                            
                                            {{data.driver_name}}<br>                                            
                                            {{data.driver_email}}<br>
                                            Tel: <QrCode v-bind:text="data.driver_phone" /><br>                                            
                                        </p>
                                    </div>
                                    <div :class="data.orderstatus > 0 ? 'col-xs-12 col-sm-3 col-md-3 col-lg-3' : 'col-xs-12 col-sm-4 col-md-4 col-lg-4'">
                                        <b>Fahrzeug</b>
                                        <p style="font-size: 15px;">
                                        {{data.car_title}}<br>
                                        FIN: {{data.car_vin}}<br>
                                        Länge: {{data.car_length}} cm<br>
                                        Breite: {{data.car_width}} cm<br>
                                        Höhe: {{data.car_height}} cm<br>
                                        Leergewicht: {{data.car_weight}} kg
                                        </p>
                                    </div>
                                </div>                            
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
    <div style="margin-top:5px;">
        <a @click="openModal()" style="font-size: 16px; margin-top:10px;cursor: pointer;">Auftrag Details</a>
    </div>
</template>

<script>
// import models from "../models";
// import makes from '@/config/makes.js'
import QrCode from '@/components/QrCode'   

export default {
    name: 'LogisticDetailsView',    
    components: {
        QrCode
    },
    props: {
        data: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            loading: false,
            show: false,                        
            status: [
                "In bearbeitung",
                "Termin gebucht",
                "In Auslieferung",
                "Abgeschlossen",
                "Storniert",
            ]
        }
    },
    mounted() {     
        // console.log(this.data)       
        // console.log(this.data.orderstatus)               
    },
    methods: {        
        openModal(){
            this.show = true;
            document.body.classList.add('noscroll');
        },
        closeModal(){
            this.show = false;            
            document.body.classList.remove('noscroll')
        },    
        formatDate(timestamp) {
            return new Date(timestamp * 1000).toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' });
        },
        formatDateWithTime(str) {
            return new Date(str * 1000).toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' });
        }                 
    }
}
</script>

<style>

.close{
    position: fixed;
    display: block;
    width: 150px;
    height: 150px;
    top: 10px;
    right: 20px;
    cursor: pointer;
    z-index: 99999999;
}

.feature-tag{
    background-color: #f2f2f2;
    padding: 5px 10px;
    border-radius: 10px;       
    color: #000;
    border:1px solid #dedede;
    display:block;
    float:left; 
    margin-right: 10px; 
    margin-bottom: 10px; 
    font-size: 13px;
}

.detailsBtn{
    display: block;
    width: 100%;
    cursor: pointer; 
    margin-top: 10px; 
    color:#454545; 
    text-decoration: none; 
    font-size: 16px;
    font-weight: 600;
    padding: 6px 20px 6px 20px;
    border: 1px solid #dedede;
    border-radius: 15px;
}
.detailsBtn:hover {
    color: #000;
    background-color: #dedede;
}


</style>
<style scoped src="../../assets/css/stepper.css"></style>