<template>
    <div id="temp">
        <Loading v-bind:loading="performingRequest"/>
        <div class="sidebar"><Sidebar/></div>
        <div class="body-text">

            <div class="container-fluid pagepadding">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1 mx-auto" style="padding-left: 10px;">
                    <h1><span class="iconfloat-title"><img src="../assets/icons/pie-chart.svg" style="height:24px; width:24px;"></span>  {{ title }}</h1>                     
                    <span style="float: right; margin-top: -70px;">                                        
                        <StockDetails v-bind:data="stockdata['items']" />
                    </span>
                    <hr>          
                    </div>        
                </div> 
            </div> 

            <div class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1 mx-auto">
                <div class="row mt-3">
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                        <div class="card text-center">
                            <div class="card-header">Bestand Total</div>
                            <div class="card-body">
                                <b>{{stockcount}} Fahrzeuge</b>
                            </div>
                        </div>                     
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                        <div class="card text-center">
                            <div class="card-header">Bestand Wert</div>
                            <div class="card-body">
                                <b>{{ this.$filters.toCurrency(allprice) }} €</b>
                            </div>
                        </div>                     
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                        <div class="card text-center">
                            <div class="card-header">⌀ Preis</div>
                            <div class="card-body">
                                <b>{{ this.$filters.toCurrency(preisdurchschnitt) }} €</b>
                            </div>
                        </div>                   
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">                    
                        <div class="card text-center">
                            <div class="card-header">⌀ Standtage</div>
                            <div class="card-body">
                                <b>{{standtagedurchschnitt}} Tage</b>
                            </div>
                        </div>                     
                    </div>                  
                </div>

                <div class="row mt-5">
                    <div class="col-xs-6 col-sm-6 col-md-6">
                        <div align="center">
                            <h5>PREISLABEL VERTEILUNG</h5>
                            <VueApexCharts width="350" type="donut" :options="chartOptions_pricelabelcount" :series="series_pricelabelcount" />
                        </div>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-6">
                        <div align="center">
                            <h5>PREIS VERTEILUNG</h5>
                            <VueApexCharts width="350" type="donut" :options="chartOptions_pricecount" :series="series_pricecount" />
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12">
                        <br><hr>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-xs-6 col-sm-6 col-md-6">
                        <div align="center">
                            <h5>KILOMETER VERTEILUNG</h5>
                            <VueApexCharts width="350" type="donut" :options="chartOptions_milecount" :series="series_milecount" />                        
                        </div>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-6">
                        <div align="center">
                            <h5>ERSTZULASSUNG VERTEILUNG</h5>
                            <VueApexCharts width="350" type="donut" :options="chartOptions_buildcount" :series="series_buildcount" />
                        </div>
                    </div>  
                    <div class="col-xs-12 col-sm-12 col-md-12">
                        <br><hr>
                    </div>                 
                </div>              
                <div class="row mt-5">                   
                    <div class="col-xs-6 col-sm-6 col-md-6">
                        <div align="center">
                            <h5>STANDTAGE VERTEILUNG</h5>
                            <VueApexCharts width="350" type="donut" :options="chartOptions_standcount" :series="series_standcount" />
                        </div>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-6">
                        <div align="center">
                            <h5>GETRIEBE VERTEILUNG</h5>
                            <VueApexCharts width="350" type="donut" :options="chartOptions_gearcount" :series="series_gearcount" />
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12">
                        <br><hr>
                    </div>
                </div>              
                <div class="row mt-5">                    
                    <div class="col-xs-6 col-sm-6 col-md-6">
                        <div align="center">
                            <h5>MARKEN VERTEILUNG</h5>
                            <VueApexCharts width="350" type="donut" :options="chartOptions_brands" :series="series_brands" />
                        </div>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-6">
                        <div align="center">
                            <h5>KRAFTSTOFF VERTEILUNG</h5>
                            <VueApexCharts width="350" type="donut" :options="chartOptions_fullcount" :series="series_fullcount" />
                        </div>
                    </div>
                </div>

            </div>


            

            <div v-if="stockcount == 0 && performingRequest != true" class="text-center">
                <br><br><br>
                <h3>Keine Daten verfügbar!</h3>
                <p>Schauen Sie zu einem späteren Zeitpunkt nochmal!</p>
                <br><br><br>
                <img src="@/assets/icons/pie-chart-green.svg"  style="width:180px;">
            </div>

            <div v-for="chat in this.chats" :key="chat.idchat" class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1 mx-auto">                
                <InvoiceItem v-bind:data="chat" v-bind:car="JSON.parse(chat.cardata)"/>                                             
            </div>
        
        <Footer/>
        </div>        
    </div>
</template>

<script>
import models from "../models";
import Sidebar from '@/components/Sidebar'
import Loading from '@/components/Loading'    
import Footer from '@/components/Footer'
import StockDetails from '@/components/StockDetails'
import VueApexCharts from 'vue-apexcharts'

export default {
    name: 'StockView',
    components: {
        Sidebar,
        Loading,
        Footer,
        VueApexCharts,
        StockDetails
    },
    data() {
        return {
            performingRequest: true,
            stockcount: 0,
            allprice: 0,
            preisdurchschnitt: 0,
            standtagedurchschnitt: 0,
            stockdata: [],

           
            
            series_pricelabelcount: [],            
            chartOptions_pricelabelcount: {
                chart: {
                    type: 'donut',            
                },
                colors: ['#2E7D32', '#3DA042', '#4CAF50',  '#61CD65', '#A5D6A7', '#FF9800', '#EF6C00', '#ED0707', '#9E9E9E'],
                labels: [], 
                dataLabels: {
                    enabled: true,
                },
                plotOptions: {
                    pie: {
                        customScale: 1,
                        donut: {
                            size: '65%',
                        }
                    },                
                },                
                legend: {
                    show: true,
                    showForSingleSeries: true,
                    showForNullSeries: true,
                    showForZeroSeries: true,
                    position: 'bottom',
                    horizontalAlign: 'center', 
                    floating: false,
                    fontSize: '14px',
                    fontFamily: 'Helvetica, Arial',
                    fontWeight: 400,
                    inverseOrder: false,
                    offsetX: 0,
                    offsetY: 0,
                },            
            },

            series_pricecount: [],               
            chartOptions_pricecount: {
                chart: {
                    type: 'donut',            
                },
                colors: ['#2E7D32', '#3DA042', '#4CAF50',  '#61CD65', '#A5D6A7', '#FF9800', '#EF6C00', '#ED0707', '#9E9E9E'],
                labels: [], 
                dataLabels: {
                    enabled: true,
                },
                plotOptions: {
                    pie: {
                        customScale: 1,
                        donut: {
                            size: '65%',
                        }
                    },                
                },                
                legend: {
                    show: true,
                    showForSingleSeries: true,
                    showForNullSeries: true,
                    showForZeroSeries: true,
                    position: 'bottom',
                    horizontalAlign: 'center', 
                    floating: false,
                    fontSize: '14px',
                    fontFamily: 'Helvetica, Arial',
                    fontWeight: 400,
                    inverseOrder: false,
                    offsetX: 0,
                    offsetY: 0,
                },                  
            },
            
            series_standcount: [],            
            chartOptions_standcount: {
                chart: {
                    type: 'donut',            
                },
                colors: ['#2E7D32', '#3DA042', '#4CAF50',  '#61CD65', '#A5D6A7', '#FF9800', '#EF6C00', '#ED0707', '#9E9E9E'],
                labels: [], 
                dataLabels: {
                    enabled: true,
                },
                plotOptions: {
                    pie: {
                        customScale: 1,
                        donut: {
                            size: '65%',
                        }
                    },                
                },                
                legend: {
                    show: true,
                    showForSingleSeries: true,
                    showForNullSeries: true,
                    showForZeroSeries: true,
                    position: 'bottom',
                    horizontalAlign: 'center', 
                    floating: false,
                    fontSize: '14px',
                    fontFamily: 'Helvetica, Arial',
                    fontWeight: 400,
                    inverseOrder: false,
                    offsetX: 0,
                    offsetY: 0,
                },                  
            },
            
            series_buildcount: [],            
            chartOptions_buildcount: {
                chart: {
                    type: 'donut',            
                },
                colors: ['#2E7D32', '#3DA042', '#4CAF50',  '#61CD65', '#A5D6A7', '#FF9800', '#EF6C00', '#ED0707', '#9E9E9E'],
                labels: [], 
                dataLabels: {
                    enabled: true,
                },
                plotOptions: {
                    pie: {
                        customScale: 0.9,
                        donut: {
                            size: '65%',
                        }
                    },                
                },                
                legend: {
                    show: true,
                    showForSingleSeries: true,
                    showForNullSeries: true,
                    showForZeroSeries: true,
                    position: 'bottom',
                    horizontalAlign: 'center', 
                    floating: false,
                    fontSize: '14px',
                    fontFamily: 'Helvetica, Arial',
                    fontWeight: 400,
                    inverseOrder: false,
                    offsetX: 0,
                    offsetY: 0,
                },                  
            },
            
            series_milecount: [],            
            chartOptions_milecount: {
                chart: {
                    type: 'donut',            
                },
                colors: ['#2E7D32', '#3DA042', '#4CAF50',  '#61CD65', '#A5D6A7', '#FF9800', '#EF6C00', '#ED0707', '#9E9E9E'],
                labels: [], 
                dataLabels: {
                    enabled: true,
                },
                plotOptions: {
                    pie: {
                        customScale: 1,
                        donut: {
                            size: '65%',
                        }
                    },                
                },                
                legend: {
                    show: true,
                    showForSingleSeries: true,
                    showForNullSeries: true,
                    showForZeroSeries: true,
                    position: 'bottom',
                    horizontalAlign: 'center', 
                    floating: false,
                    fontSize: '14px',
                    fontFamily: 'Helvetica, Arial',
                    fontWeight: 400,
                    inverseOrder: false,
                    offsetX: 0,
                    offsetY: 0,
                },                 
            },
            
            series_gearcount: [],            
            chartOptions_gearcount: {
                chart: {
                    type: 'donut',            
                },
                colors: ['#2E7D32', '#3DA042', '#4CAF50',  '#61CD65', '#A5D6A7', '#FF9800', '#EF6C00', '#ED0707', '#9E9E9E'],
                labels: [], 
                dataLabels: {
                    enabled: true,
                },
                plotOptions: {
                    pie: {
                        customScale: 0.8,
                        donut: {
                            size: '65%',
                        }
                    },                
                },                
                legend: {
                    show: true,
                    showForSingleSeries: true,
                    showForNullSeries: true,
                    showForZeroSeries: true,
                    position: 'bottom',
                    horizontalAlign: 'center', 
                    floating: false,
                    fontSize: '14px',
                    fontFamily: 'Helvetica, Arial',
                    fontWeight: 400,
                    inverseOrder: false,
                    offsetX: 0,
                    offsetY: 0,
                },                   
            },
            
            series_fullcount: [],            
            chartOptions_fullcount: {
                chart: {
                    type: 'donut',            
                },
                colors: ['#2E7D32', '#3DA042', '#4CAF50',  '#61CD65', '#A5D6A7', '#FF9800', '#EF6C00', '#ED0707', '#9E9E9E'],
                labels: [], 
                dataLabels: {
                    enabled: true,
                },
                plotOptions: {
                    pie: {
                        customScale: 1,
                        donut: {
                            size: '65%',
                        }
                    },                
                },                
                legend: {
                    show: true,
                    showForSingleSeries: true,
                    showForNullSeries: true,
                    showForZeroSeries: true,
                    position: 'bottom',
                    horizontalAlign: 'center', 
                    floating: false,
                    fontSize: '14px',
                    fontFamily: 'Helvetica, Arial',
                    fontWeight: 400,
                    inverseOrder: false,
                    offsetX: 0,
                    offsetY: 0,
                },                   
            },
            
            series_brands: [],            
            chartOptions_brands: {
                chart: {
                    type: 'donut',
                    position: 'center'         
                },
                colors: ['#2E7D32', '#3DA042', '#4CAF50',  '#61CD65', '#A5D6A7', '#FF9800', '#EF6C00', '#ED0707', '#9E9E9E'],
                labels: [], 
                dataLabels: {
                    enabled: true,
                },
                plotOptions: {
                    pie: {
                        customScale: 1,
                        donut: {
                            size: '65%',
                        }
                    },                
                },                
                legend: {
                    show: true,
                    showForSingleSeries: true,
                    showForNullSeries: true,
                    showForZeroSeries: true,
                    position: 'bottom',
                    horizontalAlign: 'center', 
                    floating: false,
                    fontSize: '14px',
                    fontFamily: 'Helvetica, Arial',
                    fontWeight: 400,
                    inverseOrder: false,
                    offsetX: 0,
                    offsetY: 0,
                },                   
            },
            
            // series_models: [],            
            // chartOptions_models: {
            //     chart: {
            //         type: 'donut',            
            //     },
            //     colors: ['#2E7D32', '#3DA042', '#4CAF50',  '#61CD65', '#A5D6A7', '#FF9800', '#EF6C00', '#ED0707', '#9E9E9E'],
            //     labels: [], 
            //     dataLabels: {
            //         enabled: false,
            //     },
            //     plotOptions: {
            //         pie: {
            //             // customScale: 1,
            //             width: 450,
            //             donut: {
            //                 size: '65%',
            //             }
            //         },                
            //     },                
            //     legend: {
            //         show: true,
            //         showForSingleSeries: true,
            //         showForNullSeries: true,
            //         showForZeroSeries: true,
            //         position: 'bottom',
            //         horizontalAlign: 'center', 
            //         floating: false,
            //         fontSize: '14px',
            //         fontFamily: 'Helvetica, Arial',
            //         fontWeight: 400,
            //         inverseOrder: false,
            //         offsetX: 0,
            //         offsetY: 0,
            //     },                   
            // },




        }
    }, 
    computed: {    
        title: () => {
            return "Bestandsanalyse"
        }
    },
    mounted() {
        this.getStockData();
    },
    methods: {
        async getStockData(){
            await models.getStock(this.$store.getters.StateUser.sellerid).then( async res => {
                this.stockdata = res.data;
                this.stockcount = res.data['stockcount'];
                this.allprice = res.data['allprice'];
                this.preisdurchschnitt = res.data['preisdurchschnitt'];
                this.standtagedurchschnitt = res.data['standtagedurchschnitt'];
                this.performingRequest = false;

                for (const key in res.data['pricelabelcount']) {                    
                    this.chartOptions_pricelabelcount.labels.push(key);
                    this.series_pricelabelcount.push(res.data['pricelabelcount'][key]);
                }
                
                for (const key in res.data['pricecount']) {                    
                    this.chartOptions_pricecount.labels.push(key);
                    this.series_pricecount.push(res.data['pricecount'][key]);
                }
                
                for (const key in res.data['standcount']) {                    
                    this.chartOptions_standcount.labels.push(key);
                    this.series_standcount.push(res.data['standcount'][key]);
                }
                
                for (const key in res.data['buildcount']) {                    
                    this.chartOptions_buildcount.labels.push(key);
                    this.series_buildcount.push(res.data['buildcount'][key]);
                }
                
                for (const key in res.data['milecount']) {                    
                    this.chartOptions_milecount.labels.push(key);
                    this.series_milecount.push(res.data['milecount'][key]);
                }
                
                for (const key in res.data['gearcount']) {                    
                    this.chartOptions_gearcount.labels.push(key);
                    this.series_gearcount.push(res.data['gearcount'][key]);
                }
                
                for (const key in res.data['fullcount']) {                    
                    this.chartOptions_fullcount.labels.push(key);
                    this.series_fullcount.push(res.data['fullcount'][key]);
                }
                
                for (const key in res.data['brands']) {                    
                    this.chartOptions_brands.labels.push(key);
                    this.series_brands.push(res.data['brands'][key]);
                }
                
                // for (const key in res.data['models']) {                    
                //     this.chartOptions_models.labels.push(key);
                //     this.series_models.push(res.data['models'][key]);
                // }

                // console.log(res.data);
            }).catch(err => {
                this.performingRequest = false;
                console.log(err);
            });
        }
    }
}    
</script>

<style scoped src="../assets/css/app.css"></style>
<style>

</style>