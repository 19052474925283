<template>
    <div v-if="showdialog" class="dialogview" >
        <transition name="fade" class="loading">
            <div>                
                <div class="dialogbox">                    
                    <h5><b>Jetzt VEEKEL Ankaufstation werden</b></h5>                    
                    <p style="font-size: 16px;margin-top: 15px;">Bewerben Sie sich mit einem Klick.</p>
                    <br>
                    
                    <span><a @click="closeModal(true)" class="btn btn-lg btn-success btn-block">Jetzt als Ankaufstation bewerben</a></span>
                    <br><br><a @click="closeModal(false)" class="text-danger"><b>Abbrechen</b></a>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'BuyStationDialogView',
    props: ['showdialog'],    
    methods: {
        closeModal(anwser) {
            this.$emit('closedialog', false)            
            this.$emit('answer', anwser)            
        },      
    },
    mounted() {

    }
}
</script>

<style scoped>
.dialogview{
    display: flex;
    margin: auto;
    justify-content: center;
    text-align: center;
    z-index: 99999;
}

.dialogbox{
    margin: auto;
    margin-top: 14%;
    width: 550px;
    height: auto;
    background-color: #fff;
    border-radius: 15px;
    padding: 45px;
}
.loading {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.7);
	z-index: 99999;
    padding-top: 0px;
}
</style>