<template>
  <div class="login">     
    <Loading v-bind:loading="performingRequest"/>
    <LoginHeader/>
    <div class="container">
      <div class="col-md-6 offset-md-3">
        <br><br><br>
        <form @submit.prevent="submit">            
        <input class="form-control" style="height:60px;" name="username" type="text" v-model="form.username" placeholder="E-Mail"><br>
        <input class="form-control" style="height:60px;" name="password" type="password" v-model="form.password" placeholder="Passwort"><br><br>
        <button type="submit" class="btn btn-lg btn-success" style="padding-left: 60px; padding-right: 60px;">Anmelden</button><br><br>
        <!-- <p>You don't have an account? <router-link to="/sign-up"><b>Create one</b></router-link></p> -->
        </form>
        <p v-if="showError" id="error">{{errorText}}</p>
        <p style="margin-top: 30px;"><router-link to="/forgot-password" style="color: #000; text-decoration: none;"><b>Passwort vergessen?</b></router-link></p>
      </div>
    </div>
  </div>
</template>

<script>


import LoginHeader from '@/components/LoginHeader'
import Loading from '@/components/Loading'
import { mapActions } from "vuex";
// import axios from "axios";
// var md5 = require('md5');

export default {
  name: "LoginView",
  components: {
    LoginHeader,
    Loading
  },
  data() {
    return {      
      form: {
        username: "",
        password: "",
      },
      performingRequest: false,
      showError: false,
      errorText: ''
    };
  },
  methods: {
    ...mapActions(["LogIn"]),
    async submit() {
      this.performingRequest = true;
      const formData = new FormData();
      formData.append("user", this.form.username);
      formData.append("pass", this.form.password);
      try {
          await this.LogIn(formData).then( (res) => {
            if(res['message'] == "success"){
            this.performingRequest = false;
            this.showError = false
            this.$router.push("/home");
            }else{
              this.performingRequest = false;
              this.showError = true
              this.errorText = res['message']
            }
          });                    
      } catch (err) {
        this.showError = true
        this.performingRequest = false;
      }
    },   
  }  
};

</script>

<style scoped>
.login{
  text-align: center;
}

a:hover{
  text-decoration: none;  
}

#error{
  color: red;
}
</style>
