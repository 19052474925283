<template>
    <div v-if="show">
        <transition name="modal" id="modal-template">            
            <div>             
                <Dialog 
                    v-bind:showdialog="this.showdialog" 
                    v-bind:title="'Fahrzeug bezahlt?'" 
                    v-bind:text="'Das Fahrzeug wurde bezahlt und kann jetzt abgeholt werden.'" 
                    v-bind:btnname="'Bezahlt'" 
                    v-bind:btnclass="'success'" 
                    @closedialog="(i) => closePaidConfirm(i)"
                    @answer="(i) => paid(i)"
                />   
                <DealClose 
                    v-bind:showdialog="this.showDealDialog" 
                    v-bind:autocontract="this.sale.autocontract" 
                    v-bind:delivery="this.sale.delivery" 
                    v-bind:title="'Geschäft Abschließen'" 
                    v-bind:text="'Schließen Sie das Geschäft ab.<br>Unterschreiben Sie Kaufvertrag und Vollmacht in einem.'" 
                    v-bind:btnname="'Geschäft Abschließen'" 
                    v-bind:btnclass="'success'" 
                    @closedialog="(i) => closeDealDone(i)"
                    @answer="(i) => setDealDone(i)"                    
                />  
                <DealStorno 
                    v-bind:showdialog="this.showStornoDialog" 
                    v-bind:title="'Fahrzeug stornieren?'" 
                    v-bind:text="'Klicken Sie auf Stornieren und das Geschäft abzubrechen.'" 
                    v-bind:btnname="'Stornieren'" 
                    v-bind:btnclass="'danger'" 
                    @closedialog="(i) => closeStorno(i)"
                    @answer="(i) => setStorno(i)"
                />
                <div v-if="showclose" @click="closeModal()" class="close">
                    <img src="@/assets/icons/times.svg" alt="">
                </div>                        
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-container-lg">                                        
                            <div v-if="img !=''" :style="'width:100%;height: 200px; overflow:hidden;background-image:url('+img+'), url('+imgdefault+');background-size:cover;background-position:center center;border-top-left-radius: 15px;border-top-right-radius: 15px;'"></div>
                            <div class="modal-body-lg text-left">
                                
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left mt-3 mb-3">
                                    <h5>
                                        {{ data.title }}
                                        <div v-if="carpaid == true" style="float:right;">
                                            <span class="badge paid">Fahrzeug wurde Bezahlt</span>
                                        </div>                                        
                                    </h5>                                    
                                    <hr>
                                </div>

                                <div class="row mt2 mb-2">
                                    <h6><b>Fahrzeug Informationen</b></h6>
                                    <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 text-left">
                                        <div style="font-size: 16px;">
                                            EZ: {{ data.ez }}<br>
                                            HU: {{ data.hu }}<br>
                                            Kilometer: {{ this.$filters.toCurrency(data.km) }} km<br>                   
                                            Kraftstoff: {{ data.full }}<br>
                                            Getriebe: {{ data.getriebe }} <br>
                                            Kategorie: {{ data.category }} <br>
                                            FIN: {{ sale.fin }} <br>       
                                        </div>                                                                        
                                    </div>
                                    <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 text-left">                                                               
                                        <div style="font-size: 16px;">     
                                            Unfall: {{ sale.unfallfrei }}<br>        
                                            Vorbesitzer: {{ sale.vorbesitzer }}<br>        
                                            Schlüssel: {{ sale.schluessel }}<br>        
                                            Abmeldung: {{ sale.abmeldung == '0' ? 'Verkäufer' : 'Händler' }}<br>                                                                                                                                    
                                        </div> 
                                       
                                        <h4 class="mr-1" style="margin-top: 10px;">Preis: {{ this.$filters.toCurrency(sale.price) }} € <small style="font-size: 14px;">{{sale.mwst == '0' ? 'Ohne MwSt.' : 'Inkl. MwSt.' }}</small></h4>
                                        <span class="price-badge" style="margin-right:20px;">- {{ this.$filters.toCurrency(getDiff(sale.inseratprice, sale.price)) }} €</span> 
                                        <span style="font-size: 14px;"><s>{{ this.$filters.toCurrency(sale.inseratprice) }} €</s></span>
                                       
                                    </div>

                                    <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 text-center" style="font-size: 16px;">
                                        
                                        <div v-if="dealdone == true">
                                            <div v-if="carpaid == false">
                                                <img src="@/assets/icons/money-protection-green.svg" style="width: 45px;">
                                                <br><br>
                                                <b>Fahrzeug bezahlen.</b> 
                                                <p>Bezahlen Sie ihr Fahrzeug und<br>bestätigen Sie die Zahlung.</p>
                                                <br><br>
                                            </div>
                                            <div v-else> 
                                                <div v-if="sale.status == 0">  
                                                    <img src="@/assets/icons/timer-green.svg" style="width: 45px;">
                                                    <br><br>
                                                    <b>Auslieferung vorbereiten.</b> 
                                                    <p>Wir informieren Sie zeitnah<br>über den Liefertermin.</p>
                                                    <br><br>
                                                </div>
                                                <div v-if="sale.status == 1">  
                                                    <img src="@/assets/icons/calendar-green.svg" style="width: 45px;">
                                                    <br><br>
                                                    <b>Termin für Auslieferung.</b>
                                                    <p>Ihr Fahrzeug wird am {{ sale.deliverydate }}<br>um {{ sale.deliverytime }} Uhr geliefert</p>
                                                    <br><br>
                                                </div>
                                                <div v-if="sale.status == 2">  
                                                    <img src="@/assets/icons/circle-check-green.svg" style="width: 45px;">
                                                    <br><br>
                                                    <b>Auftrag Abgeschlossen.</b> 
                                                    <p>Ihr Fahrzeug wurde ausgeliefert.</p>
                                                    <br><br>
                                                </div>
                                                <div v-if="sale.status == 3">  
                                                    <img src="@/assets/icons/times-circle-red.svg" style="width: 45px;">
                                                    <br><br>
                                                    <b>Auftrag Storniert.</b> 
                                                    <p>Wir haben Ihren Auftrag storniert.</p>
                                                    <br><br>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else-if="dealdone == false">
                                            <img src="@/assets/icons/handshake-green.svg" style="width: 45px;">
                                            <br><br>
                                            <b>Geschäft abschließen.</b> 
                                            <p>Schließen Sie das Geschäft ab und<br>Unterschreiben Sie den Kaufvertrag.</p>
                                            <br><br>
                                        </div>

                                    </div>
                                </div>

                                <div v-if="loadingdeal == true" style="font-size: 16px;text-align: center;">
                                    <hr>
                                    <img src="@/assets/loading.gif" width="150" alt="">
                                </div>
                                <div v-else>
                                    <div v-if="carpaid == false">                                       
                                        <div v-if="dealdone == false" class="row mb-2">
                                            <hr>                                       
                                            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 text-center">                                            
                                                <a @click="openDealDone()" class="btn btn-success btn-block btn-lg" style="width: 100%;margin-top:8px;">Geschäft abschließen</a>
                                            </div>
                                            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 text-center">
                                                <a @click="openStorno()" class="btn btn-danger btn-block btn-lg" style="width: 100%;margin-top:8px;">Buchung stornieren</a>
                                            </div>
                                        </div> 
                                        <div v-else class="row mb-2">
                                            <hr>    
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">                                            
                                                <a @click="openPaidConfirm()" class="btn btn-warning btn-block btn-lg" style="margin-top:8px;">Fahrzeug auf bezahlt stellen</a>
                                            </div>
                                        </div>                                                             
                                    </div>
                                </div>

                                <div class="row mt2 mb-2">
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left">
                                        <hr>
                                    </div>
                                </div>

                            

                                <div class="row mt2 mb-2">
                                    <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5 text-left">
                                        <div style="font-size: 16px;">
                                        <h6><b>Verkäufer</b></h6>
                                            Name: {{ sale.sellername }}<br>
                                            Telefon: {{ sale.sellerphone }}<br>
                                            E-Mail: {{ sale.selleremail }}<br>
                                        </div>
                                    </div>                                    
                                    <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7 text-left">                                                                                
                                        <div style="font-size: 16px; padding-top: 30px;">
                                            <span v-if="dealdone == true && sale.status == 2">
                                                <a :href="'https://app.sehrguterpreis.de/invoices/'+sale.billtoken+'.pdf'" target="_blank" class="btn btn-md btn-success" style="padding-left:40px;padding-right:40px;">Rechnung</a>
                                            </span>
                                            <span v-if="dealdone == true" style="margin-left: 15px;">
                                                <a :href="'https://app.sehrguterpreis.de/pdf/c7xV4fhk93y2.php?ctoken='+sale.contracttoken" target="_blank" class="btn btn-md btn-success" style="padding-left:40px;padding-right:40px;">Kaufvertrag</a>                                            
                                            </span>                                                                                        
                                            <span v-if="sale.gutachten == '1' && sale.gutachten_doc != '' && sale.gutachten_doc != null" style="margin-left: 15px;">
                                                <a :href="'https://app.sehrguterpreis.de/gutachten/'+sale.gutachten_doc" target="_blank" class="btn btn-md btn-success" style="padding-left:40px;padding-right:40px;">Gutachten</a>                                            
                                            </span>   
                                            <span v-else-if="sale.gutachten == '1' && sale.gutachten_doc == null">
                                                <div class="text-center" style="color: #000">
                                                    Warten auf das Schadengutachten vom Kunden
                                                    <p v-if="sale.gutachten_termin != null" style="color: green">
                                                        <b>Termin am: {{ formatDate(sale.gutachten_termin) }} Uhr</b>
                                                    </p>
                                                </div>                                                
                                            </span>                                                                                   
                                        </div>                                        
                                    </div>
                                </div>

                                <div class="row mt2 mb-2">
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left">
                                        <hr>
                                    </div>
                                </div>

                                <div v-if="sale.status == 1" class="row mt2 mb-2">
                                    <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5 text-left">
                                        <div style="font-size: 16px;">
                                        <h6><b>Spediteur</b></h6>
                                            Name: {{ sale.drivername }}<br>
                                            Telefon: {{ sale.driverphone }}<br>
                                            E-Mail: {{ sale.driveremail }}<br>
                                        </div>
                                    </div>                                    
                                    <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7 text-left">
                                        <div style="font-size: 16px; padding-top: 30px;">                                            
                                            <span>     
                                                <a :href="'https://app.sehrguterpreis.de/pdf/v077m4ch7.php?stoken='+sale.stoken" target="_blank" class="btn btn-md btn-default" style="padding-left:40px;padding-right:40px;">Transport Vollmacht</a>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                
                                <div v-if="sale.status == 1" class="row mt2 mb-2">
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left">
                                        <hr>
                                    </div>
                                </div>

                                <div class="row mt2 mb-4" style="font-size: 16px;">
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left" style="padding-top:6px;padding-bottom:6px;border-bottom: 1px solid #dedede;">
                                        Anzahlung: <span style="float:right">{{sale.anzahlung}}.00 €</span>
                                    </div>                                    
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left" style="margin-bottom: 15px;padding-top:6px;padding-bottom:6px;border-bottom: 1px solid #dedede;">
                                        <b>Fahrzeugpreis: <span style="float:right">{{ sale.price }}.00 €</span></b>
                                    </div>                                    
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left" style="padding-top:6px;padding-bottom:6px;border-bottom: 1px solid #dedede;">
                                        SGP-Service: <span style="float:right">{{sale.servicecost}} €</span>
                                    </div>                                    
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left" style="padding-top:6px;padding-bottom:8px;border-bottom: 1px solid #dedede;">
                                        Lieferung: <span style="float:right">{{ sale.logisticcost }} €</span>
                                    </div>                                    
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left" style="padding-top:6px;padding-bottom:8px;border-bottom: 1px solid #dedede;">
                                        Gutachten: <span style="float:right">{{ sale.gutachtencost }} €</span>
                                    </div>                                    
                                    <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left" style="padding-top:6px;padding-bottom:8px;border-bottom: 1px solid #dedede;">
                                        Treuhand: <span style="float:right">{{ sale.treuhandcost }} €</span>
                                    </div>                                     -->
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left" style="padding-top:6px;padding-bottom:6px;border-bottom: 1px solid #dedede;">
                                        <b>Gesamtpreis: <span style="float:right">{{sale.totalcost}} €</span></b>
                                    </div>                                    
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
    <div style="margin-top: 20px;">   
        <a @click="openModal()" class="detailsBtn mt-2">
            Buchungsdetails
        </a>
    </div>
</template>

<script>
import models from "../models";
import imgdefault from '@/assets/nopic-details.png'
import { useToast } from 'vue-toast-notification'
import Dialog from '@/components/Dialog.vue'
import DealClose from '@/components/DealClose.vue'
import DealStorno from '@/components/DealStorno.vue'

export default {
    name: 'SaleDetailView',    
    props: {
        data: {
            type: Object,
            required: true
        },
        sale: {
            type: Object,
            required: true
        },
    },
    components: {
        Dialog,
        DealClose,
        DealStorno
    },
    data() {
        return {
            loadingdeal: false,
            msgsend: this.data.angefragt,
            show: false,
            showdialog: false,
            showclose: true,
            showDealDialog: false,
            showCloseDeal: true,
            signatur: "",
            showStornoDialog: false,
            showCloseStorno: true,
            img: "",
            imgdefault: imgdefault,
            ratings: null,
            distance: 0,
            delivery: 0,
            sgpservice: 0,
            carpaid: false,
            dealdone: false,
            setpaidloading: false,
            status: [
                "Auslieferung vorbereiten",
                "Termin für Auslieferung",
                "Ausgeliefert",
                "Storniert"
            ]
        }
    },
    mounted() {
        if(this.sale.dealerpaid == 1){ this.carpaid = true }
        if(this.sale.deal_done == 1){ this.dealdone = true }
        this.img = 'https://www.sehrguterpreis.de/soldcars/'+this.sale.mdata
    },
    methods: {
        getDiff: function (value1, value2) {
            var val = (Number(value1) - Number(value2));
            return val;
        },
        formatDate(timestamp) {
            return new Date(timestamp * 1000).toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' });
        },
        openModal(){
            this.show = true;
            document.body.classList.add('noscroll');
        },
        closeModal(){
            this.show = false;
            document.body.classList.remove('noscroll')
        },
        openPaidConfirm(){
            this.showdialog = true
            this.showclose = false
        },
        closePaidConfirm(){
            this.showdialog = false
            this.showclose = true
        },
        async paid(res)
        {
            if(res == true){ 
                this.loadingdeal = true
                await models.setPaid(this.$store.getters.StateToken, this.sale.stoken).then(res => {     
                    const toast = useToast();  
                    if(res.data['message'] == "success"){
                        this.carpaid = true
                        toast.open({
                            message: 'Fahrzeug bezahlt!',
                            type: 'success',
                            position: 'top-right'
                        });
                        this.$emit('paid', true);                        
                    }else{
                        toast.open({
                            message: 'Es ist ein Fehler passiert!',
                            type: 'error',
                            position: 'top-right'
                        });
                    }
                    this.loadingdeal = false
                }).catch(err => {
                    this.carpaid = false
                    this.loadingdeal = false
                    console.log(err);
                });
            }
        },
        openDealDone(){
            this.showDealDialog = true
            this.showCloseDeal = false
        },
        closeDealDone(){
            this.showDealDialog = false
            this.showCloseDeal = true
        },
        async setDealDone(res)
        {
            if(res[0] == true){
                this.loadingdeal = true;                
                await models.closeDeal(this.$store.getters.StateToken, this.sale.stoken, res[1]).then(resr => {                    
                    const toast = useToast();
                    if(resr.data['message'] == "success")
                    {
                        this.dealdone = true;
                        toast.open({
                            message: 'Geschäft abgeschloßen!',
                            type: 'success',
                            position: 'top-right'
                        });
                        this.$emit('storno', true);
                    } else {
                        toast.open({
                            message: 'Es ist ein Fehler passiert!',
                            type: 'error',
                            position: 'top-right'
                        });
                    }                 
                    this.loadingdeal = false;
                }).catch(err => {      
                    this.loadingdeal = false;
                    console.log(err);                    
                });
            }
        },
        openStorno(){
            this.showStornoDialog = true
            this.showCloseStorno = false
        },
        closeStorno(){
            this.showStornoDialog = false
            this.showCloseStorno = true
        },
        async setStorno(res)
        {
            if(res == true){
                this.loadingdeal = true;                
                await models.cancelDeal(this.$store.getters.StateToken, this.sale.stoken).then(resr => {                    
                    const toast = useToast();
                    if(resr.data['message'] == "success")
                    {
                        this.dealdone = true;
                        toast.open({
                            message: 'Geschäft Storniert!',
                            type: 'error',
                            position: 'top-right'
                        });
                        this.$emit('storno', true);
                        this.closeModal()
                    } else {
                        toast.open({
                            message: 'Es ist ein Fehler passiert!',
                            type: 'error',
                            position: 'top-right'
                        });
                    }                 
                    this.loadingdeal = false;
                }).catch(err => {      
                    this.loadingdeal = false;
                    console.log(err);                    
                });
            }
        },
    }
}
</script>

<style>

.close{
    position: fixed;
    display: block;
    width: 150px;
    height: 150px;
    top: 10px;
    right: 20px;
    cursor: pointer;
    z-index: 8889;
}

.badge{
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;
}

.paid{
    background-color: #187c0b;    
}
.notpaid{
    background-color: #f2ab46;
    cursor: pointer;
}

.detailsBtn{
    display: block;
    width: 100%;
    cursor: pointer; 
    margin-top: 10px; 
    color:#454545; 
    text-decoration: none; 
    font-size: 16px;
    font-weight: 600;
    padding: 6px 20px 6px 20px;
    border: 1px solid #dedede;
    border-radius: 15px;
}
.detailsBtn:hover {
    color: #000;
    background-color: #dedede;
}

.btn-warning{
    background-color: #f2ab46;
    padding-left: 40px;
    padding-right: 40px;
    color: #fff;
}
</style>
<style scoped src="../assets/css/car.css"></style>