<template>
    <div class="row bg-white border mt-4" style="padding:0px;">           

        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4" style="position: relative;">    
            <div class="stepper d-flex flex-column mt-2 ml-2 mb-2">                           
                <div class="d-flex mt-2 mb-1">
                    <div class="d-flex flex-column pr-4 align-items-center">
                        <div class="rounded-circle py-2 bg-sgp1 text-white mb-1" style="padding-left: 12px;padding-right: 12px;"><img style="width: 20px;height:20px;" src="../../assets/icons/map-marker-white.svg" alt=""></div>
                        <div class="line h-100"></div>
                    </div>
                    <div>
                        <h5 class="text-dark text-truncate">{{data.pickup_city}}, {{data.pickup_country}}-{{data.pickup_plz}}</h5> 
                        <p class="lead text-muted pb-3"></p>                   
                    </div>
                </div>

                <div class="d-flex mb-1">
                    <div class="d-flex flex-column pr-4 align-items-center">
                        <div class="rounded-circle py-2 bg-sgp2 text-white mb-1" style="padding-left: 12px;padding-right: 12px;"><img style="width: 20px;height:20px;" src="../../assets/icons/map-marker-white.svg" alt=""></div>
                        <div class="line h-100"></div>
                    </div>
                    <div>
                        <h5 class="text-dark text-truncate">{{data.delivery_city}}, {{data.delivery_country}}-{{data.delivery_plz}}</h5>                    
                    </div>
                </div>               
            </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5 mt-3">
            <div style="font-size: 15px;">               
                <h5 class="text-truncate">{{ data.car_title }}</h5>
                Entfernung: {{data.distance}} km<br>
                Preis: {{data.cost}} € (Netto)<br>
                Datum: {{formatDate(data.createtime)}}<br>
                Typ: <span v-if="data.transportype == 0">Schnellst möglich</span><span v-if="data.transportype == 1">Termintransport - {{ formatDateWithTime(data.transport_time) }} Uhr</span>
            </div>                                                
        </div>
        <div class="align-items-center align-content-center col-xs-12 col-sm-12 col-md-12 col-lg-3 border-left mt-1">    
            
            <div style="position:relative;width: 100%;">
                <div style="position:absolute; top: 5px; right:10px;">
                    <span v-if="data.premium == 1" style="color:green;font-size: 15px;">Premium</span>
                </div>
            </div>

            <div class="d-flex flex-column mt-3 text-center">                                                
                <br>
                <div :class="data.orderstatus == 3 ? 'completedorder' : data.orderstatus == 4 ? 'storno' : 'inprogress'" style="width:100%; font-size: 16px;color:#fff;border-radius:15px;padding-top:6px;padding-bottom:6px;">{{ status[data.orderstatus] }}</div>
                <div v-if="data.orderstatus != 4">
                    <LogisticDetails v-bind:data="data" />
                </div>
            </div>
        </div>              
    </div>
</template>

<script>


import LogisticDetails from '@/components/LogisticDetails'    

export default {
    name: 'LogisticItem',
    components: {
        LogisticDetails
    },
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },
    computed:{        
    },
    data() {
        return {                    
            show: false,            
            distance: 0,
            delivery: 0,
            status: [
                "In bearbeitung",
                "Termin gebucht",
                "In Auslieferung",
                "Abgeschlossen",
                "Storniert",
            ]
        }
    },     
    mounted () {  
        // console.log(this.data)        
    },
    methods: {  
        formatDate(str) {
            return new Date(str * 1000).toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' });
        },                                                       
        formatDateWithTime(str) {
            return new Date(str * 1000).toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' });
        }                                                       
    }
}

</script>


<style>

.line {
    width: 2px;
    background-color: lightgrey !important;
}
.lead {
    font-size: 1.1rem;
}

.text-dark{
    margin-top: 12px;
    margin-left: 10px;
    font-size: 18px;
    font-weight: 400;
}

.bg-sgp1{
    background-color: #187c0b;    
}
.bg-sgp2{
    background-color: #48B213;    
}
.inprogress{
    background-color: #f2ae46;
}
.completedorder{
    background-color: #48B213;
}
.storno{
    background-color: #e72b2b;
}

</style>