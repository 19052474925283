import createPersistedState from "vuex-persistedstate";
import auth from "./modules/auth";
import { createStore } from 'vuex';

export default createStore({
  state:{},
  mutations:{},
  actions:{},
  modules:{
    auth
  },
  plugins: [createPersistedState()],
});