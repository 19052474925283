<template>
    <div v-if="showdialog" class="dialogview" >
        <transition name="fade" class="loading">
            <div>                
                <div class="dialogbox">                    
                    <h5><b>{{ title }}</b></h5>                       
                    <p style="font-size: 16px;margin-top: 15px;">
                        Schließen Sie das Geschäft ab.
                        <span v-if="autocontract != 0 || delivery != 0"><br>Unterschreiben Sie Kaufvertrag und/oder Vollmacht.</span>
                    </p>
                    <div v-if="autocontract != 0 || delivery != 0">
                        <small style="font-size: 12px;">Ihre Unterschrift:</small>
                        <div style="margin-left: 65px;">
                            <VueSignaturePad 
                                width="450px"
                                height="150px"
                                id="signature"                                                        
                                ref="signaturePad"                                                        
                            />                                                
                        </div>
                        <div>
                            <a @click="deleteSignatur()" class="text-danger">Zurücksetzen</a>
                        </div>
                    </div>
                    <br>
                    
                    <span style="margin-left:20px;"><a @click="closeModal(true)" :class="'btn btn-lg btn-block btn-'+btnclass" style="width:450px">{{ btnname }}</a></span>
                    <br><a @click="closeModal(false)" class="btn btn-md btn-text mt-2">Abbrechen</a>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { VueSignaturePad } from 'vue-signature-pad';

export default {
    name: 'DialogDealView',
    props: ['showdialog', 'autocontract', 'delivery', 'title', 'text', 'btnname', 'btnclass'],    
    data() {
        return {
            signaturRef: "",
            signatur: ""

        }
    },
    methods: {
        closeModal(answer) {
            if(this.$refs.signaturePad != undefined){
                this.signaturRef = this.$refs.signaturePad.saveSignature().data;
                if(this.signaturRef != undefined){
                    this.signatur = this.signaturRef.split('data:image/png;base64,')[1];
                }
            }
            this.$emit('closedialog', false);
            this.$emit('answer', [answer, this.signatur]);            
        },  
        deleteSignatur(){
            this.$refs.signaturePad.clearSignature();
        },    
    },
    components: {
        VueSignaturePad
    },
    mounted() {

    }
}
</script>

<style scoped>
.dialogview{
    display: flex;
    margin: auto;
    justify-content: center;
    text-align: center;
    z-index: 99999999;
}

.dialogbox{
    margin: auto;
    margin-top: 10%;
    width: 650px;
    height: auto;
    background-color: #fff;
    border-radius: 15px;
    padding: 45px;
}
.loading {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.7);
	z-index: 99999;
    padding-top: 0px;
}
</style>