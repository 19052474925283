<template>
  <div id="app">       
    <div v-if="mobile == true"><Mobile/></div>
    <div v-if="mobile == false" ><router-view/></div>
    <div v-if="blocked == true"><BlockedView v-bind:blockedshow="this.blocked" /></div>
  </div>  
</template>

<script>
  import Mobile from '@/components/Mobile'
  import BlockedView from '@/components/BlockedView'

  export default {
    name: 'App',
    components: {       
      Mobile,
      BlockedView
    },
    data() {
      return {
        mobile: false,
        blocked: false,
      };
    },
    computed: {
      currentUser() {
        return this.$store.state.currentUser
      }
    },
    mounted() {
      // console.log(navigator.userAgent);
      if(this.$store.getters.StateUser != null){
        if(this.$store.getters.StateUser['zugang'] == '0'){
          this.blocked = true;
        }
      }
      window.addEventListener('resize', () => {
        if(window.innerWidth < 767) {
          this.mobile = true;
        }else{
          this.mobile = false;
        }
      })
    },    
    
  }
</script>

<style scoped src="./assets/css/app.css"></style>