<template>
    <div id="mobile" class="mx-auto text-center">   
        <br><br>
        <img src="@/assets/sgp-app-hand.png" height="300px" alt="">
        <br><br><br>
        <h1>SehrGuterPreis<br>Die Autohändler App</h1>
        <p>Kaufen Sie Fahzeuge von überall aus.<br>
        Unsere App ist verfügbar für Android und iOS.<br>
        <strong>Jetzt Downloaden</strong></p> 
        <br>
        <span style="margin-right:20px;"><a href=""><img src="@/assets/app-store-button.png" alt=""></a></span>       
        <span><a href=""><img src="@/assets/google-play-button.png" alt=""></a></span>       
    </div>
</template>

<script>
    export default {
        name: 'MobileView',
    }
</script>

<style>

</style>