

const makedata = [  
    {
        make: "Audi",
        makeid: "1900",
        logo: "assets/logos/audi.png",
    },
    {
        make: "BMW",
        makeid: "3500",
        logo: "assets/logos/bmw.png",
    },
    {
        make: "Mercedes-Benz",
        makeid: "17200",
        logo: "assets/logos/mercedesbenz.png",
    },
    {
        make: "Volkswagen",
        makeid: "25200",
        logo: "assets/logos/vw.png",
    },
    {
        make: "Porsche",
        makeid: "20100",
        logo: "assets/logos/porsche.png",
    },
    {
        make: "Tesla",
        makeid: "135",
        logo: "assets/logos/tesla.png",
    },
    {
        make: "Suzuki",
        makeid: "23600",
        logo: "assets/logos/suzuki.png",
    },
    {
        make: "Dacia",
        makeid: "6600",
        logo: "assets/logos/dacia.png",
    },
    {
        make: "Renault",
        makeid: "20700",
        logo: "assets/logos/renault.png",
    },
    {
        make: "MINI",
        makeid: "17500",
        logo: "assets/logos/mini.png",
    },
    {
        make: "Fiat",
        makeid: "8800",
        logo: "assets/logos/fiat.png",
    },
    {
        make: "Alfa Romeo",
        makeid: "900",
        logo: "assets/logos/alfaromeo.png",
    },
    {
        make: "Citroen",
        makeid: "5900",
        logo: "assets/logos/citroen.png",
    },
    {
        make: "Ford",
        makeid: "9000",
        logo: "assets/logos/ford.png",
    },
    {
        make: "Honda",
        makeid: "11000",
        logo: "assets/logos/honda.png",
    },
    {
        make: "Kia",
        makeid: "13200",
        logo: "assets/logos/kia.png",
    },
    {
        make: "Mazda",
        makeid: "16800",
        logo: "assets/logos/mazda.png",
    },
    {
        make: "Mitsubishi",
        makeid: "17700",
        logo: "assets/logos/mitsubishi.png",
    },
    {
        make: "Nissan",
        makeid: "18700",
        logo: "assets/logos/nissan.png",
    },
    {
        make: "Opel",
        makeid: "19000",
        logo: "assets/logos/opel.png",
    },
    {
        make: "Peugeot",
        makeid: "19300",
        logo: "assets/logos/peugeot.png",
    },
    {
        make: "Skoda",
        makeid: "22900",
        logo: "assets/logos/skoda.png",
    },
    {
        make: "Hyundai",
        makeid: "11600",
        logo: "assets/logos/hyundai.png",
    },
    {
        make: "Seat",
        makeid: "22500",
        logo: "assets/logos/seat.png",
    },
    {
        make: "Toyota",
        makeid: "24100",
        logo: "assets/logos/toyota.png",
    },
    {
        make: "Volvo",
        makeid: "25100",
        logo: "assets/logos/volvo.png",
    },
    {
        make: "Chevrolet",
        makeid: "5600",
        logo: "assets/logos/chevrolet.png",    
    },
    {
        make: "Cupra",
        makeid: "3",
        logo: "assets/logos/cupra.png",
    },
    {
        make: "Jeep",
        makeid: "12600",
        logo: "assets/logos/jeep.png",
    },
    {
        make: "Jaguar",
        makeid: "12400",
        logo: "assets/logos/jaguar.png",
    },
    {
        make: "Land Rover",
        makeid: "14800",
        logo: "assets/logos/land-rover.png",
    },
    {
        make: "Bugatti",
        makeid: "4350",
        logo: "assets/logos/volvo.png",
    },
    {
        make: "Bentley",
        makeid: "3100",
        logo: "assets/logos/bugatti.png",
    },
    {
        make: "Maserati",
        makeid: "16600",
        logo: "assets/logos/maserati.png",
    },
    {
        make: "Ferrari",
        makeid: "8600",
        logo: "assets/logos/ferrari.png",
    },
    {
        make: "Lamborghini",
        makeid: "14600",
        logo: "assets/logos/lamborghini.png",
    }
]; 

export default {
    name: 'MakeData',
    makedata
};