import axios from "axios";
var md5 = require('md5');


const state = {
  user: null,
  useremail: null,
  token: null
};

const getters = {
  isAuthenticated: (state) => !!state.user,
  StateUser: (state) => state.user,
  StateUserEmail: (state) => state.useremail,  
  StateToken: (state) => state.token,  
};

const actions = {
  async Register({dispatch}, form) {
    await axios.post('register', form)
    let UserForm = new FormData()
    UserForm.append('user', form.username)
    UserForm.append('pass', form.password)
    await dispatch('LogIn', UserForm)
  },

  async LogIn({commit}, user) { 
    const body = { user: user.get("user"), pass: md5(user.get("pass")), device: 'web' };              
    return await axios.post('https://www.sehrguterpreis.de/api/4pi2.php?api=login&token=yHhMWa5rY3KcT4myn2m4NUEY1nJL1ct98OpyAORbIbI9Z5S8QBVNpLUQA0BSJ3FYLXVRT4fPA5T8y', 
      JSON.parse(JSON.stringify(body)), 
      { headers: { 
          'Content-Type': 'application/json'
        } 
      }
    ).then(async res => {      
      if(res.data['message'] == "success"){
        await commit("setUser", res.data['user']);
        await commit("setUserEmail", res.data['user']['email']);
        await commit("setToken", res.data['user']['token']);
      }
      return res.data
    })
    .catch(err => {  
      console.log(err)
      return {"message":"Error: Verbindungsproblem"}
    });       
  },

  async LogOut({ commit }) {
    let user = null;
    commit("logout", user);
  },
};

const mutations = {
  setUser(state, username) {
    state.user = username;
  },
  setUserEmail(state, useremail) {
    state.useremail = useremail;
  },
  setToken(state, token) {
    state.token = token;
  },
  logout(state, user) {
    state.user = user;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
