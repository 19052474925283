<template>
    <div v-if="show">
            <transition name="modal" id="modal-template">
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-container">                                        
                            <div class="modal-body text-center">
                                <h5><b>Ihr Ankaufspreis</b></h5>   
                                <p style="font-size: 14px;margin-top:20px;">für: {{ this.data.makename }} {{ this.data.modelname }}<br>
                                    Preis Verkäufer: {{ this.$filters.toCurrency(this.data.price) }} €</p>
                                <input type="text" @keypress="isNumber($event)" id="wishprice" placeholder="Ihr Ankaufspreis (Optional)" class="form-control" style="height:60px;">
                                <br>
                                <div v-if="loading == false">
                                    <button class="btn btn-lg btn-success btn-block" style="width: 100%;" @click="sendMessage()">Jetzt Anfragen</button>
                                </div> 
                                <div v-else>
                                    <button class="btn btn-lg btn-success btn-block" style="width: 100%;" disabled>Jetzt Anfragen</button>
                                </div>
                                <br><br>
                                <a class="text-danger" style="cursor: pointer;text-decoration: none;" @click="closeModal()">Abbrechen</a>                    
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
        <div v-if="this.msgsend == '0'">
            <button @click="enterWishPrice()" class="btn btn-success btn-sm mt-3 btn-round" style="width:100%;border-radius: 15px; padding-top:6px; padding-bottom:6px; font-size: 16px;" type="button">
                <img src="@/assets/icons/envelope-white.svg" style="width: 16px;position: relative;margin-top: -3px;margin-right:5px;"> Anfragen
            </button>
        </div>
        <div v-else>
            <button class="btn btn-default btn-sm mt-3 btn-round" disabled style="width:100%;border-radius: 15px; padding-top:6px; padding-bottom:6px; font-size: 16px;" type="button">
                <img src="@/assets/icons/envelope-grey.svg" style="width: 16px;position: relative;margin-top: -3px;margin-right:5px;"> Anfrage erhalten
            </button>
        </div>
</template>

<script>
import { useToast } from 'vue-toast-notification'
import models from "../models";

export default {
    name: 'SendMessageBtn',
    props: {
        data: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            wishprice: 0,
            msgsend: this.data.angefragt,
            show: false,
            loading: false
        }
    },
    methods: {  
        async sendMessage() {   
            this.loading = true;                     
            this.wishprice = document.getElementById('wishprice').value;
            if(this.wishprice == ''){ this.wishprice = 0; }
            await models.sendMessages(this.$store.getters.StateToken, this.data.mid, this.wishprice).then(res => { 
                if(res.data.message == 'success'){                             
                    const toast = useToast();  
                    toast.open({
                        message: 'Wir haben Ihre Anfrage erhalten!',
                        type: 'success',
                        position: 'top-right',
                    });
                    this.loading = false;                    
                    this.show = false;     
                    this.msgsend = "1";       
                    document.body.classList.remove('noscroll')       
                }else{
                    this.loading = false;
                    const toast = useToast();
                    toast.open({
                        message: 'Es ist ein Fehler aufgetreten!',
                        type: 'error',
                        position: 'top-right',
                    });                    
                }
            }).catch(() => {
                this.show = false;
            });            
        },
        closeModal(){
            document.body.classList.remove('noscroll')
            this.show = false;
        },
        enterWishPrice(){
            document.body.classList.add('noscroll')
            this.show = true;
        },
        isNumber(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
                evt.preventDefault();
            } else {
                return true;
            }
        }
    }
}

</script>