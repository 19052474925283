
<template>    
    <div class="row bg-white border mt-4" style="padding:0px;">                  
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3" style="padding:15px;position: relative; text-align:center;">                    
            <div v-if="car.image != ''">
                <img :src="car.image" @error="setAltImg" class="img-responsive" style="cursor:default;width: 100px; height: 100px; object-fit: cover;border-radius:50px;margin-top:0px;">
            </div>
            <div v-else>
                <img src="../assets/nopic2.png" class="img-responsive" style="cursor:default;width: 100px; height: 100px; object-fit: cover;border-radius:50px;margin-top:0px;">
            </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 mt-3">
            
            <h5 class="text-truncate">{{ car.title }}</h5>
            
            <div class="mt-2" style="font-size: 15px;">
                {{ car.ez }} - {{ this.$filters.toCurrency(car.km) }} km<br>                                  
                {{ car.full }} - {{ car.getriebe }}  
                <CarDetailView v-bind:data="car" v-bind:type="'chat'" />              
            </div>                                                                                  
        </div>
        <div class="align-items-center align-content-center col-xs-12 col-sm-12 col-md-12 col-lg-3 border-left mt-1">       
            
            <div style="position:relative;width: 100%;">
                <div style="position:absolute; top: 48px; right:10px;">
                    <!--  -->

                    <img @click="openDeleteConfirm" src="../assets/icons/trash-can-red.svg" height="20" style="cursor: pointer;">

                    <Dialog 
                        v-bind:showdialog="this.showdialog" 
                        v-bind:title="'Nachricht löschen?'" 
                        v-bind:text="'Wollen Sie die Nachricht wirklich löschen?'" 
                        v-bind:btnname="'Löschen'" 
                        v-bind:btnclass="'danger'" 
                        @closedialog="(i) => closeDeleteConfirm(i)"
                        @answer="(i) => deleteChat(i)"
                    />
                </div>
            </div>

            <div class="d-flex flex-column text-center">  
                <div style="margin-top: 43px;">
                    <ChatMessageView v-bind:data="car" v-bind:ctoken="data.ctoken" @close="() => reload()" />
                </div>
            </div>
        </div>              
    </div>
</template>

<script>
import models from "../models";
import imgdefault from '@/assets/nopic2.png'
import CarDetailView from '@/components/CarDetailView'    
import Dialog from '@/components/Dialog'    
import ChatMessageView from '@/components/ChatMessageView'    

export default {
    name: 'ChatListItem',
    components: {
        CarDetailView,
        Dialog,
        ChatMessageView
    },
    props: {
        data: {
            type: Object,
            default: () => {}
        },        
        car: {
            type: Object,
            default: () => {}
        }, 
        close: {
            type: String,
            default: () => ''
        },       
    },
    data() {
        return {                    
            showdialog: false,
        }
    },     
    mounted () {  
        
    },
    methods: {    
        setAltImg(event) { 
            event.target.src = imgdefault;
        },          
        openDeleteConfirm(){
            this.showdialog = true;
        },
        closeDeleteConfirm(){
            this.showdialog = false;
        },
        async deleteChat(i){
            if(i == true){
                console.log('delete');
                await models.deleteChatMessage(this.data.ctoken).then(() => {                    
                    this.performingRequest = false;
                    this.$emit('close', '');
                }).catch(err => {      
                    this.performingRequest = false;
                    console.log(err);
                });
            }
        },
        reload(){
            console.log("reload");
            this.$emit('close', '');
        }
    }
}

</script>


<style scoped src="../assets/css/car.css"></style>
<!-- <style scoped>

.detailsBtn{
    border: none !important;
    font-size: 14px !important;
    padding: 0px !important;
    margin-bottom: 10px !important;
    margin-top: 0px !important;
}

.detailsBtn:hover{    
    background-color: transparent !important;
    color: green !important;
}

</style> -->