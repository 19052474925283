<template>
    <div v-if="showdqr == true" class="dialogview" >
        <transition name="fade" class="loading">
            <div>                
                <div class="dialogbox">        
                    <h3>{{text}}</h3>            
                    <img :src='"https://www.sehrguterpreis.de/api/qr/qr-img.php?d=tel:"+text+"&s=12"' alt="">
                    <br><br>
                    <span @click="closeModal()" class="text-danger"><b>Schließen</b></span>
                </div>
            </div>
        </transition>        
    </div>
    <a @click="openModal()" style="cursor: pointer;">{{text}}</a>
</template>

<script>
export default {
    name: 'QrCode',
    props: ['text'],   
    data() {
        return {
            showdqr: false
        }
    },
    methods: {
        closeModal() {
            this.showdqr = false;
        },  
        openModal() {
            this.showdqr = true;
        },      
    },
    mounted() {
        
    }
}
</script>

<style scoped>
.dialogview{
    display: flex;
    margin: auto;
    justify-content: center;
    text-align: center;
    z-index: 99999;
}

.dialogbox{
    margin: auto;
    margin-top: 10%;
    width: 500px;
    height: auto;
    background-color: #fff;
    border-radius: 15px;
    padding: 45px;
}
.loading {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.7);
	z-index: 99999;
    padding-top: 0px;
}
</style>