<template>
    <div v-if="show">
        <div @click="closeModal()" class="close">
            <img src="@/assets/icons/times.svg" alt="">
        </div>
        <transition name="modal" id="modal-template">                
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">                                        
                        <div class="modal-body text-center">
                            <h5><b>Ihr Gegenangebot</b></h5>
                            <div style="font-size: 16px;margin-top:50px;margin-bottom:50px;">
                                <span style="font-size: 18px;">Inseratspreis: {{ this.$filters.toCurrency(JSON.parse(this.data.cardata).price) }} €</span><br><br>
                                <span class="text-danger"><b>- {{ this.$filters.toCurrency(getDiff(JSON.parse(this.data.cardata).price, this.data.gegenangebot)) }} €</b></span><br><br>
                                <h1 style="color:#187c0b"><b>{{ this.$filters.toCurrency(this.data.gegenangebot) }} €</b></h1>
                            </div>
                            <div v-if="loading == false">
                                <span>
                                    <button class="btn btn-lg btn-success btn-block" style="width: 45%;" @click="acceptOffer()">
                                        <img src="@/assets/icons/checkmark-white.svg" style="width: 36px;">
                                    </button>
                                </span>
                                <span style="margin-left: 20px;">
                                    <button class="btn btn-lg btn-danger btn-block" style="width: 45%;" @click="declineOffer()">
                                        <img src="@/assets/icons/times.svg" style="width: 36px;">
                                    </button>
                                </span>
                            </div> 
                            <div v-else>                                
                                <img src="@/assets/bx_loader.gif" alt="">
                                <br><br>
                            </div>
                                                                        
                        </div>
                    </div>
                </div>
            </div>
        </transition>
        </div>
        <div @click="openCounterOffer()" style="cursor:pointer;width:100%; background-color: #187c0b;color:#fff;border-radius:15px;padding-top:6px;padding-bottom:6px;">Gegenangebot</div>
</template>

<script>
import { useToast } from 'vue-toast-notification'
import models from "../models";

export default {
    name: 'CounterOfferView',
    props: {
        data: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            wishprice: 0,
            msgsend: this.data.angefragt,
            show: false,
            loading: false
        }
    },
    methods: {  
        getDiff: function (value1, value2) {
            var val = (Number(value1) - Number(value2));
            return val;
        },
        async acceptOffer() {   
            this.loading = true;
            await models.acceptCounterOffer(this.$store.getters.StateToken, this.data.mtoken, this.data.gegenangebot).then(res => { 
                console.log(res.data)
                if(res.data.message == 'success'){                             
                    const toast = useToast();  
                    toast.open({
                        message: 'Wir leiten Ihe Anwort an den verkäufer!',
                        type: 'success',
                        position: 'top-right',
                    });
                    this.loading = false;                    
                    this.show = false;     
                    this.msgsend = "1";
                    this.$emit('status', 0)
                    document.body.classList.remove('noscroll')
                }else{
                    this.loading = false;
                    const toast = useToast();
                    toast.open({
                        message: 'Es ist ein Fehler aufgetreten!',
                        type: 'error',
                        position: 'top-right',
                    });
                }
            }).catch(() => {
                this.show = false;
            });            
        },
        async declineOffer(){
            this.loading = true;
            await models.declineCounterOffer(this.$store.getters.StateToken, this.data.mtoken, this.data.gegenangebot).then(res => { 
                if(res.data.message == 'success'){                             
                    const toast = useToast();  
                    toast.open({
                        message: 'Wir leiten Ihe Anwort an den verkäufer!',
                        type: 'success',
                        position: 'top-right',
                    });
                    this.loading = false;                    
                    this.show = false;                         
                    this.msgsend = "1";   
                    this.$emit('status', 6)
                    document.body.classList.remove('noscroll')           
                }else{
                    this.loading = false;
                    const toast = useToast();
                    toast.open({
                        message: 'Es ist ein Fehler aufgetreten!',
                        type: 'error',
                        position: 'top-right',
                    });
                }
            }).catch(() => {
                this.show = false;
            });   
        },
        closeModal(){
            document.body.classList.remove('noscroll')
            this.show = false;
        },
        openCounterOffer(){
            document.body.classList.add('noscroll')
            this.show = true;
        },        
    }
}

</script>