<template>
    <div id="search">
        <Loading v-bind:loading="performingRequest"/>
        <div class="sidebar"><Sidebar/></div>
        <div class="body-text">

            <div class="container-fluid pagepadding">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1 mx-auto" style="padding-left: 10px;">
                    <h1><span class="iconfloat-title"><img src="../assets/icons/search-alt.svg" style="height:24px; width:24px;"></span>  {{ title }}</h1>    
                    <hr>      
                    </div>        
                </div> 
            </div> 

            <div class="container pagepadding">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1 mx-auto">

                        <div class="row">
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 mx-auto">
                                <select @change="onChangeMake($event)" class="form-control" name="makes">                                    
                                    <option value="">Alle Marken</option>
                                    <option v-for="make in this.data.makedata" :key="make.makeid"  :value="make.makeid">{{ make.make }}</option>
                                </select>
                            </div>
                            
                            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mx-auto">
                                <select class="form-control" disabled="true" @change=" onChangeModel($event)" name="models">
                                    <option value="">Alle Modelle</option>
                                    <option v-for="model in this.modelData.models" :key="model.n"  :value="model.i">{{ model.n }}</option>
                                </select>
                            </div>
                        </div>
                        
                        <div class="row mt-4">
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 mx-auto">
                                <select class="form-control" @change=" onChangePrice($event)" name="price">
                                    <option value="">Alle Preise</option>                                    
                                    <option value="5000">bis 5.000</option>
                                    <option value="10000">bis 10.000</option>
                                    <option value="15000">bis 15.000</option>
                                    <option value="20000">bis 20.000</option>
                                    <option value="25000">bis 25.000</option>
                                    <option value="30000">bis 30.000</option>
                                    <option value="40000">bis 40.000</option>
                                    <option value="45000">bis 45.000</option>
                                    <option value="50000">bis 50.000</option>
                                    <option value="60000">bis 60.000</option>
                                    <option value="70000">bis 70.000</option>
                                    <option value="80000">bis 80.000</option>
                                    <option value="90000">bis 90.000</option>
                                    <option value="100000">bis 100.000</option>
                                    <option value="150000">bis 150.000</option>
                                    <option value="200000">bis 200.000</option>
                                    <option value="250000">bis 250.000</option>
                                    <option value="300000">bis 300.000</option>
                                    <option value="400000">bis 400.000</option>
                                    <option value="500000">bis 500.000</option>
                                </select>
                            </div>
                            
                            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mx-auto">
                                <select class="form-control" @change=" onChangeMileage($event)" name="km">
                                    <option value="">Alle Kilometer</option>                                    
                                    <option value="125000">bis 125.000</option>
                                    <option value="100000">bis 100.000</option>
                                    <option value="90000">bis 90.000</option>
                                    <option value="80000">bis 80.000</option>
                                    <option value="70000">bis 70.000</option>
                                    <option value="60000">bis 60.000</option>
                                    <option value="50000">bis 50.000</option>
                                    <option value="40000">bis 40.000</option>
                                    <option value="30000">bis 30.000</option>
                                    <option value="20000">bis 20.000</option>
                                    <option value="10000">bis 10.000</option>
                                </select>
                            </div>
                        </div>
                        
                        <div class="row mt-4">
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 mx-auto">
                                <select class="form-control" @change=" onChangeFirstRegistration($event)" name="ez">
                                    <option value="">Alle Erstzulassung</option>
                                    <option value="2023">ab 2023</option>
                                    <option value="2022">ab 2022</option>
                                    <option value="2021">ab 2021</option>
                                    <option value="2020">ab 2020</option>
                                    <option value="2019">ab 2019</option>
                                    <option value="2018">ab 2018</option>
                                    <option value="2017">ab 2017</option>
                                    <option value="2016">ab 2016</option>
                                    <option value="2015">ab 2015</option>
                                    <option value="2014">ab 2014</option>
                                    <option value="2013">ab 2013</option>
                                    <option value="2012">ab 2012</option>
                                </select>
                            </div>
                            
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 mx-auto">
                                <select class="form-control" @change=" onChangeFuel($event)" name="fuel">
                                    <option value="">Alle Kraftstoffe</option>                                    
                                    <option value="Benzin">Benzin</option>
                                    <option value="Diesel">Diesel</option>
                                    <option value="Elektro">Elektro</option>
                                    <option value="Erdgas (CNG)">Erdgas (CNG)</option>
                                    <option value="Autogas (LPG)">Autogas (LPG)</option>
                                    <option value="Ethanol (FFV, E85 etc.)">Ethanol (FFV, E85 etc.)</option>
                                    <option value="Hybrid (Diesel/Elektro)">Hybrid (Diesel/Elektro)</option>
                                    <option value="Hybrid (Benzin/Elektro)">Hybrid (Benzin/Elektro)</option>
                                    <option value="Hybrid (Benzin/Elektro), E10-geeignet">Hybrid (Benzin/Elektro), E10-geeignet</option>
                                </select>
                            </div>
                        </div>

                        <div class="row mt-4">                            
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 mx-auto">
                                <select class="form-control" @change=" onChangeGear($event)" name="gear">
                                    <option value="">Alle Getriebearten</option>
                                    <option value="Automatik">Automatik</option>
                                    <option value="Halbautomatik">Halbautomatik</option>
                                    <option value="Schaltgetriebe">Schaltgetriebe</option>
                                </select>
                            </div>  
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 mx-auto">
                                <select class="form-control" @change=" onChangeMwSt($event)" name="mwst">
                                    <option value="">Alle MwSt.</option>
                                    <option value="0">Ohne MwSt.</option>
                                    <option value="19">Inkl. MwSt.</option>
                                </select>
                            </div>  
                        </div>

                        <!-- <div class="row mt-4">
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-2 mx-auto" style="padding-top:5px;">
                                <label><input type="checkbox" :value="true" @change="onChangeUmkreisActive($event)" name="umkreisactive"> Standort</label>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5 mx-auto">
                                

                                <div class="input-group">
                                    <input type="text" disabled="true" @change="onChangePLZ($event)" :value="this.searchplz" class="form-control" placeholder="PLZ" name="plz">
                                    <div class="input-group-append">
                                        <span @click="setPlZ()" class="input-group-text">
                                            <img src="../assets/icons/location.svg" height="25">
                                        </span>
                                    </div>
                                </div>

                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5 mx-auto">
                                <select class="form-control" disabled="true" @change="onChangeUmkreis($event)" name="umkreis">
                                    <option value="10">10 km</option>
                                    <option value="20">20 km</option>
                                    <option value="50">50 km</option>
                                    <option value="100">100 km</option>
                                    <option value="200">200 km</option>
                                    <option value="500">500 km</option>
                                </select>
                            </div>
                        </div> -->
                    
                        <div class="row mt-4">
                            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mx-auto">
                                <div style="padding-top:3px;">
                                    Sortierung:
                                </div>
                            </div>
                            <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10 mx-auto">                                
                                <select class="form-control"  @change=" onChangeSorting($event)" name="sort">
                                    <option value="new">Neusten Angebote</option>
                                    <option value="pricedown">Preis absteigend</option>
                                    <option value="priceup">Preis aufsteigend</option>
                                    <option value="datedown">Datum absteigend</option>
                                    <option value="dateup">Datum aufsteigend</option>
                                    <option value="kmdown">Kilometer absteigend</option>
                                    <option value="kmup">Kilometer aufsteigend</option>
                                    <option value="ezdown">Erstzulassung absteigend</option>
                                    <option value="ezup">Erstzulassung aufsteigend</option>
                                </select>
                            </div>
                        </div>

                        <div class="row mt-5">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mx-auto">                            
                                <button @click="search()" class="btn btn-success btn-lg btn-block" style="width: 100%;" type="submit">{{ count }} Fahrzeuge</button>                                
                            </div>
                        </div>
                    
                    </div>
                </div>
            </div>
        
        <Footer/>
        </div>        
    </div>            
</template>

<script>
import models from "../models";
import Sidebar from '@/components/Sidebar'
import Loading from '@/components/Loading'    
import Footer from '@/components/Footer'
import makes from '@/config/makes.js'

export default {
    name: 'SearchView',
    components: {
        Sidebar,
        Loading,
        Footer
    },
    data() {
        return {
            performingRequest: true,
            data: makes,
            modelData: [],
            marke: 'Alle',
            markeid: '',
            model: 'Alle',
            modelid: '',
            preis: 'Alle',
            preisvalue: '',
            kilometer: 'Alle',
            kilometervalue: '',
            kraftstoff: 'Alle',
            kraftstoffvalue: '',
            getriebe: 'Alle',
            getriebevalue: '',
            ez: 'Alle',
            ezvalue: '',
            sort: 'Neusten Angebote',
            sortvalue: 'new',
            count: "0",
            currentJsonFile: null,
            mwstvalue: "",
            mwst: "",
            umkreisactive: false,
            searchplz: '',
            distancevalue: 10,
            distance: ''
        }
    }, 
    computed: {    
        title: () => {
            return "Fahrzeug Suche"
        }
    },
    mounted() {
        this.performingRequest = false   
        this.countCars()
    }, 
    methods: {
        setPlZ(){
            this.searchplz = this.$store.getters.StateUser.zipcode;
            this.countCars()
        },
        getJsonFile (index) {
            this.modelData = require('../assets/carmodels/' + index + '.json')
        },
        async countCars() {
            await models.countCars(
                this.$store.getters.StateToken,
                this.markeid,
                this.modelid,
                this.preisvalue,
                this.kilometervalue,
                this.kraftstoffvalue,
                this.getriebevalue,
                this.ezvalue,
                this.sortvalue,
                this.mwstvalue,
                this.umkreisactive,
                this.searchplz,
                this.distancevalue
            ).then(res => {
                // console.log(res.data);
                this.count = res.data;              
            }).catch(err => {      
                console.log(err);
            });
        },
        async onChangeMake(event) {
            this.markeid = event.target.value
            this.marke = event.target.options[event.target.selectedIndex].text  
            if(event.target.value != ""){                                   
                this.modelid = ''
                this.model = 'Alle'    
                this.modelData = []        
                this.getJsonFile(this.markeid)
                document.getElementsByName("models")[0].disabled = false
                this.countCars()
            }else{
                this.modelid = ''
                this.model = 'Alle'
                this.modelData = []
                document.getElementsByName("models")[0].disabled = true
                this.countCars()
            }
        },
        onChangeModel(event) {            
            if(event.target.value != ""){
                this.modelid = event.target.value
                this.model = event.target.options[event.target.selectedIndex].text            
                this.countCars()
            }else {
                this.modelid = ''
                this.model = 'Alle'
                this.modelData = []
                this.countCars()
            }            
        },
        onChangePrice(event) {
            if(event.target.value != ""){
                this.preisvalue = event.target.value
                this.preis = event.target.options[event.target.selectedIndex].text      
                this.countCars()      
            }else {
                this.preisvalue = ''
                this.preis = 'Alle'
                this.countCars()   
            }
        },
        onChangeMileage(event) {
            if(event.target.value != ""){
                this.kilometervalue = event.target.value
                this.kilometer = event.target.options[event.target.selectedIndex].text            
                this.countCars()
            }else {
                this.kilometervalue = ''
                this.kilometer = 'Alle'
                this.countCars()   
            }
            this.countCars()
        },
        onChangeFirstRegistration(event) {
            if(event.target.value != ""){
                this.ezvalue = event.target.value
                this.ez = event.target.options[event.target.selectedIndex].text            
                this.countCars()   
            }else {
                this.ezvalue = ''
                this.ez = 'Alle'
                this.countCars()   
            }
        },
        onChangeFuel(event) {
            if(event.target.value != ""){
                this.kraftstoffvalue = event.target.value
                this.kraftstoff = event.target.options[event.target.selectedIndex].text            
                this.countCars()   
            }else {
                this.kraftstoffvalue = ''
                this.kraftstoff = 'Alle'
                this.countCars()   
            }
        },
        onChangeGear(event) {
            if(event.target.value != ""){
                this.getriebevalue = event.target.value
                this.getriebe = event.target.options[event.target.selectedIndex].text
                this.countCars()   
            }else {
                this.getriebevalue = ''
                this.getriebe = 'Alle'
                this.countCars()   
            }
        },
        onChangeSorting(event) {
            if(event.target.value != ""){
                this.sortvalue = event.target.value
                this.sort = event.target.options[event.target.selectedIndex].text            
                this.countCars()   
            }else {
                this.sortvalue = 'new'
                this.sort = 'Neusten Angebote'
                this.countCars()   
            }
        },
        onChangeUmkreisActive(){
            if(this.umkreisactive == false){
                this.umkreisactive = true;
                document.getElementsByName("plz")[0].disabled = false;
                document.getElementsByName("umkreis")[0].disabled = false;
                this.countCars();
            }else{
                this.umkreisactive = false;
                document.getElementsByName("plz")[0].disabled = true;
                document.getElementsByName("umkreis")[0].disabled = true;
                this.countCars();
            }
            
        },
        onChangeMwSt(event){            
            if(event.target.value != ""){
                this.mwstvalue = event.target.value
                this.mwst = event.target.options[event.target.selectedIndex].text
                this.countCars()   
            }else {
                this.mwstvalue = ''
                this.mwst = 'Alle'
                this.countCars()   
            }
        },
        onChangePLZ(event){
            this.searchplz = event.target.value;
            this.countCars();
        },
        onChangeUmkreis(event){            
            if(event.target.value != ""){
                this.distancevalue = event.target.value
                this.distance = event.target.options[event.target.selectedIndex].text
                this.countCars()   
            }else {
                this.distancevalue = ''
                this.distance = 'Alle'
                this.countCars()   
            }
        },
        search() {
            var body = {
                uid: this.$store.getters.StateToken,
                make: this.markeid,
                model: this.modelid,
                priceto: this.preisvalue,
                kmto: this.kilometervalue,
                fuel: this.kraftstoffvalue,
                gearbox: this.getriebevalue,
                firstreg: this.ezvalue,
                mwst: this.mwstvalue,
                umkreisactive: this.umkreisactive,
                searchplz: this.searchplz,
                distance: this.distancevalue,
                sort: this.sortvalue
            }
            this.$router.push({ name: 'SearchResults', state: { body }})             
        }
    }
}    
</script>

<style scoped src="../assets/css/app.css"></style>
<style>

</style>