<template>
    <div v-if="showdialog" class="dialogview" >
        <transition name="fade" class="loading">
            <div>                
                <div class="dialogbox">                    
                    <h5><b>{{ title }}</b></h5>                    
                    <p style="font-size: 16px;margin-top: 15px;">{{ text }}</p>                    
                    <br>
                    <span><a @click="closeModal(false)" class="btn btn-md btn-default">Abbrechen</a></span>
                    <span style="margin-left:20px;"><a @click="closeModal(true)" :class="'btn btn-md btn-'+btnclass">{{ btnname }}</a></span>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
// import models from "../models";
// import { useToast } from 'vue-toast-notification'

export default {
    name: 'BuyStationPauseDialogView',
    props: ['showdialog', 'title', 'text', 'btnname', 'btnclass'],    
    data() {
        return {
            feedback: ""
        }
    }, 
    methods: {
        closeModal(anwser) {            
            this.$emit('closedialog', false);
            this.$emit('answer', anwser);
        },        
    },
    mounted() {            
    }
}
</script>

<style scoped>
.dialogview{
    display: flex;
    margin: auto;
    justify-content: center;
    text-align: center;
    z-index: 99999;
}

.dialogbox{
    margin: auto;
    margin-top: 14%;
    width: 450px;
    height: auto;
    background-color: #fff;
    border-radius: 15px;
    padding: 45px;
}
.loading {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.7);
	z-index: 99999;
    padding-top: 0px;
}
</style>