import 'bootstrap/dist/css/bootstrap.css'
import 'vue-toast-notification/dist/theme-sugar.css';
import './assets/css/sys.css';

import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './router'
import axios from "axios";

axios.create({
  baseURL: "http://localhost:8080/",
  withCredentials: false,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Accept': "application/json",
  },
});

axios.interceptors.response.use(undefined, function(error) {
  if (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      store.dispatch("LogOut");
      return router.push("/login");
    }
  }
});

const app = createApp(App)

app.config.globalProperties.$filters = {  
  toCurrency(value) {      
    return Number(value).toLocaleString('de-DE')
  }
}

app.use(store)
app.use(router)
app.mount("#app");