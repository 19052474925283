<template>
    <div id="prices">
        <Loading v-bind:loading="performingRequest"/>
        <div class="sidebar"><Sidebar/></div>
        <div class="body-text">

            <div class="container-fluid pagepadding">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1 mx-auto" style="padding-left: 10px;">
                    <h1><span class="iconfloat-title"><img src="../assets/icons/calculator-alt.svg" style="height:24px; width:24px;"></span>  {{ title }}</h1>                     
                    <hr>          
                    </div>        
                </div> 
           
                <div class="row mt-5">                     
                    
                    <div class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1 mx-auto">

                        <p>Hier finden Sie einen Überblick über unsere aktuellen Preise für die Ankaufstation, 
                            SGP-Service und unsere Logistikleistungen. Bei uns erhalten Sie transparente Informationen über 
                            unsere Konditionen, um Ihnen eine klare Vorstellung von den Kosten für unsere Dienstleistungen zu vermitteln. 
                            Bitte nehmen Sie sich einen Moment Zeit, um unsere Preisübersicht zu durchstöbern, und zögern Sie nicht, 
                            uns bei weiteren Fragen oder für detailliertere Informationen zu kontaktieren. 
                            Wir stehen Ihnen gerne zur Verfügung, um Ihnen den bestmöglichen Service zu bieten.</p>

                        <br>
                        <hr>
                        <br>

                        <h3>SGP-Ankauf-Termin</h3>
                        <i><small style="font-size: 16px !important;">Preise sind in Netto</small></i>
                        <br><br>
                        <table width="100%">
                            <tr height="30" style="border-bottom: 1px solid #bbbbbb;">
                                <td width="50%">Monatliche Gebühr:</td>
                                <td style="text-align: right;" width="50%">0,00 €</td>
                            </tr>
                            <tr height="30" style="border-bottom: 1px solid #bbbbbb;">
                                <td width="150">Vermittlungsprovision:</td>
                                <td style="text-align: right;" width="50%">2 %</td>
                            </tr>
                            <tr height="30" style="border-bottom: 1px solid #bbbbbb;">
                                <td width="150">Ankauf Gebühr MIN.</td>
                                <td style="text-align: right;" width="50%">100,00 €</td>
                            </tr>
                            <tr height="30" style="border-bottom: 1px solid #bbbbbb;">
                                <td width="150">Ankauf Gebühr MAX.</td>
                                <td style="text-align: right;" width="50%">500,00 €</td>
                            </tr>                
                        </table>

                        <br>                        
                        <br>
                        <hr>
                        <br>


                        <h3>SGP-Service Preise</h3>
                        <i><small style="font-size: 16px !important;">Preise sind in Netto</small></i>
                        <br><br>
                        <table width="100%">
                            <tr height="30" style="border-bottom: 1px solid #bbbbbb;">
                                <td width="50%">Bis zu 7.000 €</td>
                                <td style="text-align: right;" width="50%">100,00 €</td>
                            </tr>
                            <tr height="30" style="border-bottom: 1px solid #bbbbbb;">
                                <td width="150">Bis zu 10.000 €</td>
                                <td style="text-align: right;" width="50%">100,00 €</td>
                            </tr>
                            <tr height="30" style="border-bottom: 1px solid #bbbbbb;">
                                <td width="150">Bis zu 15.000 €</td>
                                <td style="text-align: right;" width="50%">150,00 €</td>
                            </tr>
                            <tr height="30" style="border-bottom: 1px solid #bbbbbb;">
                                <td width="150">Bis zu 20.000 €</td>
                                <td style="text-align: right;" width="50%">200,00 €</td>
                            </tr>
                            <tr height="30" style="border-bottom: 1px solid #bbbbbb;">
                                <td width="150">Bis zu 25.000 €</td>
                                <td style="text-align: right;" width="50%">250,00 €</td>
                            </tr>
                            <tr height="30" style="border-bottom: 1px solid #bbbbbb;">
                                <td width="150">Bis zu 30.000 €</td>
                                <td style="text-align: right;" width="50%">300,00 €</td>
                            </tr>
                            <tr height="30" style="border-bottom: 1px solid #bbbbbb;">
                                <td width="150">Bis zu 35.000 €</td>
                                <td style="text-align: right;" width="50%">350,00 €</td>
                            </tr>
                            <tr height="30" style="border-bottom: 1px solid #bbbbbb;">
                                <td width="150">Bis zu 45.000 €</td>
                                <td style="text-align: right;" width="50%">400,00 €</td>
                            </tr>
                            <tr height="30" style="border-bottom: 1px solid #bbbbbb;">
                                <td width="150">Bis zu 60.000 €</td>
                                <td style="text-align: right;" width="50%">450,00 €</td>
                            </tr>
                            <tr height="30" style="border-bottom: 1px solid #bbbbbb;">
                                <td width="150">Bis zu 80.000 €</td>
                                <td style="text-align: right;" width="50%">500,00 €</td>
                            </tr>
                            <tr height="30" style="border-bottom: 1px solid #bbbbbb;">
                                <td width="150">Bis zu 150.000 €</td>
                                <td style="text-align: right;" width="50%">550,00 €</td>
                            </tr>
                            <tr height="30" style="border-bottom: 1px solid #bbbbbb;">
                                <td width="150">Bis zu 500.000 €</td>
                                <td style="text-align: right;" width="50%">600,00 €</td>
                            </tr>
                        </table>

                        <br>
                        <p>Unser Angebot sieht vor, dass wir den Kaufpreis mit dem Verkäufer verhandeln, um für Sie den bestmöglichen Deal zu sichern. Für diesen Service erheben wir eine Gebühr von 8% auf den Preisnachlass, den wir für Sie erreichen.</p>
                        
                        <br>
                        <hr>
                        <br>
                        
                        <h3>SGP-Logistik Preise</h3>
                        <i><small style="font-size: 16px !important;">Preise sind in Netto</small></i>
                        <br><br>
                        <table width="100%">
                            <tr height="30" style="border-bottom: 1px solid #bbbbbb;">
                                <td width="50%">Bis zu 100 km</td>
                                <td style="text-align: right;" width="50%">220,00 €</td>
                            </tr>
                            <tr height="30" style="border-bottom: 1px solid #bbbbbb;">
                                <td width="50%">Bis zu 200 km</td>
                                <td style="text-align: right;" width="50%">345,00 €</td>
                            </tr>
                            <tr height="30" style="border-bottom: 1px solid #bbbbbb;">
                                <td width="50%">Bis zu 300 km</td>
                                <td style="text-align: right;" width="50%">400,00 €</td>
                            </tr>
                            <tr height="30" style="border-bottom: 1px solid #bbbbbb;">
                                <td width="50%">Bis zu 400 km</td>
                                <td style="text-align: right;" width="50%">460,00 €</td>
                            </tr>
                            <tr height="30" style="border-bottom: 1px solid #bbbbbb;">
                                <td width="50%">Bis zu 500 km</td>
                                <td style="text-align: right;" width="50%">515,00 €</td>
                            </tr>
                            <tr height="30" style="border-bottom: 1px solid #bbbbbb;">
                                <td width="50%">Bis zu 600 km</td>
                                <td style="text-align: right;" width="50%">560,00 €</td>
                            </tr>
                            <tr height="30" style="border-bottom: 1px solid #bbbbbb;">
                                <td width="50%">Bis zu 700 km</td>
                                <td style="text-align: right;" width="50%">616,00 €</td>
                            </tr>
                            <tr height="30" style="border-bottom: 1px solid #bbbbbb;">
                                <td width="50%">Bis zu 800 km</td>
                                <td style="text-align: right;" width="50%">660,00 €</td>
                            </tr>
                            <tr height="30" style="border-bottom: 1px solid #bbbbbb;">
                                <td width="50%">Bis zu 1.000 km</td>
                                <td style="text-align: right;" width="50%">770,00 €</td>
                            </tr>
                            <tr height="30" style="border-bottom: 1px solid #bbbbbb;">
                                <td width="50%">Bis zu 1.200 km</td>
                                <td style="text-align: right;" width="50%">800,00 €</td>
                            </tr>
                            <tr height="30" style="border-bottom: 1px solid #bbbbbb;">
                                <td width="150">Bis zu 1.500 km</td>
                                <td style="text-align: right;" width="50%">1.100,00 €</td>
                            </tr>               
                        </table>


                        <br>
                        <br>
                        <hr>
                        <br>
                        
                        <h3>SGP-Premium-Logistik Preise</h3>
                        <i><small style="font-size: 16px !important;">Preise sind in Netto</small></i>
                        <br><br>
                        <table width="100%">
                            <tr height="30" style="border-bottom: 1px solid #bbbbbb;">
                                <td width="50%">Bis zu 100 km</td>
                                <td style="text-align: right;" width="50%">500,00 €</td>
                            </tr>
                            <tr height="30" style="border-bottom: 1px solid #bbbbbb;">
                                <td width="50%">Bis zu 200 km</td>
                                <td style="text-align: right;" width="50%">750,00 €</td>
                            </tr>
                            <tr height="30" style="border-bottom: 1px solid #bbbbbb;">
                                <td width="50%">Bis zu 300 km</td>
                                <td style="text-align: right;" width="50%">1.000,00 €</td>
                            </tr>
                            <tr height="30" style="border-bottom: 1px solid #bbbbbb;">
                                <td width="50%">Bis zu 400 km</td>
                                <td style="text-align: right;" width="50%">1.300,00 €</td>
                            </tr>
                            <tr height="30" style="border-bottom: 1px solid #bbbbbb;">
                                <td width="50%">Bis zu 500 km</td>
                                <td style="text-align: right;" width="50%">1.400,00 €</td>
                            </tr>
                            <tr height="30" style="border-bottom: 1px solid #bbbbbb;">
                                <td width="50%">Bis zu 600 km</td>
                                <td style="text-align: right;" width="50%">1.500,00 €</td>
                            </tr>
                            <tr height="30" style="border-bottom: 1px solid #bbbbbb;">
                                <td width="50%">Bis zu 700 km</td>
                                <td style="text-align: right;" width="50%">1.600,00 €</td>
                            </tr>
                            <tr height="30" style="border-bottom: 1px solid #bbbbbb;">
                                <td width="50%">Bis zu 800 km</td>
                                <td style="text-align: right;" width="50%">1.700,00 €</td>
                            </tr>
                            <tr height="30" style="border-bottom: 1px solid #bbbbbb;">
                                <td width="50%">Bis zu 1.000 km</td>
                                <td style="text-align: right;" width="50%">1.800,00 €</td>
                            </tr>
                            <tr height="30" style="border-bottom: 1px solid #bbbbbb;">
                                <td width="50%">Bis zu 1.200 km</td>
                                <td style="text-align: right;" width="50%">1.900,00 €</td>
                            </tr>
                            <tr height="30" style="border-bottom: 1px solid #bbbbbb;">
                                <td width="150">Bis zu 1.500 km</td>
                                <td style="text-align: right;" width="50%">2.300,00 €</td>
                            </tr>               
                        </table>

                        <br><br>
                        <br><br>
                        <br><br>

                    </div>                    
                </div>                    
            </div>

            
        
        <Footer/>
        </div>        
    </div>
</template>

<script>
    import Sidebar from '@/components/Sidebar'
    import Loading from '@/components/Loading'    
    import Footer from '@/components/Footer'
    export default {
        name: 'PricesView',
        components: {
            Sidebar,
            Loading,
            Footer
        },
        data() {
            return {
                performingRequest: true
            }
        }, 
        computed: {    
            title: () => {
                return "Unsere Preise"
            }
        },
        mounted() {
            this.performingRequest = false
        }
    }    
</script>

<style scoped src="../assets/css/app.css"></style>
<style>

</style>