<template>
    <div id="parking">
        <Loading v-bind:loading="performingRequest"/>
        <div class="sidebar"><Sidebar/></div>
        <div class="body-text">

            <div class="container-fluid pagepadding">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1 mx-auto" style="padding-left: 10px;">
                    <h1><span class="iconfloat-title"><img src="../assets/icons/producthunt.svg" style="height:24px; width:24px;"></span>  {{ title }} <small>({{ carcount }})</small></h1>    
                    <hr>          
                    </div>        
                </div> 
            </div> 

            <div v-for="car in cars" :key="car.id" class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1 mx-auto">                 
                <ParkItem v-bind:data="car" @add="(i) => carcount -= i"/>         
            </div>

            <div v-if="carcount == 0 && performingRequest != true" class="text-center">
                <br><br><br>
                <h3>Noch kein Auto auf deinem Parkplatz!</h3>
                <p>Jetzt Fahrzeug suchen und parken!</p>
                <br>
                <img src="../assets/nocar.png" style="width:250px;">                
            </div>
        
        <Footer/>
        </div>        
    </div>
</template>

<script>
    import Sidebar from '@/components/Sidebar'
    import Loading from '@/components/Loading'    
    import Footer from '@/components/Footer'
    import ParkItem from '@/components/ParkItem'
    import models from "../models";

    export default {
        name: 'ParkingView',
        components: {
            Sidebar,
            Loading,
            Footer,
            ParkItem
        },
        data() {
            return {
                cars: null,            
                carcount: 0,            
                performingRequest: true
            }
        },  
        computed: {    
            title: () => {
                return "Parkplatz"
            }
        },
        async mounted () {
            await models.getParking(this.$store.getters.StateToken).then(res => {                
                this.cars = res.data;
                this.carcount = res.data.length;                
                this.performingRequest = false;
            }).catch(() => {                
                this.performingRequest = false;
            });
        },
    }    
</script>

<style scoped src="../assets/css/app.css"></style>
<style>
small {
    font-size: 20px;
    color: rgb(143, 143, 143);
}
</style>